import { Box } from "@material-ui/core";
import { TriggerWithRelationInfo } from "@onn/common";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import { DropResult } from "react-beautiful-dnd";
import { FormProvider, useFieldArray } from "react-hook-form";
import styled from "styled-components";

import { usePageContext } from "../../pageContext";

import { TriggerBlockWidth } from "../constant/constant";

import { InputState } from "../hooks/scenarioForm/InputState";

import { useFormContext, useScenarioForm } from "../hooks/scenarioForm/useScenarioForm";

import { useUpdateWarningRecruitmentStatuses } from "../hooks/scenarioForm/useUpdateWarningRecruitmentStatuses";

import { EditModeContextProvider } from "./EditModeContextProvider";
import { ActionFooterForEdit } from "./components/ActionFooterForEdit";
import { TriggerBlockForEditMode } from "./components/TriggerBlockForEditMode/TriggerBlockForEditMode";

import { AddTriggerAndActionModal } from "~/components/domains/scenario/AddTriggerAndActionModal";
import { Button, DnDDraggable, DnDDroppable, DnDProvider, Icon } from "~/components/uiParts";
import { usePrompt, useSnackbar } from "~/hooks/shared";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";

type Props = {
  allTriggers: TriggerWithRelationInfo[];
};

export const ScenarioFlowAreaWhenEditMode: FC<Props> = ({ allTriggers }) => {
  // NOTE: シナリオ編集画面では複数のシナリオを編集できるため、allTriggersを渡す
  const { form, scenarios } = useScenarioForm({ triggers: allTriggers });
  const { selectedRecruitmentStatus, scenarioId } = usePageContext();
  usePrompt("変更内容を破棄しますか？", form.formState.isDirty);
  const filteredTriggers = allTriggers.filter(
    (trigger) => trigger.recruitmentStatusId === selectedRecruitmentStatus.id
  );

  const triggerIdToWaitingTriggerBlockCount = useMemo(
    () =>
      new Map(filteredTriggers.map((trigger) => [trigger.id, trigger.waitingTriggerBlockCount])),
    [filteredTriggers]
  );
  const scenarioIndex = scenarios.findIndex(
    (s) => selectedRecruitmentStatus.id === s.recruitmentStatusId
  );
  const scenario = scenarios[scenarioIndex];

  if (!scenario) return null;
  return (
    <FormProvider {...form}>
      <ScenarioFlowAreaWhenEditModeCore
        key={selectedRecruitmentStatus.id}
        scenario={scenario}
        triggerIdToWaitingTriggerBlockCount={triggerIdToWaitingTriggerBlockCount}
        scenarioIndex={scenarioIndex}
        scenarioId={scenarioId}
      />
      <ActionFooterForEdit />
    </FormProvider>
  );
};

type PropsForCore = {
  scenario: InputState["body"][number];
  triggerIdToWaitingTriggerBlockCount: Map<string, number>;
  scenarioIndex: number;
  scenarioId: string;
};

export const ScenarioFlowAreaWhenEditModeCore: FC<PropsForCore> = ({
  scenario,
  triggerIdToWaitingTriggerBlockCount,
  scenarioIndex,
  scenarioId,
}) => {
  const [isOpenAddTriggerAndActionModal, setIsOpenAddTriggerAndActionModal] = useState(false);
  const { currentSpace } = useCurrentSpace();
  const { isLockingByOthers, isLockingByOwn, setMode } = usePageContext();
  const { enqueueSnackbar } = useSnackbar();
  const { control, trigger } = useFormContext();
  const [indexToAddBlock, setIndexToAddBlock] = useState<number | null>(null);
  const { updateWarningRecruitmentStatuses } = useUpdateWarningRecruitmentStatuses();

  useEffect(() => {
    if (!isLockingByOthers && !isLockingByOwn) return;

    if (isLockingByOwn) {
      enqueueSnackbar("シナリオは現在編集中です。", { variant: "info" });
    } else if (isLockingByOthers) {
      enqueueSnackbar(
        "シナリオは現在別のユーザーによって編集中のため、保存できません。時間をおいてから再度操作をしてください。",
        { variant: "info" }
      );
    }
    setMode("view");
  }, [enqueueSnackbar, isLockingByOthers, isLockingByOwn, setMode]);

  const { remove, move, insert, append } = useFieldArray({
    control,
    name: `body.${scenarioIndex}.blocks`,
  });

  const changeOrder = useCallback(
    (result: DropResult) => {
      if (!result.destination) return;

      const {
        source: { index: sourceIndex },
        destination: { index: destinationIndex },
      } = result;

      // drop可能範囲以外でのdropは無効 or 移動元と移動先が同じ場合は処理を終了
      if (destinationIndex === undefined || sourceIndex === destinationIndex) return;

      move(sourceIndex, destinationIndex);

      // NOTE: ブロック並び順によるバリデーションを発火させるためにtriggerを実行（選考ステータストリガーがrootに来てない場合の対応）
      trigger();
    },
    [move, trigger]
  );

  return (
    <EditModeContextProvider scenarioId={scenarioId}>
      <Root>
        <DnDProvider onDragEnd={changeOrder}>
          <DnDDroppable droppableId="droppableId" isDropDisabled={false}>
            {scenario.blocks.map((block, i) => {
              const isLast = scenario.blocks.length - 1 === i;
              return (
                <DnDDraggable
                  draggableId={block.trigger.id}
                  key={block.trigger.id}
                  index={i}
                  isDragDisabled={false}
                  shouldRoundCorner={false}
                  disabledBoxShadow
                  background="transparent"
                >
                  <TriggerBlockForEditMode
                    key={block.trigger.id}
                    block={block}
                    blockIndex={i}
                    waitingTriggerBlockCount={
                      triggerIdToWaitingTriggerBlockCount.get(block.trigger.id) || 0
                    }
                    deleteTriggerBlock={() => {
                      remove(i);
                      updateWarningRecruitmentStatuses();
                    }}
                  />
                  {!isLast && (
                    <TriggerBlockLinkArea
                      onClick={() => {
                        setIsOpenAddTriggerAndActionModal(true);
                        setIndexToAddBlock(i + 1);
                      }}
                    />
                  )}
                </DnDDraggable>
              );
            })}
          </DnDDroppable>
        </DnDProvider>
        {scenario.blocks.length !== 0 && <BlockLinkBorder height={40} />}
        <ButtonToAddScenario onClick={() => setIsOpenAddTriggerAndActionModal(true)} />
      </Root>
      {/* NOTE: handleModalではProviderの値にアクセスできないためModalのレンダリングをこのファイルで行う */}
      <AddTriggerAndActionModal
        open={isOpenAddTriggerAndActionModal}
        onCancel={() => setIsOpenAddTriggerAndActionModal(false)}
        spaceId={currentSpace.id}
        recruitmentStatusId={scenario.recruitmentStatusId}
        applyToAddBlock={(inputValue) => {
          indexToAddBlock ? insert(indexToAddBlock, inputValue) : append(inputValue);
          setIndexToAddBlock(null);
        }}
      />
    </EditModeContextProvider>
  );
};

const TriggerBlockLinkArea: FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <TriggerBlockWrapper>
      <BlockLinkBorder height={40} />
      <ButtonToAddScenario onClick={onClick} />
      <BlockLinkBorder className="space-for-button" height={40} />
    </TriggerBlockWrapper>
  );
};

const ButtonToAddScenario: FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <Button
      startIcon={<Icon icon="add" size="sm" color="primary" />}
      color={"primary"}
      borderRadius={"regular"}
      variant={"dashedOutlined"}
      fullWidth
      onClick={onClick}
    >
      ブロックを追加
    </Button>
  );
};

const Root = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-bottom: 76px; // アクションフッター分
  position: relative;
  width: ${TriggerBlockWidth}px;
`;

const BlockLinkBorder = styled(Box)`
  position: relative;
  ::after {
    content: "";
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 50%;
    width: 2px;
    background-color: ${(props) => props.theme.palette.primary.main};
  }
`;

const TriggerBlockWrapper = styled(Box)`
  position: relative;
  button {
    display: none;
  }
  .space-for-button {
    display: none;
  }
  :hover {
    button {
      display: block;
    }
    .space-for-button {
      display: block;
    }
  }
`;
