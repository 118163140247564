import {
  AnswerOnnEventTriggerSetting,
  AnswerOnnTaskTriggerSetting,
  ChangeRecruitmentStatusTriggerSetting,
  ChangeStatusOfOnnEventTriggerSetting,
  EvaluateOnnEventTriggerSetting,
} from "@onn/common";
import { useMemo } from "react";

import { InputBlockState } from "../../hooks/scenarioForm/InputState";
import { useEditModeContext } from "../EditModeContextProvider";

export const useIsTriggerRelatedDeletedStatus = (
  triggerSetting: InputBlockState["triggerSetting"]
) => {
  const { recruitmentStatusesOfEditingScenarioMap } = useEditModeContext();
  const triggerType = triggerSetting.type;
  // NOTE: isDeletedRecruitmentStatusがtrueの場合、削除されたステータスのトリガーであることを表示する
  const isTriggerRelatedDeletedStatus = useMemo(() => {
    switch (triggerType) {
      case ChangeRecruitmentStatusTriggerSetting.type: {
        const recruitmentStatus = recruitmentStatusesOfEditingScenarioMap.get(
          triggerSetting.recruitmentStatusId
        );
        return recruitmentStatus?.deleted || false;
      }
      case ChangeStatusOfOnnEventTriggerSetting.type:
      case EvaluateOnnEventTriggerSetting.type:
      case AnswerOnnTaskTriggerSetting.type:
      case AnswerOnnEventTriggerSetting.type:
        return false;
      default:
        // eslint-disable-next-line no-case-declarations
        const _exhaustiveCheck: never = triggerType;
        return _exhaustiveCheck;
    }
  }, [recruitmentStatusesOfEditingScenarioMap, triggerSetting, triggerType]);

  return { isTriggerRelatedDeletedStatus };
};
