import { NewGraduateToDisplay } from "@onn/common";

import { isEmpty } from "lodash";
import { useCallback, useState } from "react";

import { Condition } from "../_share_InvitationQR/type";

import { useCurrentUser, useUpdateIndividualInvitationSetting } from "~/hooks/employee";
import { useAddScenarioToNewGraduate } from "~/hooks/employee/useAddScenarioToNewGraduate";
import { useAddTagToEmployee } from "~/hooks/employee/useAddTagToEmployee";
import { useRemoveTagFromEmployee } from "~/hooks/employee/useRemoveTagFromEmployee";
import { useSnackbar } from "~/hooks/shared";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";
import { EmployeeUseCase } from "~/service/usecases/employeeUseCase";
import { captureException } from "~/util";

type ValidScenario = {
  scenarioId: string;
  recruitmentStatusId: string;
};

/**
 * 「条件を付与」ボタンが押されたときの処理
 */
export const useOnAddConditions = ({
  toShowQRMode,
  selectedCondition,
  newGraduate,
  individualInvitationSettingId,
  onUpdateConditions,
}: {
  toShowQRMode: () => void;
  selectedCondition: Condition;
  newGraduate: NewGraduateToDisplay;
  individualInvitationSettingId: string;
  onUpdateConditions?: () => void;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useCurrentUser();
  const { currentSpace } = useCurrentSpace();

  const [isUpdating, setIsUpdating] = useState(false);

  const { updateEmployeeTagAttachment } = useUpdateEmployeeTagAttachment(newGraduate.id);
  const { updateIndividualInvitationSetting } = useUpdateIndividualInvitationSetting();
  const { trigger } = useAddScenarioToNewGraduate({
    employeeId: newGraduate.id,
  });

  const judgeIsUpdateRecruitmentStatusAndExecute = useCallback(
    async (newGraduate: NewGraduateToDisplay, scenarios: Condition["scenarios"]): Promise<void> => {
      // NOTE: すでに設定されているシナリオは除外する
      const scenarioIdsAlreadyHave = newGraduate.scenarios.map((v) => v.scenarioId);
      const scenariosToAdd = scenarios.filter(
        (v) => !scenarioIdsAlreadyHave.includes(v.scenarioId)
      );

      if (isEmpty(scenariosToAdd)) return;

      // NOTE: recruitmentStatusIdが存在しない場合は処理を中断する
      const validate = (
        scenariosToAdd: Condition["scenarios"]
      ): scenariosToAdd is ValidScenario[] => scenariosToAdd.every((v) => !!v.recruitmentStatusId);
      if (!validate(scenariosToAdd)) return;

      // NOTE: リクエストを同時に実行するが最大2件なので問題ないと判断している
      await Promise.all(
        scenariosToAdd.map((scenario) =>
          trigger({
            scenarioId: scenario.scenarioId,
            recruitmentStatusId: scenario.recruitmentStatusId,
          })
        )
      );
    },
    [trigger]
  );

  const onAddConditions = async () => {
    try {
      setIsUpdating(true);
      await EmployeeUseCase.update(newGraduate.id, {
        selectableAuthenticationFlowTypes: selectedCondition.authenticationFlowTypes,
      });
      await judgeIsUpdateRecruitmentStatusAndExecute(newGraduate, selectedCondition.scenarios);

      await updateEmployeeTagAttachment({
        newGraduate,
        selectedTagIds: selectedCondition.tagIds,
      });
      await updateIndividualInvitationSetting({
        employeeId: newGraduate.id,
        individualInvitationSettingId,
        onnEventIds: selectedCondition.onnEventIds,
        onnTaskIds: selectedCondition.onnTaskIds,
      });
      toShowQRMode();
      if (onUpdateConditions) onUpdateConditions();
    } catch (_error) {
      enqueueSnackbar("招待QRへの条件付与に失敗しました。管理者までお問い合わせください。", {
        variant: "error",
      });
      captureException({
        error: new Error("共通招待QRへの条件付与に失敗しました。"),
        tags: { type: "useGenerateRegistrationInvitationLinkUrl:handleSubmit" },
        extras: {
          employeeId: currentUser.id,
          spaceId: currentSpace.id,
          selectedCondition,
        },
      });
    } finally {
      setIsUpdating(false);
    }
  };

  return { onAddConditions, isUpdating };
};

// タグのつけ外しを行うhooks
// 処理が少し複雑なので、hooksに切り出しています
const useUpdateEmployeeTagAttachment = (employeeId: string) => {
  const { trigger: addTagToEmployee } = useAddTagToEmployee({
    employeeID: employeeId,
  });
  const { removeTagFromEmployee } = useRemoveTagFromEmployee();

  const updateEmployeeTagAttachment = useCallback(
    async ({
      newGraduate,
      selectedTagIds,
    }: {
      newGraduate: NewGraduateToDisplay;
      selectedTagIds: string[];
    }) => {
      const targetTagIds = new Set([...selectedTagIds, ...newGraduate.employeeTagIds]);

      const tagsToAdd = [];
      const tagsToRemove = [];
      for (const tagId of targetTagIds) {
        const isAlreadyAdded = newGraduate.employeeTagIds.includes(tagId);
        const isSelected = selectedTagIds.includes(tagId);

        if (isAlreadyAdded && !isSelected) {
          tagsToRemove.push(tagId);
        } else if (!isAlreadyAdded && isSelected) {
          tagsToAdd.push(tagId);
        }
      }

      // NOTE: 既存のhooksを使うために、一旦ループを分けている。パフォーマンスが気になる場合は改修する。
      // （複数削除のエンドポイントが実装時点で存在しなかった）
      for (const tagId of tagsToRemove) {
        await removeTagFromEmployee(tagId, newGraduate.id);
      }
      await addTagToEmployee({ newTagIDs: tagsToAdd });
    },
    [addTagToEmployee, removeTagFromEmployee]
  );

  return { updateEmployeeTagAttachment };
};
