import { RegistrationInvitationLink } from "@onn/common";
import { EmailType, LineType } from "@onn/common/domain/Employee/AuthenticationType";
import { useCallback } from "react";

import { apiClient } from "~/libs";

export const useCreateRegistrationInvitationLink = () => {
  const create = useCallback(
    async (params: {
      conditions: {
        spaceId: string;
        selectableAuthenticationFlowTypes: [LineType] | [LineType, EmailType];
        onnEventIds: string[];
        onnTaskIds: string[];
        tagIds: string[];
        scenarios: Array<{
          scenarioId: string;
          recruitmentStatusId: string;
        }>;
      };
    }) => {
      const response = await apiClient.post("/api/registration-invitation-link", params);
      return new RegistrationInvitationLink({
        ...response.data.registrationInvitationLink,
      });
    },
    []
  );

  return { create };
};
