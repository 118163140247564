import { Box, Paper } from "@material-ui/core";
import { Stack } from "@mui/material";
import React from "react";

import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";

import styled from "styled-components";

import { AccessControlSelectAndThreeDotMenuRow } from "./AccessControlSelectAndThreeDotMenuRow";
import { FieldLabelAndTypeInputRow } from "./FieldLabelAndTypeInputRow";
import { FieldBaseProps } from "./types";

import { AbsoluteGripHorizontalBox } from "~/components/uiParts/AbsoluteGripHorizontalBox";

type Props = FieldBaseProps & {
  children?: React.ReactNode;
};

export const FieldBase: React.FC<Props> = ({ children, ...props }) => {
  return (
    <DragAndDroppablePaper dragHandleProps={props.dragHandleProps}>
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          gridRowGap: "32px",
        }}
      >
        <Stack spacing={"24px"}>
          <FieldLabelAndTypeInputRow fieldIndex={props.fieldIndex} />
          {children}
        </Stack>
        <AccessControlSelectAndThreeDotMenuRow
          fieldIndex={props.fieldIndex}
          onRemoveField={props.onRemoveField}
          onDuplicateField={props.onDuplicateField}
          isFinalField={props.isFinalField}
        />
      </Box>
    </DragAndDroppablePaper>
  );
};

const DragAndDroppablePaper: React.FC<{
  children?: React.ReactNode;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
}> = ({ children, dragHandleProps }) => {
  return (
    <StyledPaper>
      <Box {...dragHandleProps}>
        <AbsoluteGripHorizontalBox />
      </Box>
      {children}
    </StyledPaper>
  );
};

const StyledPaper = styled(Paper)`
  padding: 40px;
  #gripVerticalIcon {
    display: none;
  }

  &:hover,
  &:active {
    #gripVerticalIcon {
      display: inline-block;
    }
  }
`;
