import { Box, FormControlLabel, Menu, MenuItem } from "@material-ui/core";
import { RecruitmentStatus, Scenario } from "@onn/common";
import { isEmpty } from "lodash";
import React, { useContext, useMemo, useState } from "react";
import styled from "styled-components";

import { ScenarioContext } from "~/components/providers/ScenarioProvider";

import { Checkbox, Icon, Typography } from "~/components/uiParts";

type ScenarioWithRecruitmentStatuses = {
  scenario: Scenario;
  recruitmentStatuses: RecruitmentStatus[];
};

const useDisplayScenariosWithRecruitmentStatuses = (includeRejectedAndWithdrew: boolean) => {
  const { scenariosWithRecruitmentStatuses } = useContext(ScenarioContext);

  const displayScenariosWithRecruitmentStatuses = useMemo(() => {
    return scenariosWithRecruitmentStatuses.flatMap((scenarioWithRecruitmentStatuses) => {
      const { recruitmentStatuses } = scenarioWithRecruitmentStatuses;
      const filteredStatuses = includeRejectedAndWithdrew
        ? recruitmentStatuses
        : recruitmentStatuses.filter(
            (recruitmentStatus) =>
              recruitmentStatus.type !== "rejected" && recruitmentStatus.type !== "withdrew"
          );

      // NOTE: 一つも表示するステータスがない場合は表示しない
      if (isEmpty(filteredStatuses)) {
        return [];
      }

      return {
        scenario: scenarioWithRecruitmentStatuses.scenario,
        recruitmentStatuses: filteredStatuses,
      };
    });
  }, [includeRejectedAndWithdrew, scenariosWithRecruitmentStatuses]);

  return { displayScenariosWithRecruitmentStatuses };
};

// FIXME: 選考ステータスメニューが開いている状態でシナリオメニューの外かつ選考ステータスメニューの外をクリックした時に一気に両方閉じるようにする
export const RecruitmentStatusFilterMenu = ({
  anchorEl,
  setAnchorEl,
  selectedRecruitmentStatusIds,
  setSelectedRecruitmentStatusIds,
  includeRejectedAndWithdrew,
}: {
  anchorEl: HTMLElement | null;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  selectedRecruitmentStatusIds: string[];
  setSelectedRecruitmentStatusIds: (ids: string[]) => void;
  includeRejectedAndWithdrew: boolean;
}) => {
  const { displayScenariosWithRecruitmentStatuses } = useDisplayScenariosWithRecruitmentStatuses(
    includeRejectedAndWithdrew
  );

  return (
    <StyledMenu
      key="statusFilter"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: -8, horizontal: "right" }}
    >
      <Box
        display="flex"
        width="400px"
        maxHeight="300px"
        gridGap="8px"
        overflow="auto"
        flexDirection="column"
      >
        {displayScenariosWithRecruitmentStatuses.map((scenarioWithRecruitmentStatuses) => {
          return (
            <SelectScenarioMenu
              key={scenarioWithRecruitmentStatuses.scenario.id}
              scenarioWithRecruitmentStatuses={scenarioWithRecruitmentStatuses}
              selectedRecruitmentStatusIds={selectedRecruitmentStatusIds}
              setSelectedRecruitmentStatusIds={setSelectedRecruitmentStatusIds}
            />
          );
        })}
      </Box>
    </StyledMenu>
  );
};

const SelectScenarioMenu = ({
  scenarioWithRecruitmentStatuses,
  selectedRecruitmentStatusIds,
  setSelectedRecruitmentStatusIds,
}: {
  scenarioWithRecruitmentStatuses: ScenarioWithRecruitmentStatuses;
  selectedRecruitmentStatusIds: string[];
  setSelectedRecruitmentStatusIds: (ids: string[]) => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  return (
    <React.Fragment key={scenarioWithRecruitmentStatuses.scenario.id}>
      <StyledMenuItem onClick={(e) => setAnchorEl(e.currentTarget)}>
        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
          <Typography variant="body1" color="textPrimary">
            {scenarioWithRecruitmentStatuses.scenario.name}
          </Typography>
          <Icon icon="chevronRight" size="md" color="grey" />
        </Box>
      </StyledMenuItem>

      <RecruitmentStatusesMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        selectedRecruitmentStatusIds={selectedRecruitmentStatusIds}
        recruitmentStatuses={scenarioWithRecruitmentStatuses.recruitmentStatuses}
        setSelectedRecruitmentStatusIds={setSelectedRecruitmentStatusIds}
      />
    </React.Fragment>
  );
};

const RecruitmentStatusesMenu = ({
  anchorEl,
  setAnchorEl,
  selectedRecruitmentStatusIds,
  recruitmentStatuses,
  setSelectedRecruitmentStatusIds,
}: {
  anchorEl: HTMLElement | null;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  recruitmentStatuses: RecruitmentStatus[];
  selectedRecruitmentStatusIds: string[];
  setSelectedRecruitmentStatusIds: (ids: string[]) => void;
}) => {
  return (
    <Menu
      key="statusFilter"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Box py="16px" width="240px" display="flex" flexDirection="column">
        <Box
          px="24px"
          maxHeight={300}
          overflow="auto"
          display="flex"
          flexDirection="column"
          gridGap="8px"
        >
          {recruitmentStatuses.map(({ id: recruitmentStatusId, label }) => {
            const isSelected = selectedRecruitmentStatusIds.includes(recruitmentStatusId);
            const onChange = () =>
              isSelected
                ? setSelectedRecruitmentStatusIds(
                    selectedRecruitmentStatusIds.filter(
                      (statusId) => statusId !== recruitmentStatusId
                    )
                  )
                : setSelectedRecruitmentStatusIds([
                    ...selectedRecruitmentStatusIds,
                    recruitmentStatusId,
                  ]);

            return (
              <React.Fragment key={recruitmentStatusId}>
                <StyledFormControlLabel
                  control={
                    <Checkbox
                      checked={isSelected}
                      onChange={onChange}
                      name={recruitmentStatusId}
                      value={recruitmentStatusId}
                    />
                  }
                  label={
                    <Box
                      width="100%"
                      display="flex"
                      alignItems="center"
                      gridGap="4px"
                      overflow="hidden"
                    >
                      <Typography variant="body2" color="textSecondary" noWrap>
                        {label}
                      </Typography>
                    </Box>
                  }
                />
              </React.Fragment>
            );
          })}
        </Box>
      </Box>
    </Menu>
  );
};

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin-right: 0;
    width: 100%;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
`;

const StyledMenu = styled(Menu)`
  .MuiList-root {
    padding: 8px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  &.MuiListItem-root {
    border-radius: 4px;
    padding: 8px;
  }
`;
