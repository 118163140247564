import { Box } from "@material-ui/core";
import { Employee, EmployeeTag, isValidEmail, NewGraduate } from "@onn/common";
import { isEmpty } from "lodash";
import React, { FC, useCallback, useMemo, useState } from "react";
import styled from "styled-components";

import { useCheckInputValues } from "../InviteNewGraduateWithCSVModal/useCheckInputValues";

import { useGetErrorMessage } from "../InviteNewGraduateWithCSVModal/useGetErrorMessage";

import { ConfirmStep } from "./ConfirmStep";
import { SelectNewGraduateStep } from "./SelectNewGraduateStep";

import { SelectScenarioStep } from "./SelectScenarioStep";

import { Button, Divider, Modal, VerticalStepper } from "~/components/uiParts";
import { useDebouncedCallback } from "~/hooks/shared";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";
import { captureException, mixin } from "~/util";

type UserDataType = {
  email: string;
  emailErrorMessage: string;
  isValidEmail: boolean;
  employeeTagIds: string[];
};

type ScenarioWithStatus = {
  scenarioId: string;
  recruitmentStatusId?: string;
};

type ValidScenarioWithStatus = {
  scenarioId: string;
  recruitmentStatusId: string;
};

type Props = {
  open: boolean;
  onCancel: () => void;
  onSubmit: (
    userDataArray: UserDataType[],
    selectedScenarios: ValidScenarioWithStatus[],
    isSendEmail: boolean
  ) => Promise<void>;
  currentUser: Employee;
  employeeTags: EmployeeTag[];
};

export const CreateNewGraduateModal: FC<Props> = ({
  open,
  onCancel,
  onSubmit,
  currentUser,
  ...props
}) => {
  const { checkInputValues } = useCheckInputValues();
  const { getErrorMessage } = useGetErrorMessage();
  const { currentSpace, spaces, isShowSpaceOnScreen } = useCurrentSpace();

  const [userDataArray, setUserDataArray] = useState<UserDataType[]>([
    {
      email: "",
      emailErrorMessage: "",
      isValidEmail: false,
      employeeTagIds: [],
    },
  ]);

  const [isValidatingInputEmails, setIsValidatingInputEmails] = useState(false);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);

  const [selectedScenarios, setSelectedScenarios] = useState<ScenarioWithStatus[]>([]);
  const [isSendEmail, setIsSendEmail] = useState(false);

  const toggleScenarioSelection = useCallback(({ scenarioId }: { scenarioId: string }) => {
    setSelectedScenarios((prev) => {
      const isExists = prev.some((scenario) => scenario.scenarioId === scenarioId);
      return isExists
        ? prev.filter((scenario) => scenario.scenarioId !== scenarioId)
        : [...prev, { scenarioId }];
    });
  }, []);

  const changeSelectedRecruitmentStatusId = useCallback(
    ({ scenarioId, recruitmentStatusId }: { scenarioId: string; recruitmentStatusId: string }) => {
      setSelectedScenarios((prev) => {
        return prev.map((scenario) => {
          if (scenario.scenarioId !== scenarioId) return scenario;
          return {
            ...scenario,
            recruitmentStatusId,
          };
        });
      });
    },
    []
  );

  const checkScenarioDataValid = useCallback(
    (selectedScenarios: ScenarioWithStatus[]): selectedScenarios is ValidScenarioWithStatus[] => {
      if (!NewGraduate.isValidActiveScenarioNumber(selectedScenarios.length)) return false;

      return selectedScenarios.every((scenario) => !!scenario.recruitmentStatusId);
    },
    []
  );

  const handleClickInvite = useCallback(async () => {
    setLoading(true);

    // NOTE: バリデーションはボタン押下時に行っているため、ここでのバリデーションは通るはずだが型ガードのために行なっている
    if (!checkScenarioDataValid(selectedScenarios)) {
      captureException({
        error: new Error("選択しているシナリオが不正です。"),
        tags: { type: "CreateNewGraduateModal:handleClickInvite" },
      });
      setLoading(false);
      return;
    }

    await onSubmit(userDataArray, selectedScenarios, isSendEmail)
      .then(() => {
        onCancel();
      })
      .catch((e) => {
        captureException({
          error: e as Error,
          tags: { type: "CreateNewGraduateModal:handleClickInvite" },
        });
      })
      .finally(() => setLoading(false));
  }, [checkScenarioDataValid, selectedScenarios, onSubmit, userDataArray, isSendEmail, onCancel]);

  const updateUserDataArray = useCallback((newObject: Partial<UserDataType>, index: number) => {
    setUserDataArray((prevUserDataArray) => {
      return prevUserDataArray.map((prevUserData, prevIndex) => {
        if (prevIndex === index) {
          return { ...prevUserData, ...newObject };
        }
        return prevUserData;
      });
    });
  }, []);

  const handleValidEmailChange = useDebouncedCallback((callback) => callback(), 2000);
  const checkEmail = useCallback(
    (email: string, index: number) => {
      updateUserDataArray({ email, isValidEmail: false, emailErrorMessage: "" }, index);

      if (email === "") return;

      const isValid = isValidEmail(email);
      if (!isValid) {
        updateUserDataArray({ emailErrorMessage: "メールアドレスの形式が間違っています" }, index);
        return;
      }
      setIsValidatingInputEmails(true);

      handleValidEmailChange(async () => {
        const errorStatus = await checkInputValues(
          userDataArray.map((data, i) => {
            return {
              email: i === index ? email : data.email,
              departmentNames: [],
              tagNames: [],
              onboardingExperienceTitles: [],
              onnEventTitles: [],
            };
          })
        );
        const errorMessage = errorStatus.size
          ? getErrorMessage(errorStatus, {
              isCsv: false,
              rowIndex: index,
              inputRowSize: userDataArray.length,
            })
          : "";
        if (errorMessage) {
          updateUserDataArray({ emailErrorMessage: errorMessage }, index);
        } else {
          updateUserDataArray({ isValidEmail: true, emailErrorMessage: "" }, index);
        }
        setIsValidatingInputEmails(false);
      });
    },
    [updateUserDataArray, handleValidEmailChange, checkInputValues, userDataArray, getErrorMessage]
  );

  const handleAddEmails = useCallback(() => {
    setUserDataArray((prev) => [
      ...prev,
      {
        email: "",
        emailErrorMessage: "",
        isValidEmail: false,
        employeeTagIds: [],
      },
    ]);
  }, []);

  const handleDeleteEmails = useCallback((index: number): void => {
    setUserDataArray((prev) => prev.flatMap((v, prevIndex) => (prevIndex === index ? [] : v)));
  }, []);

  // 1個以上の正しいフォーマットの値(+ 0個以上のブランクの値)のとき真
  const checkUserDataArrayValid = useCallback(
    (userDataArray: UserDataType[]): boolean => {
      const filledUserDataArray = userDataArray.filter((userData) => userData.email !== "");

      if (currentUser.isAdmin()) {
        return (
          !isEmpty(filledUserDataArray) &&
          filledUserDataArray.every((filledUserData) => filledUserData.isValidEmail)
        );
      } else {
        return false;
      }
    },
    [currentUser]
  );

  const stepComponents = useMemo(
    () => [
      {
        key: "selectNewGraduate",
        label: "対象者情報",
        components: (
          <SelectNewGraduateStep
            userDataArray={userDataArray}
            employeeTags={props.employeeTags}
            onChangeInputEmail={(email: string, index: number) => {
              checkEmail(email, index);
            }}
            onChangeEmployeeTagIds={(selectedTagIds: string[], index: number) => {
              updateUserDataArray({ employeeTagIds: selectedTagIds }, index);
            }}
            onDeleteEmails={handleDeleteEmails}
            onAddEmails={handleAddEmails}
          />
        ),
      },
      {
        key: "selectScenario",
        label: "シナリオ選択",
        components: (
          <SelectScenarioStep
            selectedScenarios={selectedScenarios}
            toggleScenarioSelection={toggleScenarioSelection}
            changeSelectedRecruitmentStatusId={changeSelectedRecruitmentStatusId}
          />
        ),
      },
      {
        key: "confirm",
        label: "内容確認",
        components: (
          <ConfirmStep
            userDataArray={userDataArray}
            employeeTags={props.employeeTags}
            isSendEmail={isSendEmail}
            setIsSendEmail={setIsSendEmail}
          />
        ),
      },
    ],
    [
      userDataArray,
      props.employeeTags,
      handleDeleteEmails,
      handleAddEmails,
      selectedScenarios,
      toggleScenarioSelection,
      changeSelectedRecruitmentStatusId,
      isSendEmail,
      checkEmail,
      updateUserDataArray,
    ]
  );

  const isLastStep = step === stepComponents.length;

  const Content = (
    <Box display="flex" pt={2}>
      <Box pl="30px">
        <VerticalStepper activeStep={step} labels={stepComponents.map((v) => v.label)} />
      </Box>
      <Divider orientation="vertical" margin={20} />
      <Box flexGrow={1} overflow="hidden">
        {stepComponents[step - 1]?.components}
        <StyledButtonContainer mt="40px">
          {step !== 1 && (
            <Button
              fullWidth
              borderRadius="circle"
              variant="outlined"
              color="default"
              onClick={() => setStep((prev) => prev - 1)}
            >
              戻る
            </Button>
          )}
          <Button
            fullWidth
            borderRadius="circle"
            variant="contained"
            color="primary"
            onClick={() => {
              if (isLastStep) handleClickInvite();
              else {
                setUserDataArray((prev) => prev.filter((v) => v.email.trim() !== ""));
                setStep((prev) => prev + 1);
              }
            }}
            disabled={
              !checkUserDataArrayValid(userDataArray) ||
              (stepComponents[step - 1]?.key === "selectScenario" &&
                !checkScenarioDataValid(selectedScenarios))
            }
            isLoading={loading || isValidatingInputEmails}
          >
            {isLastStep ? `${isSendEmail ? "招待メールを送信" : "登録"}` : "次へ"}
          </Button>
        </StyledButtonContainer>
      </Box>
    </Box>
  );

  return (
    <Modal
      open={open}
      title={
        isShowSpaceOnScreen(spaces) ? `アカウント追加｜${currentSpace.name}` : "アカウント追加"
      }
      content={Content}
      onCancel={onCancel}
      fullWidth
      disableBackdropModal
    />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
