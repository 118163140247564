import { APISchema } from "@onn/common";
import { useCallback } from "react";

import { apiClient } from "~/libs";
import { captureException } from "~/util";

type EndPoint = APISchema["/onn_event_api/answer-box"]["POST"];
export const useCreateOnnEventAnswerBox = () => {
  const createNewInterviewAnswerBox = useCallback(
    async ({
      onnEventId,
      employeeIds,
      forceNotifyImmediately,
    }: {
      onnEventId: string;
      employeeIds: string[];
      forceNotifyImmediately?: boolean;
    }) => {
      const requestBody: EndPoint["body"] = {
        onnEventId,
        employeeIds,
        forceNotifyImmediately,
      };
      try {
        await apiClient.post("/onn_event_api/answer-box", requestBody);
      } catch (error) {
        captureException({
          error: error as Error,
          tags: {
            type: "useCreateNewInterviewAnswerBox",
          },
          extras: {
            requestBody,
          },
        });
        throw error;
      }
    },
    []
  );

  return { createNewInterviewAnswerBox };
};
