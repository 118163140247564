import { Box } from "@material-ui/core";
import { captureException } from "@sentry/react";
import React, { FC, useCallback, useState } from "react";

import { ScrollableBodyModal, TooltipWhenTextTruncatedOneLine } from "~/components/uiParts";
import { useFileViewer } from "~/hooks/shared";
import { FileAPIAdapter } from "~/infrastructure/usecases/file/fileAPIAdapter";

const fileAPIAdapter = new FileAPIAdapter({ bucketType: "private" });

export const AnswerFilesCell: FC<{ filePath?: string }> = ({ filePath }) => {
  const { setFiles, setPreviewFileIndex } = useFileViewer();
  const [openFileListModal, setOpenFileListModal] = useState(false);
  const fileName = filePath?.split("/").pop() || "";

  const onClickFile = useCallback(async () => {
    if (!filePath) return;

    const fileUrl = await fileAPIAdapter.fetchUrl({ path: filePath });
    if (fileUrl) {
      setFiles([{ name: fileName, url: fileUrl, size: 100 }]);
      setPreviewFileIndex(0);
    } else {
      captureException({
        error: new Error("ファイルが取得できませんでした"),
        tags: { type: "AnswerFilesCell" },
        extras: { filePath },
      });
    }
  }, [fileName, filePath, setFiles, setPreviewFileIndex]);

  if (!filePath) {
    return <></>;
  }

  return (
    <>
      <FileList fileName={fileName} onClickFile={onClickFile} />
      <ScrollableBodyModal
        open={openFileListModal}
        title={"関連ファイル"}
        content={<FileList fileName={fileName} onClickFile={onClickFile} />}
        onCancel={() => setOpenFileListModal(false)}
        fullWidth
        disableBackdropModal
      />
    </>
  );
};

const FileList: FC<{ fileName: string; onClickFile: () => void }> = ({ fileName, onClickFile }) => {
  return (
    <Box width="100%" onClick={onClickFile} style={{ cursor: "pointer", textAlign: "center" }}>
      <TooltipWhenTextTruncatedOneLine
        text={fileName}
        typographyProps={{
          variant: "body2",
          color: "primary",
          style: {
            textDecoration: "underline",
          },
        }}
      />
    </Box>
  );
};
