import { OnnEventEvaluation } from "@onn/common";
import { useCallback, useMemo, useState } from "react";

import { useGetExecutableChangeRecruitmentStatusActionSettings } from "~/hooks/actionSetting/useGetExecutableChangeRecruitmentStatusActionSettings";

import { useOnnEventEvaluationRanks } from "~/hooks/onnEventEvaluationRank/useOnnEventEvaluationRanks";

// 評価ランク入力セレクターのカスタムフック
export const useOnnEventEvaluationRanksForm = (
  newGraduateId: string,
  onnEventId: string,
  currentOnnEventEvaluation?: OnnEventEvaluation
) => {
  const { data: onnEventEvaluationRanks = [], isLoading: isOnnEventEvaluationRanksLoading } =
    useOnnEventEvaluationRanks();

  const [selectedOnnEventEvaluationRankId, setSelectedOnnEventEvaluationRankId] = useState<
    string | null
  >(currentOnnEventEvaluation?.onnEventEvaluationRankId || null);

  const {
    data: executableChangeRecruitmentStatusActionSettings,
    mutate: mutateGetExecutableChangeRecruitmentStatusActionSettings,
    isValidating: isExecutableChangeRecruitmentStatusActionSettingsValidating,
  } = useGetExecutableChangeRecruitmentStatusActionSettings({
    newGraduateId,
    onnEventEvaluationRankId: selectedOnnEventEvaluationRankId,
    onnEventId,
  });

  const onClickEvaluationRank = useCallback(
    (onnEventEvaluationRankId: string | null) => {
      setSelectedOnnEventEvaluationRankId(onnEventEvaluationRankId);
      mutateGetExecutableChangeRecruitmentStatusActionSettings();
    },
    [mutateGetExecutableChangeRecruitmentStatusActionSettings]
  );

  const selectedOnnEventEvaluationRank = useMemo(() => {
    return onnEventEvaluationRanks.find((v) => v.id === selectedOnnEventEvaluationRankId) || null;
  }, [onnEventEvaluationRanks, selectedOnnEventEvaluationRankId]);

  return {
    onnEventEvaluationRanks,
    selectedOnnEventEvaluationRank,
    onClickEvaluationRank,
    isOnnEventEvaluationRanksLoading,
    executableChangeRecruitmentStatusActionSettings,
    isExecutableChangeRecruitmentStatusActionSettingsValidating,
  };
};
