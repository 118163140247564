import { Stack } from "@mui/material";
import React, { FC } from "react";
import { Control, Controller } from "react-hook-form";

import { Typography } from "~/components/uiParts";
import { SelectFormV2 } from "~/components/uiParts/SelectFormV2";

export const ScenarioForm: FC<{
  control: Control<
    {
      scenarioId: string;
      recruitmentStatusId: string;
      offerAcceptanceDeadline?: Date;
    },
    unknown
  >;
  scenarioOptions: {
    value: string;
    name: string;
  }[];
  onChangeScenario: () => void;
}> = ({ control, scenarioOptions, onChangeScenario }) => {
  return (
    <Stack width="100%" rowGap="8px">
      <Typography variant="body2" color="textSecondary" bold>
        シナリオ
      </Typography>
      <Controller
        control={control}
        name="scenarioId"
        render={({ field, fieldState }) => (
          <SelectFormV2
            labelWhenNoSelected="選択してください"
            selected={field.value}
            onChange={(e) => {
              field.onChange(e.target.value);
              onChangeScenario();
            }}
            errorText={fieldState.error?.message}
            menuItems={scenarioOptions}
            fullWidth
          />
        )}
      />
    </Stack>
  );
};
