import { Announcement, AnnouncementDistribution } from "@onn/common";
import { useCallback } from "react";

import { apiClient } from "~/libs";

export const useUpdateAnnouncementSettings = () => {
  const execUpdateAnnouncementSettings = useCallback(
    async ({
      announcementId,
      targetNewGraduateIds,
      publicationStartDate,
      publicationEndDate,
      forceNotifyImmediately,
    }: {
      announcementId: string;
      targetNewGraduateIds: string[];
      publicationStartDate?: Date | null;
      publicationEndDate?: Date | null;
      forceNotifyImmediately?: boolean;
    }) => {
      const data = await apiClient
        .post("/announcement_api/settings", {
          announcementId,
          targetNewGraduateIds,
          publicationStartDate: publicationStartDate ?? undefined,
          publicationEndDate: publicationEndDate ?? undefined,
          forceNotifyImmediately,
        })
        .then((res) => res.data);
      return {
        announcement: new Announcement(data.announcement),
        createdAnnouncementDistributions: data.createdAnnouncementDistributions.map(
          (announcementDistribution: AnnouncementDistribution) =>
            new AnnouncementDistribution(announcementDistribution)
        ),
      };
    },
    []
  );
  return {
    execUpdateAnnouncementSettings,
  };
};
