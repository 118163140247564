import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { mutateNewGraduateNextPlan } from "~/hooks/employee/useNewGraduateNextPlan";

import { useUpdateEmployeeInformation } from "~/hooks/employeeInformation";
import { mutateEmployeeInformation } from "~/hooks/employeeInformation/useEmployeeInformation";
import { useSnackbar } from "~/hooks/shared";

export const useOfferAcceptanceDeadlineForm = ({
  employeeId,
  offerAcceptanceDeadline: initOfferAcceptanceDeadline,
  onSubmit,
}: {
  employeeId: string;
  offerAcceptanceDeadline?: Date;
  onSubmit: () => void;
}) => {
  const { execUpdateEmployeeInformation } = useUpdateEmployeeInformation();
  const { enqueueSnackbar } = useSnackbar();

  const form = useForm<{
    offerAcceptanceDeadline: Date;
  }>({
    defaultValues: { offerAcceptanceDeadline: initOfferAcceptanceDeadline },
    mode: "onChange",
    resolver: zodResolver(schema),
  });

  const mode = initOfferAcceptanceDeadline ? "update" : "create";
  const handleSubmit = form.handleSubmit(async (values) => {
    await execUpdateEmployeeInformation({
      employeeId,
      value: {
        offerAcceptanceDeadline: values.offerAcceptanceDeadline,
      },
    });

    mutateEmployeeInformation(employeeId);
    mutateNewGraduateNextPlan({ newGraduateId: employeeId });

    onSubmit();
    enqueueSnackbar(`内定承諾期日が${mode === "create" ? "登録" : "更新"}されました`, {
      variant: "success",
    });
  });

  return {
    ...form,
    handleSubmit,
  };
};

const schema = z.object({ offerAcceptanceDeadline: z.date() });
