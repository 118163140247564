import { useCallback } from "react";

import { useBusinessHoursSettings } from "./useBusinessHoursSettings";

import { useTenantSettings } from "~/hooks/tenantSetting";

export const useShouldDisplayConfirmationOutsideBusinessHours = () => {
  const { tenantSettings } = useTenantSettings();
  const { mutate: mutateBusinessHoursSettings } = useBusinessHoursSettings();

  const getShouldDisplayConfirmationOutsideBusinessHours = useCallback(async () => {
    const now = new Date();
    const businessHoursSettings = await mutateBusinessHoursSettings();

    if (!tenantSettings.businessHours?.isEnable) {
      return false;
    }

    // NOTE: 初期は１つのみを想定
    const businessHoursSetting = businessHoursSettings?.[0];
    if (!businessHoursSetting) {
      return false;
    }

    const isDuringBusinessHours = businessHoursSetting.getIsDuringBusinessHours(now);
    return !isDuringBusinessHours;
  }, [mutateBusinessHoursSettings, tenantSettings.businessHours?.isEnable]);

  return { getShouldDisplayConfirmationOutsideBusinessHours };
};
