import { zodResolver } from "@hookform/resolvers/zod";
import { postScenariosBlockSchema, TriggerWithRelationInfo } from "@onn/common";
import { useEffect } from "react";
import {
  useForm,
  UseFormReturn,
  UseFormSetValue,
  useFormContext as useRHFFormContext,
} from "react-hook-form";
import { z } from "zod";

import { InputState } from "./InputState";

import { useRecruitmentStatusList } from "~/components/providers/ScenarioProvider";
import { usePageContext } from "~/pages/scenario/pageContext";

export const useFormContext = (): UseFormReturn<InputState> => {
  const { setValue: originalSetValue, ...form } = useRHFFormContext<InputState>();
  const setValue: UseFormSetValue<InputState> = (name, value, options = {}) => {
    const customOptions = {
      shouldDirty: true,
      shouldValidate: true,
      ...options,
    };
    // NOTE: 型エラーが出るため、型を明示的に指定している
    originalSetValue(
      name as Parameters<UseFormSetValue<InputState>>[0],
      value as Parameters<UseFormSetValue<InputState>>[1],
      customOptions as Parameters<UseFormSetValue<InputState>>[2]
    );
  };
  return { ...form, setValue };
};

export const useScenarioForm = ({ triggers }: { triggers: TriggerWithRelationInfo[] }) => {
  const { recruitmentStatuses } = useRecruitmentStatusList();
  const { recruitmentStatusesRelatedToScenario } = usePageContext();

  const formSchema = z.object({
    body: z.array(
      z
        .object({
          recruitmentStatusId: z.string(),
          blocks: z.array(
            postScenariosBlockSchema.superRefine((val, ctx) => {
              val.actions.forEach((action, index) => {
                switch (action.actionSetting?.type) {
                  case "ChangeRecruitmentStatusActionSetting": {
                    const recruitmentStatusId = action.actionSetting.recruitmentStatusId;
                    if (recruitmentStatuses.every((status) => status.id !== recruitmentStatusId)) {
                      ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        path: [`actions`, index, `actionSetting`, `recruitmentStatusId`],
                        message: "アクション設定に削除された採用ステータスが設定されています。",
                      });
                    }
                    break;
                  }
                  case undefined:
                  case "DeliverOnnEventActionSetting":
                  case "DeliverOnnTaskActionSetting":
                  case "SendContactMessageActionSetting": {
                    return;
                  }
                  default: {
                    const _exhaustiveCheck: never = action.actionSetting;
                    return _exhaustiveCheck;
                  }
                }
              });
            })
          ),
        })
        .superRefine((val, ctx) => {
          val.blocks.forEach((block, index) => {
            switch (block.triggerSetting.type) {
              case "ChangeRecruitmentStatusTriggerSetting": {
                if (index !== 0) {
                  ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: [`blocks`, index, `triggerSetting`],
                    message: "選考ステータスの変更トリガーは、１番始めに設定してください。",
                  });
                }
                break;
              }
              case "AnswerOnnEventTriggerSetting":
              case "AnswerOnnTaskTriggerSetting":
              case "EvaluateOnnEventTriggerSetting":
              case "ChangeStatusOfOnnEventTriggerSetting": {
                return;
              }
              default: {
                const _exhaustiveCheck: never = block.triggerSetting;
                return _exhaustiveCheck;
              }
            }
          });
        })
    ),
  });
  const form = useForm<InputState>({
    defaultValues: {
      body: recruitmentStatusesRelatedToScenario.map((status) => {
        const filteredTriggers = triggers.filter(
          (trigger) => trigger.recruitmentStatusId === status.id
        );
        return {
          recruitmentStatusId: status.id,
          blocks: filteredTriggers.map((trigger) => ({
            trigger: trigger,
            triggerSetting: trigger.triggerSetting,
            actions: trigger.actions.map((action) => ({
              action: action,
              actionSetting: action.setting,
              actionCondition: action.condition,
            })),
          })),
        };
      }),
    },
    resolver: zodResolver(formSchema),
  });
  // NOTE: 削除された選考ステータスがアクションに含まれている場合などは、最初から保存ボタンをdisabledにしたいので、初期レンダリング時にバリデーションを実行する
  useEffect(() => {
    form.trigger();
  }, [form]);

  const scenarios = form.watch("body");

  return { form, scenarios };
};
