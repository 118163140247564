import { Box } from "@mui/material";
import { OnnEventEvaluation } from "@onn/common";
import React, { FC } from "react";

import { NoneCell } from "../../../common/NoneCell";
import { Cell } from "../../_share/Cell";

import { Button, Icon, Tooltip, Typography } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useModal } from "~/hooks/modal";

export type EvaluationCellProps = {
  onnEventId: string;
  evaluation?: OnnEventEvaluation;
  onnEventEvaluationRankLabel?: string;
  newGraduateName: string;
  newGraduateId: string;
  isExistOnnEventDeterminingDate: boolean;
};

export const EvaluationCell: FC<EvaluationCellProps> = (props) => {
  if (!props.isExistOnnEventDeterminingDate) return <NoneCell />;
  return <EvaluationCellCore {...props} />;
};

export const EvaluationCellCore: FC<EvaluationCellProps> = ({
  onnEventId,
  evaluation,
  onnEventEvaluationRankLabel,
  newGraduateId,
  newGraduateName,
}) => {
  const { handleModal } = useModal();
  const { currentUser } = useCurrentUser();

  const handleOpenEvaluationModal = () => {
    // 評価入力モーダルを開くときは必ず渡される
    handleModal({
      name: "editOnnEventEvaluationModal",
      args: {
        onnEventId: onnEventId,
        newGraduateName,
        newGraduateId,
        alreadyRegisteredOnnEventEvaluation: evaluation,
      },
    });
  };
  return (
    <Cell>
      {evaluation ? (
        <Box>
          <Button
            color="primary"
            variant="text"
            borderRadius="regular"
            endIcon={<Icon icon="memo" color="primary" size="md" />}
            onClick={handleOpenEvaluationModal}
            disabled={!currentUser.isAdmin()}
          >
            <Typography variant="body2" color="primary" bold>
              {onnEventEvaluationRankLabel}
            </Typography>
          </Button>
        </Box>
      ) : (
        <Tooltip
          arrow
          placement="bottom"
          title={!currentUser.isAdmin() && "管理者のみが評価を入力できます"}
        >
          <Button
            color="primary"
            variant="text"
            borderRadius="regular"
            startIcon={<Icon icon="add" color="secondary" size="sm" />}
            onClick={handleOpenEvaluationModal}
            disabled={!currentUser.isAdmin()}
          >
            <Typography variant="body2" color="secondary" bold>
              評価を入力
            </Typography>
          </Button>
        </Tooltip>
      )}
    </Cell>
  );
};
