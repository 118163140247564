import React, { ComponentProps } from "react";

import { SelectFormV2 } from "~/components/uiParts/SelectFormV2";

export const AccessControlAllType = "ALL";
export const AccessControlOnlyAdminType = "ADMIN_ONLY";

const accessControlMenuItems = [
  {
    value: AccessControlAllType as typeof AccessControlAllType,
    name: "全員",
  },
  {
    value: AccessControlOnlyAdminType as typeof AccessControlOnlyAdminType,
    name: "管理者のみ",
  },
];

type AccessControlTypes = typeof AccessControlAllType | typeof AccessControlOnlyAdminType;

export const AccessControlTypesSelectForm: React.FC<
  Omit<ComponentProps<typeof SelectFormV2<AccessControlTypes>>, "menuItems">
> = (props) => {
  return (
    <SelectFormV2<AccessControlTypes> {...props} fullWidth menuItems={accessControlMenuItems} />
  );
};
