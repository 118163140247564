import { Box, TextField } from "@material-ui/core";
import {
  AnyOnnEventEvaluationField,
  OnnEventEvaluationFileField,
  OnnEventEvaluationMultipleSelectField,
  OnnEventEvaluationSingleSelectField,
  OnnEventEvaluationTextField,
} from "@onn/common";
import React, { useCallback } from "react";
import { useController, useFormContext } from "react-hook-form";

import { v4 } from "uuid";

import { InputState } from "../../../../../hooks/form/InputState";

import { SelectFormV2 } from "~/components/uiParts/SelectFormV2";

export type FieldTypes = AnyOnnEventEvaluationField["type"];

const fieldTypeMenuItems = [
  // NOTE: ファイルタイプは編集できない仕様
  {
    value: OnnEventEvaluationTextField.type,
    name: "記述式",
  },
  {
    value: OnnEventEvaluationSingleSelectField.type,
    name: "単一選択",
  },
  {
    value: OnnEventEvaluationMultipleSelectField.type,
    name: "複数選択",
  },
];

/**
 * フィールドラベルとフィールドタイプの入力エリア
 */
export const FieldLabelAndTypeInputRow: React.FC<{
  fieldIndex: number;
}> = ({ fieldIndex }) => {
  const { getValues, setValue, watch } = useFormContext<InputState>();

  const { field: labelField, fieldState: labelFieldState } = useController<
    InputState,
    `evaluationFields.${number}.label`
  >({
    name: `evaluationFields.${fieldIndex}.label`,
  });

  const { field: typeFieldField } = useController<InputState, `evaluationFields.${number}.type`>({
    name: `evaluationFields.${fieldIndex}.type`,
  });

  // NOTE: フィールドタイプの変更は、他のRHFの他のフィールドの値を変更するため、コンポーネント内ではなくここで定義している
  const onChangeType = useCallback(
    (type: FieldTypes) => {
      const fieldValue = getValues(`evaluationFields.${fieldIndex}`);
      const options =
        fieldValue.type === OnnEventEvaluationMultipleSelectField.type ||
        fieldValue.type === OnnEventEvaluationSingleSelectField.type
          ? fieldValue.options
          : [
              { optionId: v4(), label: "選択肢1" },
              { optionId: v4(), label: "選択肢2" },
            ];

      switch (type) {
        case OnnEventEvaluationTextField.type: {
          setValue(`evaluationFields.${fieldIndex}`, {
            ...fieldValue,
            type: OnnEventEvaluationTextField.type,
            hasInputTemplate: false,
            inputTemplate: "",
          });
          break;
        }
        case OnnEventEvaluationFileField.type: {
          setValue(`evaluationFields.${fieldIndex}`, {
            ...fieldValue,
            type: OnnEventEvaluationFileField.type,
          });
          break;
        }
        case OnnEventEvaluationSingleSelectField.type: {
          setValue(`evaluationFields.${fieldIndex}`, {
            ...fieldValue,
            type: OnnEventEvaluationSingleSelectField.type,
            options,
          });
          break;
        }
        case OnnEventEvaluationMultipleSelectField.type: {
          setValue(`evaluationFields.${fieldIndex}`, {
            ...fieldValue,
            type: OnnEventEvaluationMultipleSelectField.type,
            options,
          });
          break;
        }
        default: {
          const _exhaustiveCheck: never = type;
          return _exhaustiveCheck;
        }
      }
    },
    [fieldIndex, getValues, setValue]
  );

  const isTypeSelectorDisabled = watch(`evaluationFields.${fieldIndex}.isSavedField`);

  return (
    <Box
      display={"flex"}
      sx={{
        justifyContent: "space-between",
      }}
    >
      <Box mr="16px" width="100%">
        <TextField
          placeholder="入力してください"
          fullWidth
          value={labelField.value}
          onChange={labelField.onChange}
          error={!!labelFieldState.error}
          helperText={labelFieldState.error?.message}
          variant="standard"
        />
      </Box>
      <Box width={"160px"}>
        <SelectFormV2<FieldTypes>
          fullWidth
          menuItems={fieldTypeMenuItems}
          selected={typeFieldField.value}
          onChange={(e) => {
            onChangeType(e.target.value as FieldTypes);
          }}
          disabled={isTypeSelectorDisabled}
        />
      </Box>
    </Box>
  );
};
