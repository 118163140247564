import { Failure, OnnEventAnswer, Result, Success } from "../../../../domain";
import {
  toCloudFunctionsCompatible,
  ToCloudFunctionsCompatible,
} from "../../../../utils/toCloudFunctionsCompatible";
import { OnnEventAnswerRequestType } from "../../onnEventAnswer/shared";

import {
  IRemoveFromDeliveredNewGraduatesRequest,
  removeFromDeliveredNewGraduatesRequestSchema,
} from "./schema";

export class RemoveFromDeliveredNewGraduatesRequest
  implements IRemoveFromDeliveredNewGraduatesRequest
{
  static readonly validator = removeFromDeliveredNewGraduatesRequestSchema;
  static actionType = OnnEventAnswerRequestType.actionTypes.removeFromDeliveredNewGraduates;
  onnEventAnswer: OnnEventAnswer;
  forceNotifyImmediately?: boolean;

  constructor(init: RemoveFromDeliveredNewGraduatesRequest) {
    this.onnEventAnswer = init.onnEventAnswer;
    this.forceNotifyImmediately = init.forceNotifyImmediately;
  }

  // クライアント側でリクエストボディを作成する
  public static createRequestBody({
    onnEventAnswer,
    forceNotifyImmediately,
  }: RemoveFromDeliveredNewGraduatesRequest) {
    return toCloudFunctionsCompatible({
      actionType: RemoveFromDeliveredNewGraduatesRequest.actionType,
      onnEventAnswer,
      forceNotifyImmediately,
    });
  }

  public static validate(
    data: ToCloudFunctionsCompatible<RemoveFromDeliveredNewGraduatesRequest>
  ): Result<RemoveFromDeliveredNewGraduatesRequest, Error> {
    const requestData = convertUnixTimeToDate(data.onnEventAnswer);
    const parseResult = OnnEventAnswer.validator.safeParse(requestData);
    if (!parseResult.success) {
      return new Failure(new Error(parseResult.error.message));
    }

    return new Success(
      new RemoveFromDeliveredNewGraduatesRequest({
        onnEventAnswer: OnnEventAnswer.create({
          ...parseResult.data,
        }),
        forceNotifyImmediately: data.forceNotifyImmediately,
      })
    );
  }
}

// unixTimeをDate型に変換する
const convertUnixTimeToDate = (
  d: DeepConvertValueType<
    DeepConvertValueType<OnnEventAnswer, Date, number>,
    Date | undefined,
    number
  >
): OnnEventAnswer => {
  return new OnnEventAnswer({
    ...d,
    createdAt: new Date(d.createdAt),
    updatedAt: new Date(d.updatedAt),
  });
};
