import { z } from "zod";

/////////////////////////////////////////
// WAITING TRIGGER BLOCK SCHEMA
/////////////////////////////////////////

export const WaitingTriggerBlockSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  recruitmentStatusRelationId: z.string(),
  employeeId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
  triggerId: z.string(),
});

export type WaitingTriggerBlock = z.infer<typeof WaitingTriggerBlockSchema>;

/////////////////////////////////////////
// WAITING TRIGGER BLOCK OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const WaitingTriggerBlockOptionalDefaultsSchema = WaitingTriggerBlockSchema.merge(
  z.object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
  })
);

export type WaitingTriggerBlockOptionalDefaults = z.infer<
  typeof WaitingTriggerBlockOptionalDefaultsSchema
>;

export default WaitingTriggerBlockSchema;
