import { z } from "zod";

import { OnnEventEvaluationFieldAccessControlTypeSchema } from "../inputTypeSchemas/OnnEventEvaluationFieldAccessControlTypeSchema";

/////////////////////////////////////////
// ONN EVENT EVALUATION TEXT FIELD SCHEMA
/////////////////////////////////////////

export const OnnEventEvaluationTextFieldSchema = z.object({
  accessControlType: OnnEventEvaluationFieldAccessControlTypeSchema,
  id: z.string(),
  tenantId: z.string(),
  label: z.string(),
  order: z.number(),
  onnEventId: z.string(),
  hasInputTemplate: z.boolean(),
  inputTemplate: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type OnnEventEvaluationTextField = z.infer<typeof OnnEventEvaluationTextFieldSchema>;

/////////////////////////////////////////
// ONN EVENT EVALUATION TEXT FIELD OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const OnnEventEvaluationTextFieldOptionalDefaultsSchema =
  OnnEventEvaluationTextFieldSchema.merge(
    z.object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
  );

export type OnnEventEvaluationTextFieldOptionalDefaults = z.infer<
  typeof OnnEventEvaluationTextFieldOptionalDefaultsSchema
>;

export default OnnEventEvaluationTextFieldSchema;
