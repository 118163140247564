import liff from "@line/liff";
import { Box, List } from "@material-ui/core";
import React, { FC, ReactNode, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { SpaceSwitcherController } from "../Sidebar/parts/SpaceSwitcherController";

import { SettingSidebar } from "./SettingSidebar";

import { Divider, GlobalNaviMenu, Header } from "~/components/uiParts";
import { AccountSettingButton } from "~/components/uiParts/GlobalNaviMenu/parts/AccountSettingButton";
import { ListItem } from "~/components/uiParts/GlobalNaviMenu/parts/ListItem";

import { LogoutButton } from "~/components/uiParts/GlobalNaviMenu/parts/LogoutButton";
import { appUrl } from "~/config/url";
import { useContactContext } from "~/hooks/contactMessage/useContactContext";
import { useCurrentUser } from "~/hooks/employee/useCurrentUser";
import { useShouldSPView } from "~/hooks/shared/useShouldSPView";
import { useTenant } from "~/hooks/tenant";
import { mixin } from "~/util";

type Props = {
  children: ReactNode;
};

export const AdminSettingLayout: FC<Props> = (props) => {
  const { currentUser } = useCurrentUser();
  const { tenant } = useTenant();
  const navigate = useNavigate();
  const { children } = props;
  const shouldSPView = useShouldSPView();

  const { totalNotificationCount } = useContactContext();

  const isDisplayContactBadge = useMemo(() => {
    return totalNotificationCount > 0;
  }, [totalNotificationCount]);

  const handleClickLogo = (): void => {
    navigate("/");
  };

  return (
    <div>
      {!shouldSPView && (
        <SettingSidebar isMember={currentUser.isMember()} isAdmin={currentUser.isAdmin()} />
      )}
      <StyledBox>
        {shouldSPView && (
          <Header
            isTenantLogoUsed={false}
            currentTenantId={currentUser.tenantId}
            onClickLogo={handleClickLogo}
            drawerMenu={
              <GlobalNaviMenu
                isBadge={isDisplayContactBadge}
                logoInfo={{ isDisplay: true, handleClick: handleClickLogo }}
                renderDrawerContent={() => (
                  <Box display="flex" flexDirection="column" gridRowGap="16px">
                    {/* Spaceの個数によっては何もレンダリングされないケースがあるが、その判定はSpaceProviderに依存しているためこのコンポーネントでは判別できない */}
                    {tenant.isActiveNewGraduate && currentUser.isRegularAcceptanceEmployee() && (
                      <SpaceSwitcherController isExpand ifFullWidthMenu />
                    )}
                    <List
                      style={{ display: "flex", flexDirection: "column", rowGap: "16px" }}
                      disablePadding
                    >
                      <ListItem label="候補者" to="/" icon="onboarding" />
                      <ListItem label="イベント" to="/events" icon="calendar" />
                      <ListItem label="タスク" to="/onn_tasks" icon="checkOutlineCircle" />
                      <ListItem
                        label="コンタクト"
                        to="/contact_rooms"
                        icon="paperAirplane"
                        badgeLabel={
                          0 < totalNotificationCount ? totalNotificationCount.toString() : undefined
                        }
                      />
                      <ListItem label="ツール" to="/tools" icon="suitcase" />
                      <Divider margin={0} orientation="horizontal" />
                      <ListItem
                        label="設定"
                        to={currentUser.isAdmin() ? "/settings/admin" : "/settings/account"}
                        icon="setting"
                      />
                      {/* {tenant.isActiveNewGraduate && <NotificationSidebarListItem />} */}
                      {!tenant.isActiveNewGraduate && (
                        <ListItem
                          label="Onnの使い方"
                          to={appUrl.HOW_TO_USE}
                          icon="navi"
                          targetBlank
                          externalLink
                        />
                      )}
                      {!tenant.isActiveNewGraduate && (
                        <ListItem
                          label="フィードバック"
                          to={appUrl.FEEDBACK}
                          icon="feedback"
                          targetBlank
                          externalLink
                        />
                      )}
                      <Divider margin={0} orientation="horizontal" />
                      <AccountSettingButton currentUser={currentUser} />
                      <Divider margin={0} orientation="horizontal" />
                      {/* TODO: コンポーネント分割するなどして、liff を排除する */}
                      {!liff.isInClient() && (
                        <Box pb="16px">
                          <LogoutButton />
                        </Box>
                      )}
                    </List>
                  </Box>
                )}
              />
            }
          />
        )}
        {children}
      </StyledBox>
    </div>
  );
};

const StyledBox = styled(Box)`
  width: ${(props) => `calc(100% - ${props.theme.constants.SIDEBAR_OPEN_WIDTH}px);`};
  margin-left: ${(props) => `${props.theme.constants.SIDEBAR_OPEN_WIDTH}px;`};
  background-color: ${(props) => props.theme.palette.grey[50]};
  ${mixin.sp`
    width: 100%;
    margin-left: 0;
    background-color: white;
  `}
`;
