import { Stack } from "@mui/material";
import { NewGraduate } from "@onn/common";
import React from "react";

import { Condition } from "../../type";

import { SelectScenarioAndStatusForm } from "~/components/domains/scenario/SelectScenarioAndStatusForm";
import { useRecruitmentStatusList } from "~/components/providers/ScenarioProvider";

/**
 * 選考ステータス選択タブ
 * - [NOTE] 将来的には、シナリオもここで選択する予定
 */
export const RecruitmentStatusTabPanel = ({
  selectedCondition,
  toggleScenarioSelection,
  changeSelectedRecruitmentStatusId,
  currentScenarios,
}: {
  selectedCondition: Condition;
  toggleScenarioSelection: ({ scenarioId }: { scenarioId: string }) => void;
  changeSelectedRecruitmentStatusId: ({
    scenarioId,
    recruitmentStatusId,
  }: {
    scenarioId: string;
    recruitmentStatusId: string;
  }) => void;
  currentScenarios: NewGraduate["scenarios"];
}) => {
  const { scenariosWithRecruitmentStatuses } = useRecruitmentStatusList();

  return (
    <Stack py={"24px"} height={"300px"} gap={2} sx={{ overflowY: "scroll" }}>
      {scenariosWithRecruitmentStatuses.map(({ scenario, recruitmentStatuses }) => {
        // すでに紐づいているシナリオかどうか判定するために、currentScenariosから取得
        const scenarioAlreadyRelated = currentScenarios.find(
          (currentScenario) => currentScenario.scenarioId === scenario.id
        );
        const selectedScenario = selectedCondition.scenarios.find(
          (v) => v.scenarioId === scenario.id
        );

        return (
          <SelectScenarioAndStatusForm
            key={scenario.id}
            value={selectedScenario}
            toggleScenarioSelection={toggleScenarioSelection}
            onChangeStatus={changeSelectedRecruitmentStatusId}
            scenario={scenario}
            disabled={!!scenarioAlreadyRelated}
            recruitmentStatuses={recruitmentStatuses}
          />
        );
      })}
    </Stack>
  );
};
