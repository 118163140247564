import { BusinessHoursSetting } from "@onn/common";
import useSWR from "swr";

import { apiClient } from "~/libs";

export const useBusinessHoursSettings = () => {
  return useSWR("/api/business-hours-settings", async (endpoint) => {
    return apiClient.get(endpoint).then((res) => {
      return res.data.map((businessHoursSetting) => new BusinessHoursSetting(businessHoursSetting));
    });
  });
};
