import { Box, Tab } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { Employee } from "@onn/common";
import React, { FC, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { OnnTaskTab } from "./onnTaskTab/OnnTaskTab";

import { NewGraduateSummary } from "~/components/domains/employees/NewGraduateSummary";

import { RecruitmentProcessTab } from "~/components/domains/employees/RecruitmentProcessTab";
import { Loading, Typography } from "~/components/uiParts";
import { useAccessControl } from "~/hooks/accessControl";
import { useCurrentUser } from "~/hooks/employee";
import { useNewGraduate } from "~/hooks/employee/useNewGraduate";
import { useQuery } from "~/hooks/shared";
import { NotFound } from "~/pages/NotFound";
import { InformationTab } from "~/pages/employee/NewGraduate/InformationTab";

type TabValue = "recruitment-process" | "information" | "onnTask";
// URLのアンカーに利用される

const getIsDisplayAllTabs = (currentUser: Employee, newGraduate: Employee, isEditable: boolean) => {
  return (
    isEditable ||
    newGraduate.mentorUserId === currentUser.id ||
    newGraduate.supportMemberEmployeeIds?.includes(currentUser.id)
  );
};

export const NewGraduateEmployeeDetail: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id: employeeId } = useParams<"id">();
  const { query } = useQuery();

  const manageWelcomeMessageModalOpened = query.get("manageWelcomeMessageModal") === "true";

  const [activeTab, setActiveTab] = useState<TabValue>("recruitment-process");

  const { currentUser } = useCurrentUser();
  const { isEditable } = useAccessControl();
  const { data: newGraduate, isLoading: isLoadingNewGraduate } = useNewGraduate({
    newGraduateId: employeeId,
  });

  useEffect(() => {
    switch (location.hash) {
      case "#information": {
        setActiveTab("information");
        break;
      }
      case "#onnTask": {
        setActiveTab("onnTask");
        break;
      }
      default: {
        void 0;
      }
    }
  }, [currentUser, isEditable, location.hash, navigate]);

  if (isLoadingNewGraduate) {
    return <Loading size="large" fullHeight />;
  }

  if (!newGraduate) {
    return <NotFound />;
  }

  const handleChangeActiveTab = (_: unknown, newValue: TabValue) => {
    navigate(`#${newValue}`);
    setActiveTab(newValue);
  };

  const tabs: Array<{ label: string; value: TabValue }> = [
    {
      label: "選考情報",
      value: "recruitment-process",
    },
    {
      label: "候補者情報",
      value: "information",
    },
    {
      label: "タスク",
      value: "onnTask",
    },
  ];

  return (
    <>
      <StyledLink to="/">
        <Typography variant="body2" color="textSecondary">
          ← 候補者｜ステータス
        </Typography>
      </StyledLink>
      <NewGraduateSummary
        newGraduate={newGraduate}
        currentUser={currentUser}
        manageWelcomeMessageModalOpened={manageWelcomeMessageModalOpened}
      />
      <Box mt={3}>
        <TabContext value={activeTab}>
          <StyledTabList
            onChange={handleChangeActiveTab}
            indicatorColor="primary"
            textColor="primary"
          >
            {tabs.map((tab) => (
              <StyledTab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </StyledTabList>
          {getIsDisplayAllTabs(
            currentUser,
            newGraduate,
            currentUser.isAdmin() ||
              newGraduate.getMentorAndSupportMemberIds().includes(currentUser.id)
          ) && (
            <StyledTabPanel value="recruitment-process">
              <RecruitmentProcessTab newGraduate={newGraduate} />
            </StyledTabPanel>
          )}
          {getIsDisplayAllTabs(currentUser, newGraduate, isEditable(newGraduate)) && (
            <StyledTabPanel value="information">
              <InformationTab newGraduate={newGraduate} />
            </StyledTabPanel>
          )}
          {getIsDisplayAllTabs(currentUser, newGraduate, isEditable(newGraduate)) && (
            <StyledTabPanel value="onnTask">
              <OnnTaskTab newGraduate={newGraduate} />
            </StyledTabPanel>
          )}
        </TabContext>
      </Box>
    </>
  );
};

const StyledLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
  margin-bottom: 48px;
`;

const StyledTabList = styled(TabList)`
  &.MuiTabs-root {
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const StyledTab = styled(Tab)`
  &.MuiTab-root {
    margin-right: 40px;
  }
`;

const StyledTabPanel = styled(TabPanel)`
  &.MuiTabPanel-root {
    padding: 0;
  }
`;
