import { NewGraduate } from "./NewGraduate";

export class NewGraduateToListView extends NewGraduate {
  employeeTagIds: string[];
  unansweredOnnTaskCount: number;
  schoolName: string | null;
  affiliationFullName: string | null;
  phoneNumber: string | null;
  scenarios: (NewGraduate["scenarios"][number] & {
    predictionId: string | null;
  })[];

  constructor(
    employee: NewGraduate,
    employeeTagIds: string[],
    unansweredOnnTaskCount: number,
    schoolName: string | null,
    affiliationFullName: string | null,
    phoneNumber: string | null,
    scenarios: NewGraduateToListView["scenarios"]
  ) {
    super(employee);
    this.employeeTagIds = employeeTagIds;
    this.unansweredOnnTaskCount = unansweredOnnTaskCount;
    this.schoolName = schoolName;
    this.affiliationFullName = affiliationFullName;
    this.phoneNumber = phoneNumber;
    this.scenarios = scenarios;
  }
}
