import { RecruitmentStatus, RecruitmentStatusWithRelatedInfo } from "@onn/common";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useRecruitmentStatusList } from "~/components/providers/ScenarioProvider";
import { useRecruitmentStatusesWithRelations } from "~/hooks/recruitmentStatusWithRelation";

export const useSelectedRecruitmentStatus = ({
  scenarioId,
  defaultRecruitmentStatus,
}: {
  scenarioId: string;
  defaultRecruitmentStatus: RecruitmentStatus;
}) => {
  const navigate = useNavigate();
  const { recruitmentStatuses } = useRecruitmentStatusList();
  const [selectedRecruitmentStatusId, setSelectedRecruitmentStatusId] = useState<
    RecruitmentStatus["id"]
  >(defaultRecruitmentStatus.id);
  const selectedRecruitmentStatus = recruitmentStatuses.find(
    (v) => v.id === selectedRecruitmentStatusId
  );
  // 選択中の選考ステータスは必ず存在するため、undefinedの場合はエラーを投げる
  if (!selectedRecruitmentStatus) throw new Error("selectedRecruitmentStatus is undefined");

  // URLのアンカーによる選考ステータスの選択
  useEffect(() => {
    const statusLabel = decodeURI(location.hash.replace("#", ""));
    if (!statusLabel) return;

    const status = recruitmentStatuses.find(
      (s) => s.label === statusLabel && s.scenarioId === scenarioId
    );
    if (status) setSelectedRecruitmentStatusId(status.id);
  }, [recruitmentStatuses, scenarioId]);

  const resetSelectedRecruitmentStatus = useCallback(() => {
    setSelectedRecruitmentStatusId(defaultRecruitmentStatus.id);
  }, [defaultRecruitmentStatus]);

  const changeSelectedRecruitmentStatusId = useCallback(
    (id: RecruitmentStatus["id"]) => {
      setSelectedRecruitmentStatusId(id);

      const status = recruitmentStatuses.find((s) => s.id === id);
      if (status) navigate(`#${status.label}`);
    },
    [navigate, recruitmentStatuses]
  );

  return {
    selectedRecruitmentStatus,
    changeSelectedRecruitmentStatusId,
    resetSelectedRecruitmentStatus,
    ...useWarningRecruitmentStatuses(),
  };
};

const useWarningRecruitmentStatuses = () => {
  const { data: recruitmentStatuses = [] } = useRecruitmentStatusesWithRelations({
    withDeleted: false,
  });

  const [initialWarningRecruitmentStatuses] = useState<RecruitmentStatusWithRelatedInfo[]>(
    recruitmentStatuses.filter(
      (s) => s.isRelatedTriggerForDeletedStatus || s.isRelatedActionForDeletedStatus
    )
  );
  const [warningRecruitmentStatuses, setWarningRecruitmentStatuses] = useState<RecruitmentStatus[]>(
    initialWarningRecruitmentStatuses
  );
  const resetWarningRecruitmentStatuses = useCallback(() => {
    setWarningRecruitmentStatuses(initialWarningRecruitmentStatuses);
  }, [initialWarningRecruitmentStatuses]);

  return {
    warningRecruitmentStatuses,
    setWarningRecruitmentStatuses,
    resetWarningRecruitmentStatuses,
  };
};

/**
 * シナリオ編集画面にアクセスした際のデフォルトの選考ステータスを取得する
 */
export const useGetDefaultRecruitmentStatus = () => {
  const { recruitmentStatuses } = useRecruitmentStatusList();

  const getDefaultRecruitmentStatus = useCallback(
    ({ scenarioId }: { scenarioId: string }) => {
      const recruitmentStatusesRelatedToScenario = recruitmentStatuses.filter(
        (rs) => rs.scenarioId === scenarioId
      );
      const defaultRecruitmentStatus = recruitmentStatusesRelatedToScenario[0];
      if (!defaultRecruitmentStatus) {
        throw new Error("関連する選考ステータスが存在しません");
      }
      return defaultRecruitmentStatus;
    },
    [recruitmentStatuses]
  );

  return { getDefaultRecruitmentStatus };
};
