import { Box, FormControlLabel } from "@material-ui/core";
import { Stack } from "@mui/material";
import React from "react";
import { useController } from "react-hook-form";
import styled from "styled-components";

import { InputState } from "../../../../hooks/form/InputState";

import { FieldBase } from "./_shared/FieldBase";

import { FieldBaseProps } from "./_shared/types";

import { Checkbox, TextareaAutosize, Typography } from "~/components/uiParts";

type Props = FieldBaseProps;
export const TextTypeField: React.FC<Props> = ({
  dragHandleProps,
  fieldIndex,
  onRemoveField,
  onDuplicateField,
  isFinalField,
}) => {
  const { field } = useController<InputState, `evaluationFields.${number}.hasInputTemplate`>({
    name: `evaluationFields.${fieldIndex}.hasInputTemplate` as const,
  });

  return (
    <FieldBase
      dragHandleProps={dragHandleProps}
      fieldIndex={fieldIndex}
      onRemoveField={onRemoveField}
      onDuplicateField={onDuplicateField}
      isFinalField={isFinalField}
    >
      <InputTemplateCheckBox fieldIndex={fieldIndex} />
      {field.value && <InputTemplateTextArea fieldIndex={fieldIndex} />}
    </FieldBase>
  );
};

const InputTemplateCheckBox: React.FC<{
  fieldIndex: number;
}> = ({ fieldIndex }) => {
  const { field } = useController<InputState, `evaluationFields.${number}.hasInputTemplate`>({
    name: `evaluationFields.${fieldIndex}.hasInputTemplate` as const,
  });

  return (
    <StyledFormControlLabel
      control={<Checkbox checked={field.value} onClick={field.onChange} />}
      label={
        <Box width="100%" display="flex">
          <StyledTypography variant="body2" noWrap>
            {"入力内容のフォーマットを設定する"}
          </StyledTypography>
        </Box>
      }
    />
  );
};

const InputTemplateTextArea: React.FC<{
  fieldIndex: number;
}> = ({ fieldIndex }) => {
  const { field } = useController<InputState, `evaluationFields.${number}.inputTemplate`>({
    name: `evaluationFields.${fieldIndex}.inputTemplate`,
  });

  return (
    <Stack spacing={"8px"}>
      <Typography variant="body2" bold>
        {"入力フォーマット"}
      </Typography>
      <TextareaAutosize
        rowsMin={3}
        style={{
          width: "100%",
          resize: "none",
          padding: "8px",
          borderRadius: "4px",
        }}
        value={field.value}
        onChange={field.onChange}
      />
    </Stack>
  );
};

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    width: 100%;
    margin-left: 0px;
    gap: 8px;
    height: 24px;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
  .MuiCheckbox-root {
    padding: 0px 0px;
    margin: 11.5px 0px;
  }
`;

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    display: block;
    padding: 0;
  }
`;
