import { v4 } from "uuid";

import { IRecruitmentStatusRelationSchema, recruitmentStatusRelationSchema } from "./schema";

export class RecruitmentStatusRelation implements IRecruitmentStatusRelationSchema {
  static readonly validator = recruitmentStatusRelationSchema;

  id: string;
  tenantId: string;
  recruitmentStatusId: string;
  employeeId: string;
  isActive: boolean;
  createdAt: Date;
  updatedAt: Date;

  constructor(init: ExcludeMethods<RecruitmentStatusRelation>) {
    const parsedInit = RecruitmentStatusRelation.validator.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.recruitmentStatusId = parsedInit.recruitmentStatusId;
    this.employeeId = parsedInit.employeeId;
    this.isActive = parsedInit.isActive;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  public static createNew(
    params: Omit<
      ExcludeMethods<RecruitmentStatusRelation>,
      "id" | "isActive" | "createdAt" | "updatedAt"
    >
  ): RecruitmentStatusRelation {
    return new RecruitmentStatusRelation({
      ...params,
      id: v4(),
      isActive: true,
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }
}
