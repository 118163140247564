import { Box } from "@material-ui/core";

import React, { FC, useCallback } from "react";
import styled from "styled-components";

import {
  IconButton,
  TooltipWhenTextTruncated,
  Typography,
  UncontrolledMenu,
} from "~/components/uiParts";
import { TableRowWrapper } from "~/components/uiParts/VirtualizedTable";
import { TableRow } from "~/components/uiParts/VirtualizedTable/TableRow";
import { VirtualizedTableV2 } from "~/components/uiParts/VirtualizedTable/VirtualizedTableV2";
import { useCurrentUser } from "~/hooks/employee";
import { useModal } from "~/hooks/modal";
import { useDuplicateScenario } from "~/hooks/scenario/useDuplicateScenario";
import { useScenariosWithInfo } from "~/hooks/scenario/useScenariosWithInfo";

type Props = {
  onClickRichMenuSetting: (scenarioId: string) => void;
};
// 複数シナリオが作成できるようになるときに作り直す
export const ScenarioListTable: FC<Props> = ({ onClickRichMenuSetting }) => {
  const widthOptions = ["75%", "15%", "10%"];
  const { currentUser } = useCurrentUser();

  const {
    data: scenariosWithInfo = [],
    isLoading,
    mutate: mutateScenariosWithInfo,
  } = useScenariosWithInfo();
  const { onClickUpdateScenario } = useOnClickUpdateScenario();

  const onSuccessHandler = useCallback(() => {
    mutateScenariosWithInfo();
  }, [mutateScenariosWithInfo]);
  const { duplicateScenario } = useDuplicateScenario({ onSuccess: onSuccessHandler });

  return (
    <VirtualizedTableV2
      rowHeight={90}
      widthOptions={widthOptions}
      isLoading={isLoading}
      headers={[
        {
          text: "タイトル",
        },
        {
          text: "配信中候補者数",
        },
        {
          text: "",
        },
      ]}
      rows={scenariosWithInfo}
      rowRenderer={({ key, index, style, rowData: scenariosWithInfo }) => {
        const { scenario, countOfNewGraduateRelatedScenario } = scenariosWithInfo;

        const contents = [
          <TitleCell key={`${key}-TitleCell`} name={scenario.name} />,
          <CountOfNewGraduatesCell
            key={`${key}-CountOfNewGraduatesCell`}
            count={countOfNewGraduateRelatedScenario}
          />,
        ];
        if (currentUser.isAdmin()) {
          contents.push(
            <ScenarioManageMenu
              key={`${key}-ScenarioManageMenu`}
              onClickRichMenuSetting={() => onClickRichMenuSetting(scenario.id)}
              onClickUpdateScenario={() =>
                onClickUpdateScenario({ scenarioId: scenario.id, scenarioName: scenario.name })
              }
              onClickDuplicateScenario={() => duplicateScenario({ scenarioId: scenario.id })}
              isCommonScenario={scenario.name === "共通"}
            />
          );
        }
        return (
          <TableRowWrapper key={key} index={index} {...style}>
            <TableRow
              row={{
                contents,
                to: `/scenarios/${scenario.id}`,
                isTargetBlank: false,
              }}
              widthOptions={widthOptions}
            />
          </TableRowWrapper>
        );
      }}
    />
  );
};

const TitleCell: FC<{ name: string }> = ({ name }) => {
  return (
    <Box>
      <Box>
        <TooltipWhenTextTruncated text={name}>
          {(ref) => (
            <StyledTitle ref={ref} variant="body1" bold>
              {name}
            </StyledTitle>
          )}
        </TooltipWhenTextTruncated>
      </Box>
    </Box>
  );
};

const CountOfNewGraduatesCell: FC<{ count: number }> = ({ count }) => {
  return (
    <Typography variant="h4" color="textPrimary">
      {count}
    </Typography>
  );
};

const useOnClickUpdateScenario = () => {
  const { handleModal } = useModal();
  const onClickUpdateScenario = useCallback(
    ({ scenarioId, scenarioName }: { scenarioId: string; scenarioName: string }) => {
      handleModal({
        name: "addOrUpdateScenarioModal",
        args: {
          data: {
            type: "update",
            scenarioId,
            scenarioName,
          },
        },
      });
    },
    [handleModal]
  );

  return { onClickUpdateScenario };
};

const ScenarioManageMenu: FC<{
  onClickRichMenuSetting: () => void;
  onClickUpdateScenario: () => void;
  onClickDuplicateScenario: () => void;
  isCommonScenario: boolean;
}> = ({
  onClickRichMenuSetting,
  onClickUpdateScenario,
  onClickDuplicateScenario,
  isCommonScenario,
}) => {
  const menuItemOptions = [
    {
      onClick: onClickRichMenuSetting,
      text: "未登録者用リッチメニュー設定",
    },
    {
      onClick: onClickDuplicateScenario,
      text: "複製",
    },
  ];

  if (!isCommonScenario) {
    menuItemOptions.push({
      onClick: onClickUpdateScenario,
      text: "編集",
    });
  }

  return (
    <UncontrolledMenu
      renderButton={(openMenu) => <IconButton icon="menuVert" onClick={openMenu} />}
      menuItemOptions={menuItemOptions}
    />
  );
};

const StyledTitle = styled(Typography)`
  padding-right: 12px;
  margin-right: 10px;
  position: relative;
  display: inline-block;
  &.MuiTypography-root {
    display: -webkit-box;
    overflow: hidden;
    word-break: break-all;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
`;
