import { APISchema } from "@onn/common";
import { useSnackbar } from "notistack";
import { useCallback, useContext } from "react";

import { ScenarioContext } from "~/components/providers/ScenarioProvider";
import { apiClient } from "~/libs";
import { ApiResponseError } from "~/util";

type EndPoint = APISchema["/scenario_api/scenarios"]["PATCH"];

export const useUpdateScenario = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { mutateContext } = useContext(ScenarioContext);

  const updateScenario = useCallback(
    async ({
      scenarioId,
      scenarioName,
      onSuccess,
    }: {
      scenarioId: string;
      scenarioName: string;
      onSuccess?: () => Promise<void> | void;
    }) => {
      const body: EndPoint["body"] = {
        scenarioId,
        scenarioName,
      };
      try {
        await apiClient.patch(`/scenario_api/scenarios`, body);
        mutateContext();
        onSuccess?.();
        enqueueSnackbar("シナリオを更新しました", { variant: "success" });
      } catch (e) {
        if (e instanceof ApiResponseError && e.status === 400) {
          enqueueSnackbar(e.message, {
            variant: "error",
          });
        } else {
          enqueueSnackbar("シナリオの更新に失敗しました。管理者までお問い合わせください。", {
            variant: "error",
          });
        }
      }
    },
    [enqueueSnackbar, mutateContext]
  );

  return { updateScenario };
};
