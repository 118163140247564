import { v4 } from "uuid";

import { OnnFormTaskAnswer } from "../../domain";

export const createIMockOnnFormTaskAnswer = (
  mock: Partial<OnnFormTaskAnswer> = {}
): OnnFormTaskAnswer => {
  return new OnnFormTaskAnswer({
    id: mock.id ?? "TaskMemo1",
    formTaskId: mock.formTaskId ?? "formTask1",
    employeeId: mock.employeeId ?? "employee1",
    tenantId: mock.tenantId ?? "tenant1",
    lastReadAt: mock.lastReadAt ?? null,
    firstReadAt: mock.firstReadAt ?? null,
    lastAnsweredAt: mock.lastAnsweredAt ?? null,
    answeredFormRevisionId: mock.answeredFormRevisionId ?? null,
    createdAt: mock.createdAt ?? new Date(),
    updatedAt: mock.updatedAt ?? new Date(),
    answers: mock.answers ?? [
      {
        id: v4(),
        questionId: "question1",
        type: "CHECK_BOX",
        selectedOptionIds: ["option1", "option2"],
        freeText: "自由記述",
      },
      {
        id: v4(),
        questionId: "question2",
        type: "TEXT",
        value: "テキスト",
      },
      {
        id: v4(),
        questionId: "question3",
        type: "RADIO",
        selectedOptionId: "option1",
      },
      {
        id: v4(),
        questionId: "question4",
        type: "FILE",
        filePath: "sample.pdf",
      },
    ],
  });
};

export const createIMockOnnFormTaskAnswers = ({
  commonValue,
  values,
}: {
  commonValue: Partial<OnnFormTaskAnswer>;
  values: Array<Partial<OnnFormTaskAnswer>>;
}): OnnFormTaskAnswer[] => {
  return values.map((value) =>
    createIMockOnnFormTaskAnswer({
      // ユニーク制約があるため、適当な値を入れる
      id: v4(),

      ...commonValue,
      ...value,
    })
  );
};
