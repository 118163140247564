import { z } from "zod";

import { OnnEventEvaluationFieldAccessControlTypeSchema } from "../inputTypeSchemas/OnnEventEvaluationFieldAccessControlTypeSchema";

/////////////////////////////////////////
// ONN EVENT EVALUATION SINGLE SELECT FIELD SCHEMA
/////////////////////////////////////////

export const OnnEventEvaluationSingleSelectFieldSchema = z.object({
  accessControlType: OnnEventEvaluationFieldAccessControlTypeSchema,
  id: z.string(),
  tenantId: z.string(),
  label: z.string(),
  order: z.number(),
  onnEventId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type OnnEventEvaluationSingleSelectField = z.infer<
  typeof OnnEventEvaluationSingleSelectFieldSchema
>;

/////////////////////////////////////////
// ONN EVENT EVALUATION SINGLE SELECT FIELD OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const OnnEventEvaluationSingleSelectFieldOptionalDefaultsSchema =
  OnnEventEvaluationSingleSelectFieldSchema.merge(
    z.object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
  );

export type OnnEventEvaluationSingleSelectFieldOptionalDefaults = z.infer<
  typeof OnnEventEvaluationSingleSelectFieldOptionalDefaultsSchema
>;

export default OnnEventEvaluationSingleSelectFieldSchema;
