import { generateNextPlanDomain, NextPlan } from "@onn/common";
import useSWR, { mutate } from "swr";

import { apiClient } from "~/libs";
import { generateDataLoader } from "~/libs/generateDataLoader";

const dataLoader = generateDataLoader<string, NextPlan[]>(async (keys) => {
  const results = await apiClient.get("/api/new-graduates/next-plans", {
    newGraduateIds: keys,
  });

  return keys.map((key) =>
    (results.data[key] || [])
      .map(generateNextPlanDomain)
      .filter((plan): plan is NextPlan => plan !== null)
  );
});

const generateKey = ({ newGraduateId }: { newGraduateId: string }) => {
  return { url: "/api/new-graduates/next-plans" as const, newGraduateId };
};

export const useNewGraduateNextPlan = ({ newGraduateId }: { newGraduateId: string }) => {
  return useSWR(generateKey({ newGraduateId }), async ({ newGraduateId }) => {
    return await dataLoader.load(newGraduateId);
  });
};

export const mutateNewGraduateNextPlan = ({ newGraduateId }: { newGraduateId: string }) => {
  return mutate(generateKey({ newGraduateId }));
};
