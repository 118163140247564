import {
  IOnnEventEvaluationSingleSelectOption,
  onnEventEvaluationSingleSelectOptionSchema,
} from "./schema";

export class OnnEventEvaluationSingleSelectOption implements IOnnEventEvaluationSingleSelectOption {
  static validator = onnEventEvaluationSingleSelectOptionSchema;

  id: string;
  tenantId: string;
  onnEventEvaluationSingleSelectFieldId: string;
  label: string;
  order: number;

  createdAt: Date;
  updatedAt: Date;

  constructor(init: ExcludeMethods<OnnEventEvaluationSingleSelectOption>) {
    const parsedInit = OnnEventEvaluationSingleSelectOption.validator.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.onnEventEvaluationSingleSelectFieldId = parsedInit.onnEventEvaluationSingleSelectFieldId;
    this.label = parsedInit.label;
    this.order = parsedInit.order;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  static createNewWithGeneratedId(
    params: Omit<ExcludeMethods<OnnEventEvaluationSingleSelectOption>, "createdAt" | "updatedAt">
  ): OnnEventEvaluationSingleSelectOption {
    return new OnnEventEvaluationSingleSelectOption({
      ...params,
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  update(
    params: Pick<OnnEventEvaluationSingleSelectOption, "label" | "order">
  ): OnnEventEvaluationSingleSelectOption {
    return new OnnEventEvaluationSingleSelectOption({
      ...this,
      ...params,
      updatedAt: new Date(),
    });
  }
}
