import { Box } from "@material-ui/core";
import React, { FC, useMemo } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { LineButton } from "./_shared/LineButton";

import { Button, Divider, Typography } from "~/components/uiParts";
import { CenterPaper } from "~/components/uiParts/CenterPaper";
import { useNavigateWithQuery } from "~/hooks/shared/useNavigateWithQuery";

type Props = {
  start: {
    startEmailRegistration: () => void;
    startLineRegistration: () => void;
  };
  canSelectMail: boolean;
  canNavigateToLogin: boolean;
};

export const SelectionPage: FC<Props> = ({
  start: { startEmailRegistration, startLineRegistration },
  canSelectMail,
  canNavigateToLogin,
}) => {
  const navigate = useNavigateWithQuery();
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const notice = (location.state as any | null)?.notice || null;
  const shouldDisplayNotice = useMemo(
    () =>
      notice ? (
        <StyledNoticeBox>
          <Typography variant="body2" color="textPrimary" style={{ fontWeight: 700 }}>
            {notice}
            <br />
            以下からアカウント登録を行なってください。
          </Typography>
        </StyledNoticeBox>
      ) : null,
    [notice]
  );

  return (
    <CenterPaper isGrayColorInSP={true}>
      <Box display="flex" flexDirection="column" gridRowGap="40px">
        {shouldDisplayNotice}
        <Box display="flex" flexDirection="column" gridRowGap="16px">
          <Typography variant="h2" color="textPrimary" style={{ fontWeight: 700 }}>
            アカウント登録
          </Typography>
          <Typography variant="body1" color="textPrimary">
            アカウント登録を行うと、担当者からの連絡や最新情報を受け取ることができます。
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" gridRowGap="24px">
          <LineButton type="registration" onClick={startLineRegistration} />

          {canSelectMail && (
            <StyledButtonWrapper>
              <Button
                fullWidth
                variant="text"
                color="primary"
                borderRadius="circle"
                onClick={startEmailRegistration}
              >
                <Typography variant="caption" bold>
                  LINEで登録できない方はこちら
                </Typography>
              </Button>
            </StyledButtonWrapper>
          )}
        </Box>

        {canNavigateToLogin && (
          <>
            <Divider margin={0} />

            <StyledButtonWrapper>
              <Button
                fullWidth
                variant="text"
                color="primary"
                borderRadius="circle"
                onClick={() => navigate("/portal/accounts/login")}
              >
                <Typography variant="caption" bold>
                  ログインはこちら
                </Typography>
              </Button>
            </StyledButtonWrapper>
          </>
        )}
      </Box>
    </CenterPaper>
  );
};

const StyledNoticeBox = styled(Box)`
  background: white;
  border: 1px solid ${(props) => props.theme.palette.primary.main};
  border-radius: 4px;
  padding: 16px;
`;

// NOTE: 今のButtonコンポーネントだとサイズを自由に変更ができないので、一旦styled-componentsで上書きしている
const StyledButtonWrapper = styled(Box)`
  .MuiButtonBase-root > div {
    padding: 4.5px 16px;
  }
`;
