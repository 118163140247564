import { postScenarioRecruitmentStatusSchema } from "@onn/common";
import { useCallback, useContext } from "react";

import { z } from "zod";

import { useSnackbar } from "../shared";

import { ScenarioContext } from "~/components/providers/ScenarioProvider";
import { apiClient } from "~/libs";

export const useEditScenarioRecruitmentStatus = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { mutateContext } = useContext(ScenarioContext);

  const editScenarioRecruitmentStatus = useCallback(
    async ({
      data,
      successMessage,
    }: {
      data: z.infer<typeof postScenarioRecruitmentStatusSchema>["body"];
      successMessage: string;
    }) => {
      await apiClient
        .post("/scenario_api/scenarios/recruitment-statuses", {
          recruitmentStatuses: data.recruitmentStatuses,
          scenarioId: data.scenarioId,
        })
        .then(() => {
          enqueueSnackbar(successMessage, { variant: "success" });
          mutateContext();
        })
        .catch((error) => {
          if (error instanceof Error) {
            enqueueSnackbar(error.message, { variant: "error" });
          }
        });
    },
    [enqueueSnackbar, mutateContext]
  );

  return { editScenarioRecruitmentStatus };
};
