import {
  ChangeRecruitmentStatusActionSetting,
  SendContactMessageActionSetting,
  DeliverOnnTaskActionSetting,
  DeliverOnnEventActionSetting,
} from "@onn/common";
import { useMemo } from "react";

import { InputBlockState } from "../../hooks/scenarioForm/InputState";
import { useEditModeContext } from "../EditModeContextProvider";

export const useIsActionRelatedDeletedStatus = (actions: InputBlockState["actions"]) => {
  const { recruitmentStatusesOfEditingScenarioMap } = useEditModeContext();
  const isActionRelatedDeletedStatus = useMemo(() => {
    return actions.some(({ actionSetting }) => {
      switch (actionSetting.type) {
        case ChangeRecruitmentStatusActionSetting.type: {
          const recruitmentStatus = recruitmentStatusesOfEditingScenarioMap.get(
            actionSetting.recruitmentStatusId
          );
          return recruitmentStatus?.deleted || false;
        }
        case SendContactMessageActionSetting.type:
        case DeliverOnnEventActionSetting.type:
        case DeliverOnnTaskActionSetting.type:
          return false;
        default:
          // eslint-disable-next-line no-case-declarations
          const _exhaustiveCheck: never = actionSetting;
          return;
      }
    });
  }, [actions, recruitmentStatusesOfEditingScenarioMap]);

  return { isActionRelatedDeletedStatus };
};
