import { z } from "zod";

/////////////////////////////////////////
// PORTAL RICH MENU RELATION SCHEMA
/////////////////////////////////////////

export const PortalRichMenuRelationSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  scenarioId: z.string(),
  recruitmentStatusId: z.string(),
  internalRichMenuId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type PortalRichMenuRelation = z.infer<typeof PortalRichMenuRelationSchema>;

/////////////////////////////////////////
// PORTAL RICH MENU RELATION OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const PortalRichMenuRelationOptionalDefaultsSchema = PortalRichMenuRelationSchema.merge(
  z.object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
  })
);

export type PortalRichMenuRelationOptionalDefaults = z.infer<
  typeof PortalRichMenuRelationOptionalDefaultsSchema
>;

export default PortalRichMenuRelationSchema;
