import { zodResolver } from "@hookform/resolvers/zod";
import { AnyOnnEventEvaluationField } from "@onn/common";
import { UseFormReturn, useForm as useRHF } from "react-hook-form";

import { InputState } from "./InputState";
import { generateFormSchema } from "./schema";

export const useForm = ({
  onnEventEvaluationFields,
}: {
  onnEventEvaluationFields: AnyOnnEventEvaluationField[];
}) => {
  const form = useRHF<InputState>({
    defaultValues: generateDefaultValues(onnEventEvaluationFields),
    resolver: zodResolver(generateFormSchema()),
    mode: "all",
  });

  const { submit } = useSubmit({ form });
  const isSubmitButtonDisabled =
    form.formState.isSubmitting || !form.formState.isDirty || !form.formState.isValid;

  return {
    RHForm: form,
    isSubmitButtonDisabled,
    submit,
  };
};

const useSubmit = ({ form }: { form: UseFormReturn<InputState> }) => {
  const submit = form.handleSubmit(
    (data) => {
      // TODO: 実装
      alert(JSON.stringify(data));
    },
    (e) => {
      console.error(e);
    }
  );

  return {
    submit,
  };
};

const generateDefaultValues = (onnEventEvaluationFields: AnyOnnEventEvaluationField[]) => {
  const defaultValues: InputState = {
    evaluationFields: onnEventEvaluationFields.flatMap((field) => {
      switch (field.type) {
        case "TextField":
          return {
            id: field.id,
            label: field.label,
            type: field.type,
            accessControl: field.accessControlType,
            hasInputTemplate: field.hasInputTemplate,
            inputTemplate: field.inputTemplate,
            isSavedField: true,
          };
        case "SingleSelectField":
          return {
            id: field.id,
            label: field.label,
            type: field.type,
            options: field.options.map((o) => {
              return {
                optionId: o.id,
                label: o.label,
              };
            }),
            accessControl: field.accessControlType,
            isSavedField: true,
          };
        case "MultipleSelectField":
          return {
            id: field.id,
            label: field.label,
            type: field.type,
            options: field.options.map((o) => {
              return {
                optionId: o.id,
                label: o.label,
              };
            }),
            accessControl: field.accessControlType,
            isSavedField: true,
          };
        // NOTE: ファイルタイプは固定値なのでRHFでは管理しない
        case "FileField": {
          return [];
        }
        default: {
          const _exhaustiveCheck: never = field;
          return _exhaustiveCheck;
        }
      }
    }),
  };

  return defaultValues;
};
