import { Box, Menu, MenuItem } from "@material-ui/core";
import { Employee } from "@onn/common";
import {
  BriefingSessionEvent,
  NewInterviewEvent,
} from "@onn/common/domain/OnnEvent/OnnEvent/OnnEvent";
import React, { useState, FC, MouseEvent, memo, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { ContentWithLabel } from "./ContentWithLabel";

import {
  IconButton,
  Paper,
  Typography,
  TooltipWhenTextTruncated,
  Loading,
} from "~/components/uiParts";
import { useModal } from "~/hooks/modal";
import { useDeleteOnnEvent } from "~/hooks/onnEvent";
import { useOnnEventAnswersForDisplay } from "~/hooks/onnEventAnswer/useOnnEventAnswersForDisplay";
import { useOnnEventSlotDatesForDisplay } from "~/hooks/onnEventSlotDates/useOnnEventSlotDatesForDisplay";

export type Props = {
  currentUser: Employee;
  onnEvent: NewInterviewEvent | BriefingSessionEvent;
};

export const EventDetailSummaryPaperForNewInterviewAndBriefingSession: FC<Props> = memo(
  ({ currentUser, onnEvent }) => {
    const navigation = useNavigate();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const { handleModal } = useModal();
    const { execDeleteOnnEvent } = useDeleteOnnEvent();

    const { data: onnEventSlotDatesForDisplay = [], isLoading: isLoadingFetchingSlots } =
      useOnnEventSlotDatesForDisplay(onnEvent.id);

    const { data: onnEventAnswersForDisplay = [], isLoading: isLoadingFetchingAnswers } =
      useOnnEventAnswersForDisplay(onnEvent.id);

    const handleOpenMenu = useCallback((event: MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    }, []);

    const handleCloseMenu = useCallback(() => {
      setAnchorEl(null);
    }, []);

    const onClickSlotDefaultValueSetting = () => {
      handleCloseMenu();
      navigation(`/events/${onnEvent.id}/slot_default_value_setting/?from_page=detail`);
    };

    const onClickDeliverySetting = () => {
      handleCloseMenu();
      navigation(`/events/${onnEvent.id}/delivery_setting/?from_page=detail`);
    };

    const onClickEditOnnEvent = () => {
      handleCloseMenu();
      navigation(`/events/${onnEvent.id}/edit/?from_page=detail`);
    };

    const onClickDeleteButton = () => {
      handleCloseMenu();
      handleModal({
        name: "eventDeleteConfirmModal",
        args: {
          eventTitle: onnEvent.title,
          onSubmit: async () => {
            await execDeleteOnnEvent(onnEvent.id);
            navigation(`/events?from_page=detail`);
          },
        },
      });
    };

    const publicSlotsCount = useMemo(() => {
      return onnEventSlotDatesForDisplay.filter((slot) => slot.isPublishedAndNotDone()).length;
    }, [onnEventSlotDatesForDisplay]);

    const determinedSlotsCountToInterviewInTheFuture = useMemo(() => {
      return onnEventAnswersForDisplay.filter((answer) =>
        answer.selectedOnnEventSlotDate ? !answer.selectedOnnEventSlotDate.isDone() : false
      ).length;
    }, [onnEventAnswersForDisplay]);

    return (
      <StyledPaper square={false}>
        <Box display="flex" alignItems="center" justifyContent="space-between" height={68}>
          <Box display="flex">
            <Box display="flex" alignItems="center" maxWidth="350px">
              <TooltipWhenTextTruncated text={onnEvent.title}>
                {(ref) => (
                  <StyledTitle ref={ref} bold variant="h4">
                    {onnEvent.title}
                  </StyledTitle>
                )}
              </TooltipWhenTextTruncated>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box ml="40px" minWidth={50}>
              <ContentWithLabel label="公開予約枠">
                {isLoadingFetchingSlots ? (
                  <Loading size="small" />
                ) : (
                  <Typography variant="h3">{publicSlotsCount}</Typography>
                )}
              </ContentWithLabel>
            </Box>
            <Box ml="40px" minWidth={50}>
              <ContentWithLabel label={onnEvent.type === "new_interview" ? "面談予定" : "開催予定"}>
                {isLoadingFetchingAnswers ? (
                  <Loading size="small" />
                ) : (
                  <Typography variant="h3">{determinedSlotsCountToInterviewInTheFuture}</Typography>
                )}
              </ContentWithLabel>
            </Box>
            {currentUser.isAdmin() && (
              <Box ml="40px">
                <IconButton icon="menuVert" onClick={handleOpenMenu} />
                <StyledMenu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleCloseMenu}
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                >
                  <MenuItem onClick={onClickEditOnnEvent}>編集</MenuItem>
                  <MenuItem onClick={onClickSlotDefaultValueSetting}>共通設定</MenuItem>
                  <MenuItem onClick={onClickDeliverySetting}>配信設定</MenuItem>
                  <MenuItem onClick={onClickDeleteButton}>削除</MenuItem>
                </StyledMenu>
              </Box>
            )}
          </Box>
        </Box>
      </StyledPaper>
    );
  }
);

const StyledPaper = styled(Paper)`
  padding: 24px;
`;

const StyledMenu = styled(Menu)`
  margin-top: 8px;
`;

const StyledTitle = styled(Typography)`
  &.MuiTypography-root {
    display: -webkit-box;
    overflow: hidden;
    word-break: break-all;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
`;
