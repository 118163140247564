import { OnnEventEvaluationFieldAccessControlType } from "../../OnnEventEvaluationFieldAccessControlType";

import { IOnnEventEvaluationTextField, onnEventEvaluationTextFieldSchema } from "./schema";

export class OnnEventEvaluationTextField implements IOnnEventEvaluationTextField {
  static validator = onnEventEvaluationTextFieldSchema;

  static readonly type = "TextField" as const;
  type = OnnEventEvaluationTextField.type;

  id: string;
  tenantId: string;

  label: string;
  order: number;
  onnEventId: string;
  hasInputTemplate: boolean;
  inputTemplate: string;
  accessControlType: OnnEventEvaluationFieldAccessControlType;

  createdAt: Date;
  updatedAt: Date;

  constructor(init: Omit<ExcludeMethods<OnnEventEvaluationTextField>, "type">) {
    const parsedInit = OnnEventEvaluationTextField.validator.parse(init);

    this.type = OnnEventEvaluationTextField.type;

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.label = parsedInit.label;
    this.order = parsedInit.order;
    this.onnEventId = parsedInit.onnEventId;
    this.hasInputTemplate = parsedInit.hasInputTemplate;
    this.inputTemplate = parsedInit.inputTemplate;
    this.accessControlType = parsedInit.accessControlType;

    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  /**
   * フィールドデータを新規作成する
   *
   * - IDは既に生成済みの場合を想定している
   */
  static createNewWithGeneratedId(
    params: Omit<ExcludeMethods<OnnEventEvaluationTextField>, "createdAt" | "updatedAt">
  ): OnnEventEvaluationTextField {
    return new OnnEventEvaluationTextField({
      ...params,
      id: params.id,
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  update(
    params: Partial<
      Omit<
        OnnEventEvaluationTextField,
        "id" | "tenantId" | "onnEventId" | "createdAt" | "updatedAt"
      >
    >
  ): OnnEventEvaluationTextField {
    return new OnnEventEvaluationTextField({
      ...this,
      ...params,
      updatedAt: new Date(),
    });
  }
}
