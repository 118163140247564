import { Box } from "@material-ui/core";
import { NewGraduate } from "@onn/common";
import React from "react";
import styled from "styled-components";

import { Condition } from "../type";

import { Button } from "~/components/uiParts";

export const ModalFooter = ({
  selectedCondition,
  onAddConditions,
  onCancel,
  isLoading,
}: {
  selectedCondition: Condition;
  onAddConditions: () => Promise<void>;
  onCancel: () => void;
  isLoading: boolean;
}) => {
  const isValidRecruitmentStatus =
    NewGraduate.isValidActiveScenarioNumber(selectedCondition.scenarios.length) &&
    selectedCondition.scenarios.every((v) => !!v.recruitmentStatusId);
  const isValidAuthenticationFlowTypes = 0 < selectedCondition.authenticationFlowTypes.length;
  const isValid = isValidRecruitmentStatus && isValidAuthenticationFlowTypes;

  return (
    <StyledButtonContainer>
      <Button
        variant="text"
        onClick={onCancel}
        color="default"
        borderRadius="circle"
        disabled={isLoading}
      >
        キャンセル
      </Button>
      <Button
        variant="contained"
        color="primary"
        borderRadius="circle"
        onClick={onAddConditions}
        disabled={isLoading || !isValid}
        isLoading={isLoading}
      >
        条件を付与
      </Button>
    </StyledButtonContainer>
  );
};

const StyledButtonContainer = styled(Box)`
  display: flex;
  gap: 8px;
  justify-content: right;
`;
