import { Box, Stack, styled } from "@mui/material";
import React, { FC } from "react";

import { Typography } from "~/components/uiParts";

export const CurrentValueView: FC<{
  title: string;
  value: string;
}> = ({ title, value }) => {
  return (
    <Stack width="100%" rowGap="8px">
      <Typography variant="body2" color="textSecondary" bold>
        {title}
      </Typography>
      <StyledViewBox>
        <Typography variant="body2" color="textPrimary">
          {value}
        </Typography>
      </StyledViewBox>
    </Stack>
  );
};

const StyledViewBox = styled(Box)`
  background: #f7f7f7;
  height: 40px;
  padding: 8px 16px;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid #cccccc;
`;
