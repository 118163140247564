import { z } from "zod";

import { OnnFormTaskQuestionTypeSchema } from "../inputTypeSchemas/OnnFormTaskQuestionTypeSchema";

/////////////////////////////////////////
// ONN FORM TASK QUESTION ANSWER SCHEMA
/////////////////////////////////////////

export const OnnFormTaskQuestionAnswerSchema = z.object({
  questionType: OnnFormTaskQuestionTypeSchema,
  id: z.string(),
  onnFormTaskAnswerId: z.string(),
  questionId: z.string(),
  selectedOptionIds: z.string().array(),
  freeText: z.string().nullish(),
  textValue: z.string().nullish(),
  selectedOptionId: z.string().nullish(),
  filePath: z.string().nullish(),
});

export type OnnFormTaskQuestionAnswer = z.infer<typeof OnnFormTaskQuestionAnswerSchema>;

/////////////////////////////////////////
// ONN FORM TASK QUESTION ANSWER OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const OnnFormTaskQuestionAnswerOptionalDefaultsSchema =
  OnnFormTaskQuestionAnswerSchema.merge(z.object({}));

export type OnnFormTaskQuestionAnswerOptionalDefaults = z.infer<
  typeof OnnFormTaskQuestionAnswerOptionalDefaultsSchema
>;

export default OnnFormTaskQuestionAnswerSchema;
