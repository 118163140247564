import { APISchema, RegistrationInvitationLink } from "@onn/common";
import useSWR, { SWRResponse } from "swr";

import { apiClient } from "~/libs";

const generateKeyObj = (id: string) => {
  return {
    name: "get_registration_invitation_link",
    id,
  };
};

export const useRegistrationInvitationLink = (
  id?: string
): SWRResponse<
  {
    registrationInvitationLink: RegistrationInvitationLink;
    tenantInfo: { tenantName: string; tenantLogoUrl: string };
  },
  Error
> => {
  return useSWR(id ? generateKeyObj(id) : null, async (key: { id: string }) => {
    const queryParam: APISchema["/api/registration-invitation-link"]["GET"]["query"] = {
      id: key.id,
    };
    const response = await apiClient.get("/api/registration-invitation-link", queryParam);

    return {
      registrationInvitationLink: new RegistrationInvitationLink(
        response.data.registrationInvitationLink
      ),
      tenantInfo: response.data.tenantInfo,
    };
  });
};
