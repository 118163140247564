import { useSWRConfig } from "swr";
import useSWRMutation from "swr/mutation";

import { useSnackbar } from "../shared";

import { useMutateAllNewcomers } from "./useAllNewcomers";

import { generateUseNewGraduateKey } from "./useNewGraduate";

import { generateUseEmployeeKeys } from "~/hooks/employee/useEmployee";
import { apiClient } from "~/libs";

export const useDisableScenarioToNewGraduate = ({
  employeeId,
  onDisableScenario,
}: {
  employeeId: string;
  onDisableScenario?: () => void;
}) => {
  const { mutate } = useSWRConfig();
  const { mutateAllNewcomers } = useMutateAllNewcomers();
  const { enqueueSnackbar } = useSnackbar();

  return useSWRMutation(
    { key: "disableScenarioToNewGraduate", employeeId },
    async (
      _key,
      {
        arg,
      }: {
        arg: { scenarioId: string };
      }
    ) => {
      await apiClient.delete("/scenario_api/scenarios/:id/relations", {
        employeeId,
        ...arg,
      });
    },
    {
      onSuccess: () => {
        enqueueSnackbar("シナリオが中断されました", { variant: "success" });

        onDisableScenario?.();
        mutate(generateUseEmployeeKeys(employeeId));
        mutate(generateUseNewGraduateKey({ newGraduateId: employeeId }));
        mutateAllNewcomers();
      },

      onError: (error: Error) => {
        enqueueSnackbar(
          `シナリオの中断に失敗しました${error.message ? `（${error.message}）` : ""}`,
          { variant: "error" }
        );
      },
    }
  );
};
