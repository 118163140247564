import { Paper } from "@material-ui/core";
import { Box, Stack } from "@mui/material";
import { OnnEventEvaluationFileField } from "@onn/common";
import React from "react";

import { AccessControlTypesSelectForm } from "./_shared/AccessControlTypesSelectForm";
import { FieldTypes } from "./_shared/FieldLabelAndTypeInputRow";

import { TextField, Typography } from "~/components/uiParts";

import { SelectFormV2 } from "~/components/uiParts/SelectFormV2";

/**
 * ファイルタイプは、１つのイベントにつき１つのみ固定で存在する
 */
export const FileTypeField: React.FC<{
  onnEventEvaluationFileField: OnnEventEvaluationFileField;
}> = ({ onnEventEvaluationFileField }) => {
  return (
    <Paper
      style={{
        padding: "40px",
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          gridRowGap: "32px",
        }}
      >
        <Stack spacing={"24px"}>
          <Box
            display={"flex"}
            sx={{
              justifyContent: "space-between",
            }}
          >
            <Box mr="16px" width="100%">
              <TextField
                fullWidth
                value={onnEventEvaluationFileField.label}
                onChange={() => 0}
                variant="standard"
                disabled={true}
              />
            </Box>
            <Box width={"160px"}>
              <SelectFormV2<FieldTypes>
                fullWidth
                menuItems={[
                  {
                    value: OnnEventEvaluationFileField.type,
                    name: "ファイル",
                  },
                ]}
                selected={OnnEventEvaluationFileField.type}
                onChange={() => 0}
                disabled={true}
              />
            </Box>
          </Box>
        </Stack>
        <Box
          display={"flex"}
          sx={{
            alignItems: "center",
            columnGap: "16px",
          }}
        >
          <Typography variant="body2" color="textPrimary">
            アクセス権限
          </Typography>
          <Box width={"160px"}>
            <AccessControlTypesSelectForm
              selected={onnEventEvaluationFileField.accessControlType}
              onChange={() => 0}
              disabled={true}
            />
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};
