export { Action } from "./Action";
export * from "./actionConditions";
export * from "./actionSettings";
export * from "./logs";
export { Scenario } from "./Scenario";
export * from "./ScenarioEditLock";
export { Trigger } from "./Trigger";
export * from "./triggerSettings";
export * from "./WaitingTriggerBlock";

export type ScenarioSpecificReason =
  | "CANNOT_DELETE_WAITING_TRIGGER_BLOCK_EXISTS"
  | "TRIGGER_SETTING_ACTION_CONDITION_MISMATCH_ANSWER_ONN_TASK"
  | "TRIGGER_SETTING_ACTION_CONDITION_MISMATCH_ONN_EVENT_EVALUATION"
  | "TRIGGER_SETTING_ACTION_CONDITION_MISMATCH_EMPLOYEE_TAG"
  | "EVALUATE_ONN_EVENT_TRIGGER_SETTING_ACTION_CONDITION_VALUE_MISMATCH_ONN_EVENT_ID"
  | "ANSWER_ONN_TASK_TRIGGER_SETTING_ACTION_CONDITION_VALUE_MISMATCH_ONN_TASK_ID"
  | "CHANGE_RECRUITMENT_STATUS_TRIGGER_SETTING_INVALID_RECRUITMENT_STATUS"
  | "CHANGE_RECRUITMENT_STATUS_TRIGGER_SETTING_NOT_ROOT_TRIGGER";
export type NotFoundReason =
  | "NOT_FOUND_ONN_EVENT"
  | "NOT_FOUND_ONN_TASK"
  | "NOT_FOUND_RECRUITMENT_STATUS"
  | "NOT_FOUND_EMPLOYEE_TAG"
  | "NOT_FOUND_ONN_EVENT_EVALUATION_RANK";
export type DomainSpecificReason = "ONN_EVENT_NOT_DELIVERABLE" | "ONN_TASK_NOT_DELIVERABLE";
export type ScenarioIdMismatchReason = "SCENARIO_ID_MISMATCH" | "NOT_FOUND_RECRUITMENT_STATUS";
export type ValidationReason =
  | ScenarioSpecificReason
  | NotFoundReason
  | DomainSpecificReason
  | ScenarioIdMismatchReason;

type ResultWithReasons<T> =
  | { ok: true }
  | {
      ok: false;
      reasons: T[];
    };
export type ScenarioSpecificValidationResult = ResultWithReasons<ScenarioSpecificReason>;
export type NotFoundValidationResult = ResultWithReasons<NotFoundReason>;
export type DomainSpecificValidationResult = ResultWithReasons<DomainSpecificReason>;
export type ScenarioIdMismatchValidationResult = ResultWithReasons<ScenarioIdMismatchReason>;

// NOTE: いずれかのバリデーション結果が最終的な結果になる点に注意 (直列に実行し、最初に失敗したものが結果になる)
export type ValidationResult =
  | ScenarioSpecificValidationResult
  | NotFoundValidationResult
  | DomainSpecificValidationResult
  | ScenarioIdMismatchValidationResult;

/**
 * バリデーション理由のローカライゼーション
 * NOTE: 動的に生成したくなったら使えなくなるが、現状は固定なので定数として定義
 * NOTE: 顧客からの問い合わせがあった場合に素早く対応するために失敗内容の詳細はログ出しする
 */
export const ValidationReasonLocalizationRecord: Record<ValidationReason, string> = {
  NOT_FOUND_ONN_EVENT: "イベントが見つかりませんでした",
  NOT_FOUND_ONN_TASK: "タスクが見つかりませんでした",
  NOT_FOUND_RECRUITMENT_STATUS: "選考ステータスが見つかりませんでした",
  NOT_FOUND_EMPLOYEE_TAG: "タグが見つかりませんでした",
  NOT_FOUND_ONN_EVENT_EVALUATION_RANK: "イベント評価ランクが見つかりませんでした",
  CANNOT_DELETE_WAITING_TRIGGER_BLOCK_EXISTS:
    "待機中のトリガーブロックが存在するため削除できません",
  ONN_EVENT_NOT_DELIVERABLE: "イベントが配信可能な状態ではありません",
  ONN_TASK_NOT_DELIVERABLE: "タスクが配信可能な状態ではありません",
  TRIGGER_SETTING_ACTION_CONDITION_MISMATCH_ANSWER_ONN_TASK:
    "トリガー設定とタスク回答アクション条件のタイプが一致しません",
  TRIGGER_SETTING_ACTION_CONDITION_MISMATCH_ONN_EVENT_EVALUATION:
    "トリガー設定とイベント評価アクション条件のタイプが一致しません",
  TRIGGER_SETTING_ACTION_CONDITION_MISMATCH_EMPLOYEE_TAG:
    "トリガー設定とタグアクション条件のタイプが一致しません",
  EVALUATE_ONN_EVENT_TRIGGER_SETTING_ACTION_CONDITION_VALUE_MISMATCH_ONN_EVENT_ID:
    "イベント評価トリガー設定とアクション条件のイベントが一致しません",
  ANSWER_ONN_TASK_TRIGGER_SETTING_ACTION_CONDITION_VALUE_MISMATCH_ONN_TASK_ID:
    "タスク回答トリガー設定とアクション条件のタスクが一致しません",
  CHANGE_RECRUITMENT_STATUS_TRIGGER_SETTING_NOT_ROOT_TRIGGER:
    "選考ステータス変更トリガーは１つ目のトリガーである必要があります",
  CHANGE_RECRUITMENT_STATUS_TRIGGER_SETTING_INVALID_RECRUITMENT_STATUS:
    "選考ステータス変更トリガーの選考ステータスが不正です",
  SCENARIO_ID_MISMATCH: "シナリオIDが一致しません",
};
