import liff from "@line/liff";
import { Employee } from "@onn/common";
import { isEmpty } from "lodash";
import React, { FC, ReactNode, createContext, useCallback, useEffect, useState } from "react";

import { useAuthenticationNonGuarded } from "~/hooks/context";
import { useSignOut } from "~/hooks/employee";
import { useSnackbar } from "~/hooks/shared";
import { apiClient } from "~/libs";
import { getTenantIdFromDomain } from "~/libs/getTenantIdFromDomain";
import { captureException } from "~/util";
import { isAdminHostname } from "~/util/isAdminHostname";

const guardAuthorizedUsers = (
  authorizedUsers: unknown
): authorizedUsers is NonEmptyArray<Employee> => {
  return !isEmpty(authorizedUsers);
};

export const AuthorizationContext = createContext<{
  authorizedUsers: Employee[] | null | undefined;
  mutateAuthorizedUsers: () => Promise<void>;
  guardAuthorizedUsers(authorizedUsers: unknown): authorizedUsers is NonEmptyArray<Employee>;
}>({
  authorizedUsers: undefined,
  mutateAuthorizedUsers: async () => void 0,
  guardAuthorizedUsers,
});

export const AuthorizationProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { authUser } = useAuthenticationNonGuarded();
  const [authorizedUsers, setAuthorizedUsers] = useState<Employee[] | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { signOut } = useSignOut();

  const mutateAuthorizedUsers = useCallback(async () => {
    if (!authUser) {
      setAuthorizedUsers(null);
      return;
    }

    // NOTE: 1つの認証で複数の認可ユーザーを扱うため事前に認証ユーザーを再取得する必要がある。
    await authUser.reload();

    await apiClient
      .get(`/api/employee/get-employees-by-uid`, { includeDeleted: true })
      .then(async ({ data: employeesExcludedMethod }) => {
        if (employeesExcludedMethod.length === 0) {
          await signOut();
          return;
        }

        // NOTE: 候補者のみ複数のテナントに所属する可能性があるためログインしようとしているテナントに紐づくemployeeが削除されていないかを確認している
        if (!isAdminHostname()) {
          const tenantId = getTenantIdFromDomain();
          const deletedEmployee = employeesExcludedMethod.find(
            (v) => v.tenantId === tenantId && v.deleted
          );
          // NOTE: 削除済みユーザーがログインしようとした場合はログアウトさせる
          if (deletedEmployee) {
            enqueueSnackbar("ログインに失敗しました。お手数ですが管理者の方にご連絡ください。", {
              variant: "error",
            });
            captureException({
              error: new Error("ログイン失敗"),
              tags: {
                type: "AuthorizationProvider",
              },
              extras: {
                deletedEmployee,
                tenantId,
              },
            });
            await signOut();
            return;
          }
        }

        const employees: Employee[] = employeesExcludedMethod.map((employeeExcludedMethod) => {
          const employee = Employee.plainToInstance(employeeExcludedMethod);
          return employee;
        });

        setAuthorizedUsers(employees);
      })
      .catch(async (e) => {
        enqueueSnackbar("ユーザーの取得に失敗しました", { variant: "error" });
        captureException({
          error: e as Error,
          tags: { type: "AuthorizationProvider" },
          extras: {
            lineProfile: await liff.getProfile().catch(() => "取得できませんでした"),
          },
        });
      });
  }, [authUser, enqueueSnackbar, signOut]);

  useEffect(() => {
    mutateAuthorizedUsers();
  }, [mutateAuthorizedUsers]);

  return (
    <AuthorizationContext.Provider
      value={{
        authorizedUsers,
        mutateAuthorizedUsers,
        guardAuthorizedUsers,
      }}
    >
      {children}
    </AuthorizationContext.Provider>
  );
};
