import { Box } from "@material-ui/core";
import { Stack } from "@mui/material";
import { RecruitmentStatus, Scenario } from "@onn/common";
import React from "react";
import styled from "styled-components";

import { Checkbox, FormControlLabel, Icon, Tooltip, Typography } from "~/components/uiParts";
import { SelectFormV2 } from "~/components/uiParts/SelectFormV2";

export const SelectScenarioAndStatusForm = ({
  value,
  toggleScenarioSelection,
  onChangeStatus,
  disabled,
  scenario,
  recruitmentStatuses,
}: {
  value:
    | {
        scenarioId: string;
        recruitmentStatusId?: string;
      }
    | undefined;
  toggleScenarioSelection: ({ scenarioId }: { scenarioId: string }) => void;
  onChangeStatus: ({
    scenarioId,
    recruitmentStatusId,
  }: {
    scenarioId: string;
    recruitmentStatusId: string;
  }) => void;

  disabled: boolean;
  scenario: Scenario;
  recruitmentStatuses: RecruitmentStatus[];
}) => {
  const menuItems = recruitmentStatuses.map((recruitmentStatus) => ({
    name: recruitmentStatus.label,
    value: recruitmentStatus.id,
  }));
  return (
    <Stack gap={1}>
      <StyledFormControlLabel
        control={
          <Checkbox
            onChange={() => toggleScenarioSelection({ scenarioId: scenario.id })}
            checked={!!value}
          />
        }
        label={
          <Box width="100%" display="flex" alignItems={"center"}>
            <StyledTypography variant="body2" noWrap>
              {scenario.name}
            </StyledTypography>
            {disabled && (
              <Box ml="12px" display="flex" alignItems={"center"}>
                <Tooltip
                  title="すでに紐づいているシナリオを解除することはできません"
                  placement="top"
                >
                  <Icon icon="info" color="grey" size="sm" />
                </Tooltip>
              </Box>
            )}
          </Box>
        }
        disabled={disabled}
      />
      {!!value && (
        <Box pl="32px">
          <StyledSelectFormV2
            labelWhenNoSelected="選択してください"
            onChange={(e) =>
              onChangeStatus({
                scenarioId: scenario.id,
                recruitmentStatusId: e.target.value as string,
              })
            }
            menuItems={menuItems}
            selected={value?.recruitmentStatusId || undefined}
            disabled={disabled}
            errorText={value?.recruitmentStatusId ? "" : "選考ステータスを選択してください"}
          />
        </Box>
      )}
    </Stack>
  );
};

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    width: 100%;
    margin-left: 0px;
    gap: 8px;
    height: 24px;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
  .MuiCheckbox-root {
    padding: 0px 0px;
    margin: 11.5px 0px;
    color: #000000;
  }
`;

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    display: block;
    padding: 0;
  }
`;

const StyledSelectFormV2 = styled(SelectFormV2)`
  width: 240px;
`;
