import { OnnEventEvaluationFileField } from "../OnnEventEvaluationFileField";
import { OnnEventEvaluationMultipleSelectField } from "../OnnEventEvaluationMultipleSelectField";
import { OnnEventEvaluationSingleSelectField } from "../OnnEventEvaluationSingleSelectField";
import { OnnEventEvaluationTextField } from "../OnnEventEvaluationTextField";

export const onnEventEvaluationFieldTypes = [
  OnnEventEvaluationTextField.type,
  OnnEventEvaluationFileField.type,
  OnnEventEvaluationSingleSelectField.type,
  OnnEventEvaluationMultipleSelectField.type,
] as const;
export type OnnEventEvaluationFieldType = (typeof onnEventEvaluationFieldTypes)[number];

export * from "./onnEventEvaluationFormInputSchema";
