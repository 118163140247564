import { z } from "zod";

import { OnnEventEvaluationFieldAccessControlTypeSchema } from "../inputTypeSchemas/OnnEventEvaluationFieldAccessControlTypeSchema";

/////////////////////////////////////////
// ONN EVENT EVALUATION MULTIPLE SELECT FIELD SCHEMA
/////////////////////////////////////////

export const OnnEventEvaluationMultipleSelectFieldSchema = z.object({
  accessControlType: OnnEventEvaluationFieldAccessControlTypeSchema,
  id: z.string(),
  tenantId: z.string(),
  label: z.string(),
  order: z.number(),
  onnEventId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type OnnEventEvaluationMultipleSelectField = z.infer<
  typeof OnnEventEvaluationMultipleSelectFieldSchema
>;

/////////////////////////////////////////
// ONN EVENT EVALUATION MULTIPLE SELECT FIELD OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const OnnEventEvaluationMultipleSelectFieldOptionalDefaultsSchema =
  OnnEventEvaluationMultipleSelectFieldSchema.merge(
    z.object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
  );

export type OnnEventEvaluationMultipleSelectFieldOptionalDefaults = z.infer<
  typeof OnnEventEvaluationMultipleSelectFieldOptionalDefaultsSchema
>;

export default OnnEventEvaluationMultipleSelectFieldSchema;
