import { Employee } from "@onn/common";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useQuery } from "../../shared";

export const useRedirectAfterRegistration = () => {
  const navigate = useNavigate();
  const { query } = useQuery();

  const redirectAfterRegistration = useCallback(
    async (user: Employee) => {
      // LIFF でアカウント登録をしたが、公式アカウントを友達追加していない場合はQRページに飛ばす
      if (
        user.isNewGraduate &&
        user.selectedAuthenticationFlowType === "line" &&
        !user.isFollowedLineOfficialAccount
      ) {
        navigate("/portal/line_qr", { state: { isFirstLogin: true } });
        return;
      }

      // dest-path があれば、そこへ遷移する
      const destPath = query.get("dest-path");
      if (destPath) {
        navigate(destPath, { state: { isFirstLogin: false } }); // destPath がある場合は、isFirstLogin は false にする
        return;
      }

      if (user.isNewGraduate) {
        navigate("/portal", { state: { isFirstLogin: true } });
      } else if (user.isOnlyInterviewer()) {
        // アカウント設定画面に遷移
        navigate("/settings/account");
      } else {
        navigate("/");
      }
    },
    [navigate, query]
  );

  return { redirectAfterRegistration };
};
