import { instantiateFromAnyOnnEventEvaluationFieldExcludeMethods } from "@onn/common";
import useSWR from "swr";

import { useCurrentUser } from "../employee";

import { apiClient } from "~/libs";

const generateKey = (arg: { tenantId: string; onnEventId: string }) => {
  return {
    endpoint: "/api/onn-event-evaluations/settings",
    ...arg,
  } as const;
};
export const useOnnEventEvaluationSetting = ({ onnEventId }: { onnEventId: string }) => {
  const { currentUser } = useCurrentUser();

  return useSWR(
    generateKey({
      tenantId: currentUser.tenantId,
      onnEventId,
    }),
    async ({ endpoint }) => {
      const response = await apiClient.get(endpoint, { onnEventId });
      return {
        onnEventEvaluationFields: response.data.onnEventEvaluationFields.map((d) =>
          instantiateFromAnyOnnEventEvaluationFieldExcludeMethods(d)
        ),
      };
    }
  );
};
