import {
  IOnnEventEvaluationMultipleSelectOption,
  onnEventEvaluationMultipleSelectOptionSchema,
} from "./schema";

export class OnnEventEvaluationMultipleSelectOption
  implements IOnnEventEvaluationMultipleSelectOption
{
  static validator = onnEventEvaluationMultipleSelectOptionSchema;

  id: string;
  tenantId: string;
  onnEventEvaluationMultipleSelectFieldId: string;
  label: string;
  order: number;

  createdAt: Date;
  updatedAt: Date;

  constructor(init: ExcludeMethods<OnnEventEvaluationMultipleSelectOption>) {
    const parsedInit = OnnEventEvaluationMultipleSelectOption.validator.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.onnEventEvaluationMultipleSelectFieldId =
      parsedInit.onnEventEvaluationMultipleSelectFieldId;
    this.label = parsedInit.label;
    this.order = parsedInit.order;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  static createNewWithGeneratedId(
    params: Omit<ExcludeMethods<OnnEventEvaluationMultipleSelectOption>, "createdAt" | "updatedAt">
  ): OnnEventEvaluationMultipleSelectOption {
    return new OnnEventEvaluationMultipleSelectOption({
      ...params,
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  update(
    params: Pick<OnnEventEvaluationMultipleSelectOption, "label" | "order">
  ): OnnEventEvaluationMultipleSelectOption {
    return new OnnEventEvaluationMultipleSelectOption({
      ...this,
      ...params,
      updatedAt: new Date(),
    });
  }
}
