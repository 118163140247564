import { z } from "zod";

import { OnnEventEvaluationMultipleSelectFieldSchema } from "../../../../../_gen/zodSchema/index";

import { onnEventEvaluationMultipleSelectOptionSchema } from "./OnnEventEvaluationMultipleSelectOption";

export const onnEventEvaluationMultipleSelectFieldSchema =
  OnnEventEvaluationMultipleSelectFieldSchema.merge(
    z.object({ options: z.array(onnEventEvaluationMultipleSelectOptionSchema) })
  );

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOnnEventEvaluationMultipleSelectField
  extends z.infer<typeof onnEventEvaluationMultipleSelectFieldSchema> {}
