import { Box } from "@material-ui/core";
import {
  ChangeRecruitmentStatusActionSetting,
  DEFAULT_MAX_FILE_SIZE_MB,
  DEFAULT_UPLOAD_ACCEPTED_FILE_TYPES,
  OnnEventEvaluation,
  OnnEventEvaluationRank,
  generateOnnEventEvaluationFilePath,
  getFileLikeObjectsFromFilePath,
} from "@onn/common";
import React, { ChangeEvent, FC, useCallback, useContext, useState } from "react";
import styled from "styled-components";
import { mutate } from "swr";
import { v4 } from "uuid";

import { useAttachedFilesForm } from "./useAttachedFilesForm";
import { useIsSubmitButtonDisabled } from "./useIsSubmitButtonDisabled";
import { useOnnEventEvaluationRanksForm } from "./useOnnEventEvaluationRanksForm";

import { ScenarioContext } from "~/components/providers/ScenarioProvider";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Loading,
  Modal,
  SelectForm,
  TextareaAutosizeAttachableFile,
  Typography,
} from "~/components/uiParts";
import { Tag } from "~/components/uiParts/Tag";
import { mutateNewGraduateNextPlan } from "~/hooks/employee/useNewGraduateNextPlan";
import { useUpdateOnnEventEvaluation } from "~/hooks/onnEvent";
import { useCreateOnnEventEvaluation } from "~/hooks/onnEvent/useCreateOnnEventEvaluation";
import { generateUseRecruitmentProcessRecordsByEmployeeKey } from "~/hooks/recruitmentProcess/useRecruitmentProcessRecordsByEmployee";
import { useSnackbar } from "~/hooks/shared";
import { useTenant } from "~/hooks/tenant";
import { FileAPIAdapter } from "~/infrastructure/usecases/file/fileAPIAdapter";
import { captureException } from "~/util";

const fileAPIAdapter = new FileAPIAdapter({ bucketType: "private" });

type Props = {
  open: boolean;
  onCancel: () => void;
  onnEventId: string;
  newGraduateId: string;
  newGraduateName: string;
  alreadyRegisteredOnnEventEvaluation?: OnnEventEvaluation;
  onSave?: () => void;
};

export const EditOnnEventEvaluationModal = ({
  open,
  onCancel,
  onnEventId,
  newGraduateId,
  newGraduateName,
  alreadyRegisteredOnnEventEvaluation,
  onSave,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { tenant } = useTenant();
  const pageType: "new" | "edit" = alreadyRegisteredOnnEventEvaluation ? "edit" : "new";
  const title = pageType === "edit" ? "評価編集" : "評価入力";
  const alreadyRegisteredOnnEventEvaluationText = alreadyRegisteredOnnEventEvaluation?.text ?? "";
  const [evaluationText, setEvaluationText] = useState<string>(
    alreadyRegisteredOnnEventEvaluationText
  );
  const onChangeEvaluationText = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setEvaluationText(e.target.value);
  }, []);

  const [isSending, setIsSending] = useState<boolean>(false);

  const { createOnnEventEvaluation } = useCreateOnnEventEvaluation();
  const { updateOnnEventEvaluation } = useUpdateOnnEventEvaluation();
  const { onChangeFiles, attachedFiles } = useAttachedFilesForm(
    alreadyRegisteredOnnEventEvaluation
  );

  const {
    onnEventEvaluationRanks,
    selectedOnnEventEvaluationRank,
    onClickEvaluationRank,
    isOnnEventEvaluationRanksLoading,
    executableChangeRecruitmentStatusActionSettings,
    isExecutableChangeRecruitmentStatusActionSettingsValidating,
  } = useOnnEventEvaluationRanksForm(
    newGraduateId,
    onnEventId,
    alreadyRegisteredOnnEventEvaluation
  );

  const { isSubmitButtonDisabled } = useIsSubmitButtonDisabled({
    pageType,
    evaluationText,
    selectedOnnEventEvaluationRank,
    attachedFiles,
    alreadyRegisteredOnnEventEvaluation,
    alreadyRegisteredOnnEventEvaluationText,
  });

  const onClickSave = useCallback(async () => {
    try {
      setIsSending(true);

      // ファイル名->ファイルパスのマップ
      const attachedFileNameToFilePathMap = new Map(
        attachedFiles.map((file) => {
          // NOTE: File型の場合は、新規アップロードのためファイルパスを生成
          if (file instanceof File) {
            return [
              file.name,
              generateOnnEventEvaluationFilePath({
                tenantId: tenant.tenantId,
                fileName: file.name,
                pathUid: v4(),
              }),
            ];
          }
          // NOTE: Pick<File, "name">型の場合は、すでにアップロード済みのためcurrentOnnEventEvaluationからパスを取得
          const alreadyExistFilePath =
            alreadyRegisteredOnnEventEvaluation?.filePaths &&
            alreadyRegisteredOnnEventEvaluation?.filePaths.find(
              (filePath) => getFileLikeObjectsFromFilePath(filePath) === file.name
            );
          if (!alreadyExistFilePath) {
            captureException({
              error: new Error("評価ファイルのパスが取得できませんでした"),
              tags: { type: "EditOnnEventEvaluationModal" },
              extras: { file },
            });
          }
          return [file.name, alreadyExistFilePath];
        })
      );

      const fileObjectsToUpdate = attachedFiles.flatMap((file) => {
        // NOTE:  Pick<File, "name">型の場合は、すでにアップロード済みであり、画面上に表示するためのダミーファイルオブジェクトのため、アップロード処理は不要
        if (!(file instanceof File)) return [];
        const filePath = attachedFileNameToFilePathMap.get(file.name);
        if (!filePath) return [];
        return {
          filePath,
          file,
        };
      });

      if (fileObjectsToUpdate.length > 0) {
        await fileAPIAdapter.uploadFiles(
          fileObjectsToUpdate.flatMap(({ filePath, file }) => {
            if (file instanceof File) {
              return {
                path: filePath,
                file,
              };
            }
            return [];
          })
        );
      }
      const filePaths = attachedFiles.flatMap(
        (file) => attachedFileNameToFilePathMap.get(file.name) ?? []
      );
      const selectedOnnEventEvaluationRankId = selectedOnnEventEvaluationRank?.id;
      if (alreadyRegisteredOnnEventEvaluation) {
        await updateOnnEventEvaluation({
          onnEventEvaluationId: alreadyRegisteredOnnEventEvaluation.id,
          text: evaluationText,
          onnEventEvaluationRankId: selectedOnnEventEvaluationRankId,
          filePaths,
        });
      } else {
        await createOnnEventEvaluation({
          text: evaluationText,
          onnEventId: onnEventId,
          evaluatedEmployeeId: newGraduateId,
          onnEventEvaluationRankId: selectedOnnEventEvaluationRankId,
          filePaths,
        });
      }
      onCancel();
      onSave && onSave();
      mutate(generateUseRecruitmentProcessRecordsByEmployeeKey(newGraduateId));

      // NOTE: 次の予定はイベントの評価状況によって表示内容が変わるため、このタイミングで再取得する
      mutateNewGraduateNextPlan({ newGraduateId });
    } catch (e) {
      captureException({
        error: e as Error,
        tags: { type: "EditOnnEventEvaluationModal" },
        extras: {
          onnEventId,
          newGraduateId,
          evaluationText,
          alreadyRegisteredOnnEventEvaluation,
        },
      });
      enqueueSnackbar("評価の保存に失敗しました。", { variant: "error" });
    } finally {
      setIsSending(false);
    }
  }, [
    alreadyRegisteredOnnEventEvaluation,
    attachedFiles,
    createOnnEventEvaluation,
    enqueueSnackbar,
    evaluationText,
    newGraduateId,
    onCancel,
    onSave,
    onnEventId,
    selectedOnnEventEvaluationRank?.id,
    tenant.tenantId,
    updateOnnEventEvaluation,
  ]);

  return (
    <StyledModal
      open={open}
      title={title}
      footer={
        <Footer
          onClickCancel={onCancel}
          onClickSave={onClickSave}
          isLoading={isSending}
          isDisabled={
            isSubmitButtonDisabled || isExecutableChangeRecruitmentStatusActionSettingsValidating
          }
          executableChangeRecruitmentStatusActionSettings={
            executableChangeRecruitmentStatusActionSettings ?? []
          }
        />
      }
      content={
        <Content
          newGraduateName={newGraduateName}
          evaluationText={evaluationText}
          onChangeEvaluationText={onChangeEvaluationText}
          onChangeFiles={onChangeFiles}
          attachedFiles={attachedFiles}
          onnEventEvaluationRanks={onnEventEvaluationRanks}
          onClickEvaluationRank={onClickEvaluationRank}
          selectedEvaluationRankId={selectedOnnEventEvaluationRank?.id ?? null}
          isLoading={isOnnEventEvaluationRanksLoading}
        />
      }
      onCancel={onCancel}
      fullWidth
      disableBackdropModal
    />
  );
};

const Content: FC<{
  newGraduateName: string;
  evaluationText: string;
  attachedFiles: File[] | Pick<File, "name">[];
  onChangeEvaluationText: React.ChangeEventHandler<HTMLTextAreaElement>;
  onChangeFiles: (newFiles: (File | Pick<File, "name">)[]) => void;
  onnEventEvaluationRanks: OnnEventEvaluationRank[];
  onClickEvaluationRank: (onnEventEvaluationRankId: string) => void;
  selectedEvaluationRankId: string | null;
  isLoading?: boolean;
}> = ({
  newGraduateName,
  evaluationText,
  attachedFiles,
  onChangeEvaluationText,
  onChangeFiles,
  onnEventEvaluationRanks,
  onClickEvaluationRank,
  selectedEvaluationRankId,
  isLoading,
}) => {
  const onChange = useCallback(
    (
      event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>
    ) => {
      onClickEvaluationRank(event.target.value as string);
    },
    [onClickEvaluationRank]
  );

  return (
    <Box textAlign="center">
      <Box>
        <Typography>{`${newGraduateName}さんの評価を記入してください。`}</Typography>
        <Typography>{`この評価内容は候補者に公開されません。`}</Typography>
      </Box>
      <Box width={"212px"} mt="24px">
        <Box display="flex" alignItems="center" gridColumnGap={8} mb="8px">
          <Typography variant="body1" color="textPrimary" bold>
            評価ランク
          </Typography>
          <Tag color="secondary" size="sm" text="必須" />
        </Box>
        {isLoading ? (
          <Loading size="small" />
        ) : (
          <SelectForm
            selected={selectedEvaluationRankId}
            onChange={onChange}
            menuItems={onnEventEvaluationRanks.map((onnEventEvaluationRank) => {
              return {
                value: onnEventEvaluationRank.id,
                name: onnEventEvaluationRank.label,
              };
            })}
            labelWhenNoSelected="選択してください"
            fullWidth
          />
        )}
      </Box>
      <Box width={"100%"} pt="24px">
        <Box display="flex" alignItems="center" gridColumnGap={8} mb="8px">
          <Typography variant="body1" color="textPrimary" bold>
            評価メモ
          </Typography>
        </Box>
        <TextareaAutosizeAttachableFile
          fullWidth
          value={evaluationText}
          placeholder={`評価に関するメモを入力してください`}
          onChange={onChangeEvaluationText}
          minRows={4}
          maxFileSizeMb={DEFAULT_MAX_FILE_SIZE_MB}
          accepts={DEFAULT_UPLOAD_ACCEPTED_FILE_TYPES}
          onChangeFiles={onChangeFiles}
          attachedFiles={attachedFiles}
        />
      </Box>
    </Box>
  );
};

const Footer: FC<{
  onClickCancel: () => void;
  onClickSave: () => void;
  isLoading: boolean;
  isDisabled: boolean;
  executableChangeRecruitmentStatusActionSettings: ExcludeMethods<ChangeRecruitmentStatusActionSetting>[];
}> = (props) => {
  const [isExecuteChangeRecruitmentStatusConfirmed, setIsExecuteChangeRecruitmentStatusConfirmed] =
    useState<boolean>(false);
  const { recruitmentStatuses, scenarios } = useContext(ScenarioContext);

  const shouldDisplayChangeRecruitmentStatusConfirmationCheckbox =
    !!props.executableChangeRecruitmentStatusActionSettings?.length;

  return (
    <Box>
      {shouldDisplayChangeRecruitmentStatusConfirmationCheckbox && (
        <Box width="100%" textAlign="center" mb="24px">
          <FormControlLabel
            control={
              <Checkbox
                checked={isExecuteChangeRecruitmentStatusConfirmed}
                onChange={(event) => {
                  setIsExecuteChangeRecruitmentStatusConfirmed(event.target.checked);
                }}
              />
            }
            label={
              <Typography variant="caption" color="textSecondary">
                この評価で保存した場合、候補者は
                {props.executableChangeRecruitmentStatusActionSettings
                  .flatMap((setting) => {
                    const recruitmentStatus = recruitmentStatuses.find(
                      (status) => status.id === setting.recruitmentStatusId
                    );
                    if (!recruitmentStatus) {
                      return [];
                    }

                    const scenario = scenarios.find(
                      (scenario) => scenario.id === recruitmentStatus.scenarioId
                    );
                    if (!scenario) {
                      return [];
                    }

                    const statusLabel = recruitmentStatus.label;
                    const scenarioName = scenario.name;

                    return `「${scenarioName}／${statusLabel}」`;
                  })
                  .join("と")}
                に変更されます
              </Typography>
            }
          />
        </Box>
      )}
      <StyledButtonContainer>
        <Box width="240px">
          <Button
            fullWidth
            borderRadius="circle"
            variant="outlined"
            color="default"
            onClick={props.onClickCancel}
          >
            キャンセル
          </Button>
        </Box>
        <Box width="240px">
          <Button
            fullWidth
            borderRadius="circle"
            variant="contained"
            color="primary"
            disabled={
              props.isLoading ||
              props.isDisabled ||
              props.executableChangeRecruitmentStatusActionSettings.length
                ? !isExecuteChangeRecruitmentStatusConfirmed
                : false
            }
            isLoading={props.isLoading}
            onClick={props.onClickSave}
          >
            変更を保存
          </Button>
        </Box>
      </StyledButtonContainer>
    </Box>
  );
};

const StyledModal = styled(Modal)`
  .MuiDialog-paper {
    padding-bottom: 40px;
    row-gap: 40px;
  }
  footer {
    margin-top: 40px;
  }
`;

const StyledButtonContainer = styled(Box)`
  display: flex;
  gap: 24px;
  justify-content: center;
  width: 100%;
`;
