import { NewGraduateToDisplay, OnnFormTaskAnswer } from "@onn/common";

import { useCallback } from "react";

import { useGenerateDateRows } from "./useGenerateDateRows";
import { useGenerateHeaderRow } from "./useGenerateHeaderRow";
import { SortedRevisionQuestionDataList } from "./useGenerateSortedRevisionQuestionDataList";

// MultiGrid用のデータを生成する
export const useGenerateRows = () => {
  const { generateHeaderRow } = useGenerateHeaderRow();
  const { generateDataRows } = useGenerateDateRows();

  const generateRows = useCallback(
    ({
      newGraduates,
      onnFormTaskAnswers,
      sortedRevisionQuestionDataList,
    }: {
      newGraduates: NewGraduateToDisplay[];
      onnFormTaskAnswers: OnnFormTaskAnswer[];
      sortedRevisionQuestionDataList: SortedRevisionQuestionDataList;
    }) => {
      const headerRow = generateHeaderRow({
        onnFormTaskAnswers,
        sortedRevisionQuestionDataList,
      });

      const dataRows = generateDataRows({
        newGraduates,
        onnFormTaskAnswers,
        sortedRevisionQuestionDataList,
      });

      return [headerRow, ...dataRows];
    },
    [generateDataRows, generateHeaderRow]
  );

  return { generateRows };
};
