import { APISchema } from "@onn/common";
import { instanceToPlain } from "class-transformer";

import { apiClient } from "~/libs";

type Body = APISchema["/api/onn-task/patch-delivery-settings-of-onn-task"]["PATCH"]["body"];

export const useUpdateDeliverySettings = () => {
  const updateDeliverySettings = async ({
    scheduledDate,
    onnFormTaskId,
    deadlineDate,
    canAnswerAfterDeadline,
    newGraduateIds,
    forceNotifyImmediately,
  }: {
    scheduledDate?: Date | null;
    onnFormTaskId: string;
    deadlineDate?: Date | null;
    canAnswerAfterDeadline: boolean;
    newGraduateIds: string[];
    forceNotifyImmediately?: boolean;
  }) => {
    const body = {
      "scheduled-date": scheduledDate,
      "onn-form-task-id": onnFormTaskId,
      "deadline-date": deadlineDate,
      "can-answer-after-deadline": canAnswerAfterDeadline,
      "new-graduate-ids": newGraduateIds,
      "force-notify-immediately": forceNotifyImmediately,
    };
    await apiClient.patch(
      "/api/onn-task/patch-delivery-settings-of-onn-task",
      instanceToPlain(body) as Body
    );
  };

  return { updateDeliverySettings };
};
