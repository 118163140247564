import { Box } from "@material-ui/core";
import { isEmpty } from "lodash";
import React, { useCallback, useContext, useMemo, useState } from "react";

import { Choice } from "./Choice";

import { RecruitmentStatusFilterMenu } from "~/components/domains/employees/RecruitmentStatusFilterMenu/RecruitmentStatusFilterMenu";
import { ScenarioContext } from "~/components/providers/ScenarioProvider";
import { Button, Icon, Typography } from "~/components/uiParts";

type Props = {
  selectedChoices: Choice<string>[] | undefined;
  choices: Choice<string>[];
  onChange(newChoices: Choice<string>[]): void;
};
export const SelectRecruitmentStatusConditionDropdown = ({
  selectedChoices,
  choices,
  onChange,
}: Props): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { scenariosWithRecruitmentStatuses } = useContext(ScenarioContext);

  const selectedRecruitmentStatusIds = useMemo(() => {
    return selectedChoices?.map((c) => c.value) ?? [];
  }, [selectedChoices]);

  const setSelectedRecruitmentStatusIds = useCallback(
    (ids: string[]) => onChange([...choices.filter((c) => ids.includes(c.value))]),
    [onChange, choices]
  );

  const buttonText = useMemo(() => {
    if (!selectedChoices || isEmpty(selectedChoices)) {
      return "選考ステータスを選択";
    }

    // eslint-disable-next-line no-irregular-whitespace
    // e.g. シナリオ１／選考ステータス１,選考ステータス２　シナリオ２／選考ステータス３
    return scenariosWithRecruitmentStatuses
      .flatMap((scenarioWithRecruitmentStatuses) => {
        const filteredSelectedChoices = selectedChoices.filter((choice) => {
          const isExist = !!scenarioWithRecruitmentStatuses.recruitmentStatuses.find(
            (r) => r.id === choice.value
          );
          return isExist;
        });

        if (isEmpty(filteredSelectedChoices)) {
          return [];
        }

        const scenarioName = scenarioWithRecruitmentStatuses.scenario.name;
        const joinedStatusLabels = filteredSelectedChoices.map((r) => r.label).join(",");

        return `${scenarioName}／${joinedStatusLabels}`;
      })
      .join("　");
  }, [scenariosWithRecruitmentStatuses, selectedChoices]);

  return (
    <Box width={"100%"}>
      <Button
        color="default"
        variant="outlined"
        borderRadius="regular"
        fullWidth
        onClick={(e) => setAnchorEl(e.currentTarget)}
        endIcon={<Icon icon="dropdownArrow" size="sm" color="grey" />}
      >
        <Box width={"100%"} flex={1} display={"flex"}>
          <Typography variant="body2" noWrap>
            {buttonText}
          </Typography>
        </Box>
      </Button>
      <RecruitmentStatusFilterMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        selectedRecruitmentStatusIds={selectedRecruitmentStatusIds}
        setSelectedRecruitmentStatusIds={setSelectedRecruitmentStatusIds}
        includeRejectedAndWithdrew={true}
      />
    </Box>
  );
};
