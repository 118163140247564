import { NewGraduate, NewGraduateToDisplay } from "@onn/common";
import useSWR from "swr";

import { apiClient } from "~/libs";

const generateKey = ({
  newGraduateIds,
  includeDeleted = false,
}: {
  newGraduateIds?: string[];
  includeDeleted?: boolean;
}) => {
  return newGraduateIds
    ? { url: "/api/employee/get-new-graduates" as const, newGraduateIds, includeDeleted }
    : null;
};
/**
 * idをもとに複数newGraduateを返すswr
 */
export const useNewGraduates = ({
  newGraduateIds,
  includeDeleted = false,
}: {
  newGraduateIds?: string[];
  includeDeleted?: boolean;
}) => {
  const keys = generateKey({ newGraduateIds, includeDeleted });

  return useSWR(
    keys,
    async ({ url, newGraduateIds }) =>
      await apiClient
        .post(url, {
          ids: newGraduateIds,
          includeDeleted,
        })
        .then(({ data }) => {
          return data.map(
            (d) => new NewGraduateToDisplay(new NewGraduate(d), d.employeeTagIds, d.scenarios)
          );
        })
  );
};
