import { Box } from "@mui/material";
import { AnyOnnEventEvaluationField } from "@onn/common";
import React, { FC, useCallback, useEffect } from "react";

import { FormProvider } from "react-hook-form";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { OnnEventEvaluationFormForm } from "./components/OnnEventEvaluationFormForm/OnnEventEvaluationFormForm";
import { useForm } from "./hooks/form/useForm";

import { Loading, Typography } from "~/components/uiParts";
import { ActionFooter } from "~/components/uiParts/ActionFooter";
import { useOnnEvent } from "~/hooks/onnEvent";
import { useOnnEventEvaluationSetting } from "~/hooks/onnEventEvaluation/useOnnEventEvaluationSetting";
import { NotFound } from "~/pages/NotFound";
import { captureException } from "~/util";

export const Page = () => {
  const params = useParams();
  const onnEventId = params.id;
  const { data: onnEvent, isLoading: isLoadingOnnEvent } = useOnnEvent(onnEventId);
  const { data: onnEventEvaluationSetting, isLoading: isLoadingEvaluationSetting } =
    useOnnEventEvaluationSetting({
      onnEventId: onnEventId || "",
    });
  const isLoading = isLoadingOnnEvent || isLoadingEvaluationSetting;

  if (isLoading) return <Loading size="large" fullHeight />;
  if (!onnEventId || !onnEvent) {
    return <NotFound />;
  }
  return (
    <PageCore
      onnEventEvaluationFields={onnEventEvaluationSetting?.onnEventEvaluationFields || []}
      onnEventId={onnEventId}
    />
  );
};

const PageCore: FC<{
  onnEventEvaluationFields: AnyOnnEventEvaluationField[];
  onnEventId: string;
}> = ({ onnEventEvaluationFields, onnEventId }) => {
  const { RHForm, isSubmitButtonDisabled, submit } = useForm({
    onnEventEvaluationFields,
  });
  const onnEventEvaluationFileField = useOnnEventEvaluationFileField(onnEventEvaluationFields);

  const { onClickCancel } = useOnClickCancel({ onnEventId });

  const { isSubmitting } = RHForm.formState;
  return (
    <FormProvider {...RHForm}>
      <Box mb={4}>
        <Typography variant="h4" color="textPrimary" bold>
          評価フォーム設定
        </Typography>
      </Box>
      {/* イベント評価改修2ndでランクの編集ができるようになる */}
      <OnnEventEvaluationFormForm onnEventEvaluationFileField={onnEventEvaluationFileField} />
      <ActionFooter
        cancelButtonText={"キャンセル"}
        submitButtonText={"変更を保存"}
        onClickCancel={onClickCancel}
        onClickConfirmSave={submit}
        isSaving={isSubmitting}
        isDisabledSaveButton={isSubmitButtonDisabled}
      />
    </FormProvider>
  );
};

/**
 * アクションフッターの「キャンセル」をクリックした時の処理をまとめたhooks
 */
const useOnClickCancel = ({ onnEventId }: { onnEventId: string }) => {
  const [searchParams] = useSearchParams();
  const fromPage = searchParams.get("from_page");
  const navigation = useNavigate();

  const onClickCancel = useCallback(() => {
    if (fromPage === "detail") {
      navigation(`/events/${onnEventId}`);
    } else {
      navigation(`/events`);
    }
  }, [fromPage, navigation, onnEventId]);

  return { onClickCancel };
};

/**
 * ファイルタイプフィールドを取得するhooks
 *
 *  - ファイルタイプフィールドが存在しない場合にはエラーを通知する
 */
const useOnnEventEvaluationFileField = (onnEventEvaluationFields: AnyOnnEventEvaluationField[]) => {
  const params = useParams();
  const onnEventId = params.id;

  const onnEventEvaluationFileFields = onnEventEvaluationFields.filter(
    (f) => f.type === "FileField"
  );
  const onnEventEvaluationFileField = onnEventEvaluationFileFields[0];

  // NOTE: 1イベントにつき、関連ファイルのFieldは1つだけ存在する想定のため、それ以外の場合はエラーを通知する
  useEffect(() => {
    if (onnEventEvaluationFileFields.length > 1) {
      captureException({
        error: new Error("関連ファイルのFieldが複数存在します"),
        extras: { onnEventEvaluationFields, onnEventId },
        tags: { type: "useOnnEventEvaluationFileField" },
      });
    }
    if (!onnEventEvaluationFileField) {
      captureException({
        error: new Error("関連ファイルのFieldが見つかりません"),
        extras: { onnEventEvaluationFields, onnEventId },
        tags: { type: "useOnnEventEvaluationFileField" },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onnEventEvaluationFields.length, onnEventId]);

  return onnEventEvaluationFileField;
};
