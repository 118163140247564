import { Box, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import React, { ComponentProps, FC, ReactNode } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { Icon, Tooltip, Typography } from "~/components/uiParts";
import { Badge } from "~/components/uiParts/Badge";

type CustomProps = {
  label: string;
  to: string;
  shouldShowName: boolean;
  currentPathname: string;
  isBadge?: boolean;
  targetBlank?: boolean;
  externalLink?: boolean;
  isAccessible?: boolean;
} & Partial<Pick<ComponentProps<typeof Icon>, "icon">>;

const checkActive = (to: string, currentPathname: string) => {
  return to === "/"
    ? to === currentPathname || currentPathname.includes("/employee/")
    : currentPathname.includes(to);
};

export const SidebarListItem: FC<CustomProps> = React.memo(({ isAccessible = false, ...props }) => {
  const active = checkActive(props.to, props.currentPathname);

  const TooltipComponent = React.memo(({ children }: { children: ReactNode }) => {
    if (!props.shouldShowName) {
      return (
        <Tooltip title={props.label} placement="right" arrow>
          <> {children}</>
        </Tooltip>
      );
    }

    if (!isAccessible) {
      return (
        <Tooltip
          title="権限がありません。アクセスするには管理者に連絡してください"
          placement="right"
          arrow
        >
          <>{children}</>
        </Tooltip>
      );
    }

    return <>{children}</>;
  });

  const StyledListItemComponent = React.memo(() => {
    return (
      <StyledBox p={0} $isSelected={active} $isAccessible={isAccessible}>
        <StyledListItem selected={active} button $shouldShowName={props.shouldShowName}>
          {props.icon && (
            <ListItemIcon>
              <Badge color="secondary" variant={props.isBadge ? "dot" : "standard"}>
                <Icon
                  icon={props.icon}
                  size="md"
                  color={isAccessible ? (active ? "primary" : "grey") : "lightGrey"}
                />
              </Badge>
            </ListItemIcon>
          )}
          {props.shouldShowName && (
            <ListItemText
              primary={
                <StyledTypography
                  variant="body2"
                  bold
                  $isActive={active}
                  $isAccessible={isAccessible}
                >
                  {props.label}
                </StyledTypography>
              }
            />
          )}
        </StyledListItem>
      </StyledBox>
    );
  });

  return (
    <TooltipComponent>
      {props.externalLink ? (
        <StyledAnchor
          href={props.to}
          target={props.targetBlank ? "_blank" : ""}
          $isAccessible={isAccessible}
        >
          <StyledListItemComponent />
        </StyledAnchor>
      ) : (
        <StyledLink
          to={props.to}
          target={props.targetBlank ? "_blank" : ""}
          $isAccessible={isAccessible}
        >
          <StyledListItemComponent />
        </StyledLink>
      )}
    </TooltipComponent>
  );
});

const StyledTypography = styled(Typography)<{ $isActive: boolean; $isAccessible: boolean }>`
  &.MuiTypography-root {
    color: ${(props) => {
      if (!props.$isAccessible) {
        return props.theme.palette.grey[200];
      }
      if (!props.$isActive) {
        return props.theme.palette.text.secondary;
      }
      return props.theme.palette.primary.main;
    }}
`;

const StyledBox = styled(Box)<{ $isSelected: boolean; $isAccessible: boolean }>`
  border-left: ${(props) =>
    props.$isSelected && props.$isAccessible && `solid 4px ${props.theme.palette.primary.main}}`};
`;

const StyledAnchor = styled.a<{ $isAccessible: boolean }>`
  text-decoration: none;
  color: inherit;
  pointer-events: ${(props) => (props.$isAccessible ? "auto" : "none")};
`;

const StyledLink = styled(Link)<{ $isAccessible: boolean }>`
  text-decoration: none;
  color: inherit;
  pointer-events: ${(props) => (props.$isAccessible ? "auto" : "none")};
`;

const StyledListItem = styled(ListItem)<{ $shouldShowName: boolean }>`
  &.MuiButtonBase-root.MuiListItem-root {
    margin-left: 32px;
    width: ${(props) => (props.$shouldShowName ? 190 : 40)}px;
    padding: 8px;
    background-color: ${(props) => props.theme.palette.common.white};
    border-radius: 8px;
  }

  &.MuiButtonBase-root.Mui-selected {
    /* 左端のボーダーの幅4px分減らしている */
    margin-left: calc(32px - 4px);
    box-sizing: border-box;
    :hover {
      background-color: ${(props) => props.theme.palette.grey[50]};
    }
  }

  &.MuiButtonBase-root:hover {
    background-color: ${(props) => props.theme.palette.grey[50]};
  }

  .MuiListItemText-root {
    margin: 0px;
  }
`;
