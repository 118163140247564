import { Box, Stack } from "@mui/material";
import { NewGraduateToDisplay, NewGraduateToListView } from "@onn/common";
import React, { ComponentProps, FC, useContext } from "react";

import styled from "styled-components";

import { ChangeEmployeePredictionSelector } from "~/components/domains/employeePrediction/ChangeEmployeePredictionSelector";
import { ChangeRecruitmentStatusSelector } from "~/components/domains/recruitmentStatus/ChangeRecruitmentStatusSelector";
import { ScenarioContext } from "~/components/providers/ScenarioProvider";
import { Typography } from "~/components/uiParts";
import { OnEditEmployeePredictionRelation } from "~/hooks/employeePrediction/useEditEmployeePredictionRelations";

type Props = {
  newGraduate: NewGraduateToDisplay;
  newGraduateToListView?: NewGraduateToListView;
  onUpdateRecruitmentStatus?: ComponentProps<
    typeof ChangeRecruitmentStatusSelector
  >["onUpdateRecruitmentStatus"];
  onEditEmployeePredictionRelation?: OnEditEmployeePredictionRelation;
  selectorWidth?: string;
  disabled?: boolean;
};

export const NewGraduateRecruitmentStatusAndPrediction: FC<Props> = ({
  newGraduate,
  newGraduateToListView,
  disabled,
  onUpdateRecruitmentStatus,
  onEditEmployeePredictionRelation,
  selectorWidth,
}) => {
  const { scenarios } = useContext(ScenarioContext);
  const scenarioMap = new Map((scenarios || []).map((scenario) => [scenario.id, scenario]));

  return (
    <Stack height="100%">
      {newGraduate.scenarios.map((scenario) => {
        return (
          <Root key={scenario.scenarioId} py="16px">
            <ChangeSelectorWrapper
              title={scenarioMap.get(scenario.scenarioId)?.name}
              selectorComponent={
                <ChangeRecruitmentStatusSelector
                  newGraduate={newGraduate}
                  newGraduateToListView={newGraduateToListView}
                  scenarioId={scenario.scenarioId}
                  disabled={disabled}
                  selectedRecruitmentStatusId={scenario.recruitmentStatusId}
                  onUpdateRecruitmentStatus={onUpdateRecruitmentStatus}
                />
              }
              selectorWidth={selectorWidth}
            />
            <ChangeSelectorWrapper
              title="ヨミ"
              selectorComponent={
                <ChangeEmployeePredictionSelector
                  newGraduate={newGraduate}
                  scenarioId={scenario.scenarioId}
                  currentPredictionId={scenario.predictionId}
                  newGraduateToListView={newGraduateToListView}
                  disabled={disabled}
                  onEditEmployeePredictionRelation={onEditEmployeePredictionRelation}
                />
              }
              selectorWidth={selectorWidth}
            />
          </Root>
        );
      })}
    </Stack>
  );
};

const ChangeSelectorWrapper: FC<{
  title?: string;
  selectorComponent: React.ReactElement;
  selectorWidth?: string;
}> = ({ title, selectorComponent, selectorWidth }) => {
  return (
    <Box
      width={selectorWidth || "50%"}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <Typography variant="overline" color="textPrimary">
        {title}
      </Typography>
      {selectorComponent}
    </Box>
  );
};

const Root = styled(Box)`
  display: flex;
  gap: 8px;
  align-items: center;
`;
