import { APISchema, Employee } from "@onn/common";
import useSWR from "swr";

import { apiClient } from "~/libs";

export type RegistrationInfo =
  APISchema["/api/employee/get-registration-info"]["POST"]["response"]["data"];

/**
 * invitationTokenを元に登録に必要なデータを取得する
 * @param invitationToken invitationToken
 * @returns {RegistrationInfo}
 */
export const useRegistrationInfoByInvitationToken = (
  invitationToken?: string,
  liffAccessToken?: string
) => {
  return useSWR(
    invitationToken
      ? (["/api/employee/get-registration-info", invitationToken, liffAccessToken] as const)
      : null,
    async ([endpoint, invitationToken, liffAccessToken]) => {
      const response = await apiClient.post(endpoint, {
        "invitation-token": invitationToken,
        "line-access-token": liffAccessToken,
      });

      return {
        employee: new Employee(response.data.employee),
        tenantName: response.data.tenantName,
        enTenantName: response.data.enTenantName,
        tenantLogoUrl: response.data.tenantLogoUrl,
        emailAuthenticationStatus: response.data.emailAuthenticationStatus,
        lineAuthenticationStatus: response.data.lineAuthenticationStatus,
      };
    }
  );
};
