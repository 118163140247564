import { zodResolver } from "@hookform/resolvers/zod";
import {
  NewGraduateToDisplay,
  RecruitmentStatus,
  RecruitmentStatusRelation,
  Scenario,
} from "@onn/common";
import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

const schema = z.object({
  scenarioId: z.string(),
  recruitmentStatusId: z.string(),
  offerAcceptanceDeadline: z.date().optional(),
});

export const useChangeRecruitmentStatusForm = ({
  newGraduate,
  scenariosWithRecruitmentStatuses,
  offerAcceptanceDeadline,
  currentRecruitmentStatus,
  currentScenario,
  inactiveScenarioRelations,
  updateNewGraduateRecruitmentStatus,
  moveScenario,
}: {
  newGraduate: NewGraduateToDisplay;
  scenariosWithRecruitmentStatuses: {
    scenario: Scenario;
    recruitmentStatuses: RecruitmentStatus[];
  }[];
  offerAcceptanceDeadline?: Date;
  currentRecruitmentStatus: RecruitmentStatus;
  currentScenario: Scenario;
  inactiveScenarioRelations: Array<{
    scenario: Scenario;
    recruitmentStatusRelation: RecruitmentStatusRelation;
  }>;
  updateNewGraduateRecruitmentStatus: (args: {
    scenarioId: string;
    recruitmentStatusId: string;
    offerAcceptanceDeadline?: Date;
  }) => Promise<void>;
  moveScenario: (args: {
    scenarioId: string;
    recruitmentStatusId: string;
    offerAcceptanceDeadline?: Date;
  }) => Promise<void>;
}) => {
  const form = useForm<{
    scenarioId: string;
    recruitmentStatusId: string;
    offerAcceptanceDeadline?: Date;
  }>({
    defaultValues: { offerAcceptanceDeadline, scenarioId: currentScenario.id },
    mode: "onChange",
    resolver: zodResolver(schema),
  });

  const scenarioOptions = useMemo(() => {
    return scenariosWithRecruitmentStatuses
      .filter(({ scenario }) => {
        // 現在のシナリオは選択肢に含める
        if (scenario.id === currentScenario.id) return true;

        return (
          // 候補者に設定しているシナリオは選択肢から除外する
          !newGraduate.scenarios.find(
            (scenarioOfNewGraduate) => scenario.id === scenarioOfNewGraduate.scenarioId
          ) &&
          // 候補者に非アクティブなシナリオは選択肢から除外する
          !inactiveScenarioRelations.find((relation) => relation.scenario.id === scenario.id)
        );
      })
      .map(({ scenario }) => {
        return {
          value: scenario.id,
          name: scenario.name,
        };
      });
  }, [
    currentScenario.id,
    inactiveScenarioRelations,
    newGraduate.scenarios,
    scenariosWithRecruitmentStatuses,
  ]);

  const selectedScenarioId = form.watch("scenarioId");
  const selectedScenario = useMemo(
    () =>
      scenariosWithRecruitmentStatuses.find(({ scenario }) => scenario.id === selectedScenarioId),
    [scenariosWithRecruitmentStatuses, selectedScenarioId]
  );

  const shouldMoveScenario = useMemo(
    () => currentScenario.id === selectedScenarioId,
    [currentScenario.id, selectedScenarioId]
  );

  const recruitmentStatusOptions = useMemo(() => {
    if (!selectedScenario) return [];

    return (
      selectedScenario.recruitmentStatuses
        // 現在の選考ステータスは選択肢から除外する
        .filter((recruitmentStatus) => currentRecruitmentStatus.id !== recruitmentStatus.id)
        .map((recruitmentStatus) => ({
          value: recruitmentStatus.id,
          name: recruitmentStatus.label,
        }))
    );
  }, [currentRecruitmentStatus.id, selectedScenario]);

  const selectedRecruitmentStatusId = form.watch("recruitmentStatusId");
  const isSelectedJobOfferType = useMemo(() => {
    const selectedRecruitmentStatus = selectedScenario?.recruitmentStatuses.find(
      (status) => status.id === selectedRecruitmentStatusId
    );

    if (!selectedRecruitmentStatus) return false;

    return selectedRecruitmentStatus.isJobOffer();
  }, [selectedRecruitmentStatusId, selectedScenario?.recruitmentStatuses]);

  const isSelectedRejectedOrWithdrewType = useMemo(() => {
    const selectedRecruitmentStatus = selectedScenario?.recruitmentStatuses.find(
      (status) => status.id === selectedRecruitmentStatusId
    );

    if (!selectedRecruitmentStatus) return false;

    return selectedRecruitmentStatus.isRejectedOrWithdrew();
  }, [selectedRecruitmentStatusId, selectedScenario?.recruitmentStatuses]);

  const onChangeScenario = useCallback(() => {
    form.resetField("recruitmentStatusId");
    form.resetField("offerAcceptanceDeadline");
  }, [form]);

  const onChangeRecruitmentStatus = useCallback(() => {
    form.resetField("offerAcceptanceDeadline");
  }, [form]);

  return {
    ...form,
    scenarioOptions,
    recruitmentStatusOptions,
    shouldMoveScenario,
    isSelectedJobOfferType,
    isSelectedRejectedOrWithdrewType,
    handleSubmit: form.handleSubmit(
      async ({
        scenarioId,
        recruitmentStatusId,
        offerAcceptanceDeadline: _offerAcceptanceDeadline,
      }) => {
        // 内定承諾期日を変更していないときは、リクエストに含まない
        // リクエストに含めると、内定承諾期日変更のスナックバーが表示されてしまうため
        const offerAcceptanceDeadline = form.getFieldState("offerAcceptanceDeadline").isDirty
          ? _offerAcceptanceDeadline
          : undefined;

        if (currentScenario.id === scenarioId) {
          await updateNewGraduateRecruitmentStatus({
            scenarioId,
            recruitmentStatusId,
            offerAcceptanceDeadline,
          });
        } else {
          await moveScenario({
            scenarioId,
            recruitmentStatusId,
            offerAcceptanceDeadline,
          });
        }
      }
    ),
    onChangeScenario,
    onChangeRecruitmentStatus,
  };
};
