import { NewGraduate } from "./NewGraduate";

export class NewGraduateToDisplay extends NewGraduate {
  employeeTagIds: string[];
  scenarios: (NewGraduate["scenarios"][number] & {
    predictionId: string | null;
  })[];

  constructor(
    newGraduate: NewGraduate,
    employeeTagIds: string[],
    scenarios: NewGraduateToDisplay["scenarios"]
  ) {
    super(newGraduate);
    this.spaceId = newGraduate.spaceId;
    this.employeeTagIds = employeeTagIds;
    this.uniqueId = newGraduate.uniqueId;
    this.scenarios = scenarios;
  }
}
