import { Scenario } from "@onn/common";
import { useCallback } from "react";
import useSWR, { useSWRConfig } from "swr";

import { useCurrentUser } from "../employee/useCurrentUser";

import { apiClient } from "~/libs";

const getKey = ({ tenantId, scenarioId }: { tenantId: string; scenarioId: string | null }) => {
  return scenarioId
    ? ({
        endpoint: "/scenario_api/scenarios/:id",
        tenantId,
        scenarioId,
      } as const)
    : null;
};

export const useScenario = ({ scenarioId }: { scenarioId: string | null }) => {
  const { currentUser } = useCurrentUser();
  return useSWR(
    getKey({ tenantId: currentUser.tenantId, scenarioId }),
    async ({ endpoint, scenarioId }) => {
      const response = await apiClient.get(endpoint, { id: scenarioId });

      return new Scenario(response.data);
    }
  );
};

export const useMutateScenarios = () => {
  const { mutate } = useSWRConfig();
  const { currentUser } = useCurrentUser();

  const mutateScenarios = useCallback(
    async ({ scenarioId }: { scenarioId: string }) => {
      await mutate(getKey({ tenantId: currentUser.tenantId, scenarioId }));
    },
    [currentUser.tenantId, mutate]
  );

  return { mutateScenarios };
};
