import { Box } from "@material-ui/core";
import { Employee, NewGraduateToDisplay } from "@onn/common";
import React, { useState } from "react";
import styled from "styled-components";

import { NewGraduateSelectionCore } from "../NewGraduateSelectionCore";

import { Button, Modal } from "~/components/uiParts";
import { useModalContentHeight } from "~/hooks/modal";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  filter: (employee: NewGraduateToDisplay) => boolean;
  onSubmit: (selectedNewGraduates: Employee[]) => void;
  onCancel: () => void;
  openCsvUploadModal?: () => void;
};

const MODAL_PADDING_TOP = 40;
const MODAL_PADDING_BOTTOM = 24;
const MODAL_MARGIN = 32;
export const AddNewGraduateModal = ({
  open,
  filter,
  onSubmit,
  onCancel,
  openCsvUploadModal,
}: Props): JSX.Element => {
  const [selectedNewGraduates, setSelectedNewGraduates] = useState<NewGraduateToDisplay[]>([]);

  // ユーザーが選択されていない時は追加ボタンを押せないようにする
  const isAddButtonDisabled = selectedNewGraduates.length === 0;

  const { contentHeight } = useModalContentHeight({
    headerHeight: 40 + 24,
    footerHeight: 50 + 24, // hooterの高さ+上方向の余白
    sumOfVerticalPadding: MODAL_PADDING_TOP + MODAL_PADDING_BOTTOM,
    sumOfVerticalMargin: MODAL_MARGIN * 2, // 上下方向分なので2倍している
  });

  return (
    <StyledModal
      fullWidth
      open={open}
      title="配信対象追加"
      onCancel={onCancel}
      content={
        <NewGraduateSelectionCore
          selectedEmployees={selectedNewGraduates}
          setSelectedEmployees={setSelectedNewGraduates}
          filter={filter}
          height={contentHeight}
          includeRejectedAndWithdrew={false}
        />
      }
      footer={
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box height="48px">
            {/* レイアウト調整のため、openCsvUploadModalがない場合も 外枠は表示する */}
            {openCsvUploadModal && (
              <Button
                borderRadius="regular"
                variant="outlined"
                color="primary"
                fullHeight
                onClick={openCsvUploadModal}
              >
                CSVアップロード
              </Button>
            )}
          </Box>
          <StyledButtonContainer display="flex" flexDirection="row-reverse" alignItems="center">
            <Box width="76px">
              <StyledButton
                borderRadius="circle"
                variant="contained"
                color="primary"
                onClick={() => {
                  onSubmit(selectedNewGraduates);
                  onCancel();
                }}
                disabled={isAddButtonDisabled}
              >
                追加
              </StyledButton>
            </Box>
            <Box width="102px">
              <Button borderRadius="regular" variant="text" color="default" onClick={onCancel}>
                キャンセル
              </Button>
            </Box>
          </StyledButtonContainer>
        </Box>
      }
    />
  );
};

const StyledButton = styled(Button)`
  &.MuiButton-root {
    height: 36px;
    padding: 8px 16px;
    font-size: 14px;
    color: white;
  }
`;

const StyledModal = styled(Modal)`
  .MuiDialog-paper {
    height: 100%;
    padding-bottom: ${MODAL_PADDING_BOTTOM}px;
  }
  footer {
    margin-top: 24px;
  }
`;

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
  justify-content: flex-start;
`;
