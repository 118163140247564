import { z } from "zod";

import { BusinessHoursSettingSchema } from "../_gen/zodSchema/index";

export const businessHoursSettingSchema = BusinessHoursSettingSchema.superRefine((value, ctx) => {
  if (value.fromHours === 24) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "開始時刻が不正です",
      path: ["fromHours"],
    });
  }

  if (value.untilHours === 0 && value.untilMinutes === 0) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "終了時刻が不正です",
      path: ["untilHours", "untilMinutes"],
    });
  }

  if (value.untilHours === 24 && value.untilMinutes === 30) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "終了時刻が不正です",
      path: ["untilHours", "untilMinutes"],
    });
  }

  if (value.fromHours > value.untilHours) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "開始時刻は終了時刻より過去になるように設定してください",
      path: ["fromHours", "untilHours"],
    });
  }

  if (value.fromHours === value.untilHours && value.fromMinutes >= value.untilMinutes) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "開始時刻は終了時刻より過去になるように設定してください",
      path: ["fromHours", "untilHours", "fromMinutes", "untilMinutes"],
    });
  }
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBusinessHoursSetting extends z.infer<typeof businessHoursSettingSchema> {}
