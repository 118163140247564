import { answerEventOnBehalfOfNewGraduateRequestSchema } from "@onn/common";
import { useCallback } from "react";

import { z } from "zod";

import { useSnackbar } from "../shared";

import { apiClient } from "~/libs";

export const useAnswerNewInterviewEventOnBehalfOfNewGraduate = () => {
  const { enqueueSnackbar } = useSnackbar();
  const execAnswerNewInterviewEventOnBehalfOfNewGraduate = useCallback(
    async (
      params: z.infer<typeof answerEventOnBehalfOfNewGraduateRequestSchema>["body"]
    ): Promise<void> => {
      await apiClient.post("/api/onn-event-answers/new-interview/on-behalf", params);
      enqueueSnackbar("回答を追加しました", { variant: "success" });
    },
    [enqueueSnackbar]
  );

  return { execAnswerNewInterviewEventOnBehalfOfNewGraduate };
};
