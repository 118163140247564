import { z } from "zod";

import { EmployeeTag } from "../../../../domain/EmployeeTag";
import { OnnEvent, OnnEventEvaluationRank } from "../../../../domain/OnnEvent";
import { OnnTask } from "../../../../domain/OnnTask";

import { RecruitmentStatus } from "../../../../domain/Recruitment/RecruitmentStatus";
import { recruitmentStatusSchema } from "../../../../domain/Recruitment/RecruitmentStatus/schema";
import { RecruitmentStatusRelation } from "../../../../domain/Recruitment/RecruitmentStatusRelation";
import {
  Action,
  AnswerOnnEventTriggerSetting,
  AnswerOnnTaskTriggerSetting,
  AnyActionCondition,
  ChangeRecruitmentStatusActionSetting,
  ChangeRecruitmentStatusTriggerSetting,
  ChangeStatusOfOnnEventTriggerSetting,
  DeliverOnnEventActionSetting,
  DeliverOnnTaskActionSetting,
  EmployeeTagActionCondition,
  EvaluateOnnEventTriggerSetting,
  OnnEventEvaluationActionCondition,
  OnnFormTaskResultActionCondition,
  Scenario,
  SendContactMessageActionSetting,
  Trigger,
} from "../../../../domain/Scenario";

import { actionSchema } from "../../../../domain/Scenario/Action/schema";
import { triggerSchema } from "../../../../domain/Scenario/Trigger/schema";
import { anyActionConditionWithTypeSchema } from "../../../../domain/Scenario/actionConditions/schema";
import { anyActionSettingWithTypeSchema } from "../../../../domain/Scenario/actionSettings/schema";

import { anyTriggerSettingWithTypeSchema } from "../../../../domain/Scenario/triggerSettings/schema";

export interface APISchemaScenario {
  ["/scenario_api/triggers"]: {
    GET: {
      response: {
        data: TriggerWithRelationInfo[];
      };
    };
  };
  ["/scenario_api/executable-change-recruitment-status-action-settings"]: {
    GET: {
      query: z.infer<typeof getExecutableChangeRecruitmentStatusActionSettingsSchema>["query"];
      response: {
        data: {
          changeRecruitmentStatusActionSettings: ExcludeMethods<ChangeRecruitmentStatusActionSetting>[];
        };
      };
    };
  };
  ["/scenario_api/scenarios"]: {
    GET: {
      response: {
        data: ExcludeMethods<Scenario>[];
      };
    };
    POST: {
      body: z.infer<typeof postScenariosSchema>["body"];
    };
    PATCH: {
      body: z.infer<typeof patchScenariosSchema>["body"];
    };
  };
  ["/scenario_api/scenarios-with-info"]: {
    GET: {
      response: {
        data: {
          scenario: ExcludeMethods<Scenario>;
          countOfNewGraduateRelatedScenario: number;
        }[];
      };
    };
  };
  ["/scenario_api/duplicate-scenarios"]: {
    POST: {
      body: z.infer<typeof postDuplicateScenariosSchema>["body"];
    };
  };
  ["/scenario_api/edit-scenarios"]: {
    POST: {
      body: z.infer<typeof postEditScenariosSchema>["body"];
    };
  };
  ["/scenario_api/scenarios/:id"]: {
    GET: {
      query: z.infer<typeof getScenarioSchema>["query"];
      response: {
        data: ExcludeMethods<Scenario>;
      };
    };
  };
  ["/scenario_api/scenarios/recruitment-statuses"]: {
    POST: {
      body: z.infer<typeof postScenarioRecruitmentStatusSchema>["body"];
      response: {
        data: object;
      };
    };
  };
  ["/scenario_api/action-conditions"]: {
    GET: {
      query: z.infer<typeof getActionConditionsSchema>["query"];
      response: {
        data: AnyActionCondition[];
      };
    };
  };
  ["/scenario_api/scenarios/:id/relations"]: {
    GET: {
      query: z.infer<typeof getScenarioRelationsSchema>["query"];
      response: {
        data: Array<{
          recruitmentStatusRelation: ExcludeMethods<RecruitmentStatusRelation>;
          scenario: ExcludeMethods<Scenario>;
        }>;
      };
    };
    POST: {
      body: z.infer<typeof postScenarioRelationsSchema>["body"];
      response: {
        data: void;
      };
    };
    DELETE: {
      query: z.infer<typeof deleteScenarioRelationsSchema>["query"];
      response: {
        data: void;
      };
    };
  };
  ["/scenario_api/scenarios/:id/relations/move"]: {
    POST: {
      body: z.infer<typeof postScenarioRelationsMoveSchema>["body"];
      response: {
        data: {
          scenarioId: string;
          offerAcceptanceDeadline: Date | undefined;
        };
      };
    };
  };
}

export type TriggerWithRelationInfo = ExcludeMethods<Trigger> & {
  actions: (ExcludeMethods<Action> & {
    setting:
      | (ExcludeMethods<ChangeRecruitmentStatusActionSetting> & {
          recruitmentStatusLabel: RecruitmentStatus["label"];
          isDeletedRecruitmentStatus: RecruitmentStatus["deleted"];
        })
      | (ExcludeMethods<SendContactMessageActionSetting> & {
          id: SendContactMessageActionSetting["id"];
        })
      | (ExcludeMethods<DeliverOnnEventActionSetting> & {
          id: DeliverOnnEventActionSetting["id"];
          onnEventId: OnnEvent["id"];
          onnEventTitle: OnnEvent["title"];
        })
      | (ExcludeMethods<DeliverOnnTaskActionSetting> & {
          type: DeliverOnnTaskActionSetting["type"];
          id: DeliverOnnTaskActionSetting["id"];
          onnTaskId: OnnTask["id"];
          onnTaskTitle: OnnTask["title"];
        });

    condition?:
      | (ExcludeMethods<EmployeeTagActionCondition> & {
          tagNames: EmployeeTag["name"][];
        })
      | (ExcludeMethods<OnnEventEvaluationActionCondition> & {
          type: OnnEventEvaluationActionCondition["type"];
          onnEventTitle: OnnEvent["title"];
          rankLabels: OnnEventEvaluationRank["label"][];
        })
      | (ExcludeMethods<OnnFormTaskResultActionCondition> & {
          type: OnnFormTaskResultActionCondition["type"];
          onnTaskTitle: OnnTask["title"];
          questionLabel: string;
          optionTitle: string;
        });
  })[];

  triggerSetting:
    | {
        id: AnswerOnnEventTriggerSetting["id"];
        type: AnswerOnnEventTriggerSetting["type"];
        onnEventId: AnswerOnnEventTriggerSetting["onnEventId"];
        onnEventTitle: OnnEvent["title"];
      }
    | {
        id: AnswerOnnTaskTriggerSetting["id"];
        type: AnswerOnnTaskTriggerSetting["type"];
        onnTaskId: AnswerOnnTaskTriggerSetting["onnTaskId"];
        onnTaskTitle: OnnTask["title"];
      }
    | {
        id: EvaluateOnnEventTriggerSetting["id"];
        type: EvaluateOnnEventTriggerSetting["type"];
        onnEventId: EvaluateOnnEventTriggerSetting["onnEventId"];
        onnEventTitle: OnnEvent["title"];
      }
    | {
        id: ChangeRecruitmentStatusTriggerSetting["id"];
        type: ChangeRecruitmentStatusTriggerSetting["type"];
        recruitmentStatusId: ChangeRecruitmentStatusTriggerSetting["recruitmentStatusId"];
        recruitmentStatusLabel: RecruitmentStatus["label"];
        isDeletedRecruitmentStatus: RecruitmentStatus["deleted"];
      }
    | {
        id: ChangeStatusOfOnnEventTriggerSetting["id"];
        type: ChangeStatusOfOnnEventTriggerSetting["type"];
        onnEventId: ChangeStatusOfOnnEventTriggerSetting["onnEventId"];
        onnEventTitle: OnnEvent["title"];
        changeType: ChangeStatusOfOnnEventTriggerSetting["changeType"];
      };
  waitingTriggerBlockCount: number;
};

export const getScenarioSchema = z.object({
  query: z.object({
    id: z.string(),
  }),
});

export const getExecutableChangeRecruitmentStatusActionSettingsSchema = z.object({
  query: z.object({
    newGraduateId: z.string(),
    onnEventEvaluationRankId: z.string(),
    onnEventId: z.string(),
  }),
});

export const getActionConditionsSchema = z.object({
  query: z.discriminatedUnion("type", [
    z.object({
      type: z.literal("OnnFormTaskResultActionCondition"),
      onnFormTaskId: z.string(),
    }),
  ]),
});

export const postScenariosBlockSchema = z
  .object({
    trigger: triggerSchema,
    triggerSetting: anyTriggerSettingWithTypeSchema,
    actions: z.array(
      z.object({
        action: actionSchema,
        actionSetting: anyActionSettingWithTypeSchema,
        actionCondition: anyActionConditionWithTypeSchema.optional(),
      })
    ),
  })
  .superRefine((val, ctx) => {
    val.actions.forEach((action, index) => {
      switch (action.actionCondition?.type) {
        case "OnnEventEvaluationActionCondition": {
          if (
            OnnEventEvaluationActionCondition.isAvailableTriggerSettingType(val.triggerSetting.type)
          ) {
            if (val.triggerSetting.type === "EvaluateOnnEventTriggerSetting") {
              if (val.triggerSetting.onnEventId !== action.actionCondition.onnEventId) {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  path: [`actions`, index, `actionCondition`],
                  message:
                    "「候補者の評価が入力された時」のトリガーで指定でされているイベントに紐づく、評価ランクを入力してください。",
                });
              }
            }
          } else {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: [`actions`, index, `actionCondition`],
              message:
                "「候補者の評価が入力された時」のトリガーでのみ、このアクション条件は設定できます。",
            });
          }

          break;
        }
        case "OnnFormTaskResultActionCondition": {
          if (
            OnnFormTaskResultActionCondition.isAvailableTriggerSettingType(val.triggerSetting.type)
          ) {
            if (val.triggerSetting.type === "AnswerOnnTaskTriggerSetting") {
              if (val.triggerSetting.onnTaskId !== action.actionCondition.onnFormTaskId) {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  path: [`actions`, index, `actionCondition`],
                  message:
                    "「タスクが「回答済み」になった時」のトリガーに紐づくタスクのアクション条件を設定してください。",
                });
              }
            }
          } else {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: [`actions`, index, `actionCondition`],
              message:
                "「タスクが「回答済み」になった時」のトリガーでのみ、このアクション条件は設定できます。",
            });
          }
          break;
        }
        case "EmployeeTagActionCondition":
        case undefined:
          break;
        default: {
          const _exhaustiveCheck: never = action.actionCondition;
          return _exhaustiveCheck;
        }
      }
    });
  });

export const postDuplicateScenariosSchema = z.object({
  body: z.object({
    scenarioId: z.string(),
  }),
});
export const postScenariosSchema = z.object({
  body: z.object({
    scenarioName: z.string(),
  }),
});
export const patchScenariosSchema = z.object({
  body: z.object({
    scenarioId: z.string(),
    scenarioName: z.string(),
  }),
});
export const postEditScenariosSchema = z.object({
  body: z.object({
    scenarioId: z.string(),
    workflows: z.array(
      z.object({
        recruitmentStatusId: z.string(),
        blocks: z.array(postScenariosBlockSchema),
      })
    ),
  }),
});

export const postScenarioRecruitmentStatusSchema = z.object({
  body: z.object({
    scenarioId: z.string(),
    recruitmentStatuses: z.array(
      z.object({
        id: recruitmentStatusSchema.shape.id,
        label: recruitmentStatusSchema.shape.label,
      })
    ),
  }),
});

export const getScenarioRelationsSchema = z.object({
  query: z.object({
    employeeId: z.string(),
    isActive: z.boolean(),
  }),
});

export const postScenarioRelationsSchema = z.object({
  body: z.object({
    scenarioId: z.string(),
    recruitmentStatusId: z.string(),
    employeeId: z.string(),
    offerAcceptanceDeadline: z.date().optional(),
  }),
});

export const deleteScenarioRelationsSchema = z.object({
  query: z.object({
    scenarioId: z.string(),
    employeeId: z.string(),
  }),
});

export const postScenarioRelationsMoveSchema = z.object({
  body: z.object({
    toScenarioId: z.string(),
    toRecruitmentStatusId: z.string(),
    fromScenarioId: z.string(),
    employeeId: z.string(),
    offerAcceptanceDeadline: z.date().optional(),
  }),
});
