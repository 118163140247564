import {
  LinePlatformRichMenu,
  PortalRichMenuRelation,
  RecruitmentStatus,
  RegistrationRichMenuRelation,
} from "@onn/common";
import React, { createContext, useContext, useState } from "react";

import { useRecruitmentStatusList } from "~/components/providers/ScenarioProvider";
import { Loading } from "~/components/uiParts";
import { useAllRichMenus } from "~/hooks/richMenu/useAllRichMenus";

type RichMenuType = "registrationRichMenu" | "portalRichMenu";

type ContextType = {
  isUploadingRichMenuImage: boolean;
  setIsUploadingRichMenuImage: React.Dispatch<React.SetStateAction<boolean>>;
  currentPortalRichMenusRelations: {
    relation: PortalRichMenuRelation;
    richMenu: LinePlatformRichMenu;
  } | null;
  registrationRichMenu: {
    relation: RegistrationRichMenuRelation;
    richMenu: LinePlatformRichMenu;
  } | null;
  isAlreadySettingRichMenu: boolean;
} & (
  | {
      type: "registrationRichMenu";
      selectedRecruitmentStatus: null;
      scenarioId: string;
    }
  | {
      type: "portalRichMenu";
      selectedRecruitmentStatus: RecruitmentStatus;
    }
);

/**
 * リッチメニュー設定タブのみで使用するコンテキスト
 */
export const RichMenuTabContext = createContext<ContextType | undefined>(undefined);

export const useRichMenuTabContext = () => {
  const c = useContext(RichMenuTabContext);
  if (!c) throw new Error("useContext must be inside a Provider with a value");
  return c;
};

export const RichMenuTabContextProvider: React.FC<{
  scenarioId: string;
  recruitmentStatusId: string | null;
  children: React.ReactNode;
  type: RichMenuType;
}> = ({ children, scenarioId, recruitmentStatusId, type }) => {
  const { recruitmentStatuses } = useRecruitmentStatusList();
  const [isUploadingRichMenuImage, setIsUploadingRichMenuImage] = useState(false);
  const selectedRecruitmentStatus = recruitmentStatusId
    ? recruitmentStatuses.find((status) => status.id === recruitmentStatusId) || null
    : null;
  const { data: allRichMenus, isLoading: isLoadingAllRichMenus } = useAllRichMenus(scenarioId);
  const generateContextValue = () => {
    const currentPortalRichMenusRelations =
      allRichMenus?.portalRichMenusRelationWithRichMenu.find(
        ({ relation }) => relation.recruitmentStatusId === selectedRecruitmentStatus?.id
      ) || null;
    const common = {
      isUploadingRichMenuImage,
      setIsUploadingRichMenuImage,
      registrationRichMenu: allRichMenus?.registrationRichMenu || null,
      currentPortalRichMenusRelations,
    };

    switch (type) {
      case "registrationRichMenu":
        return {
          selectedRecruitmentStatus: null,
          type,
          isAlreadySettingRichMenu: !!common.registrationRichMenu,
          scenarioId,
          ...common,
        };
      case "portalRichMenu": {
        if (!selectedRecruitmentStatus) throw new Error("selectedRecruitmentStatus is null");
        return {
          selectedRecruitmentStatus: selectedRecruitmentStatus,
          type,
          isAlreadySettingRichMenu: !!common.currentPortalRichMenusRelations,
          ...common,
        };
      }
      default: {
        const _exhaustiveCheck: never = type;
        return _exhaustiveCheck;
      }
    }
  };

  return (
    <RichMenuTabContext.Provider value={generateContextValue()}>
      {isLoadingAllRichMenus ? <Loading size="large" /> : children}
    </RichMenuTabContext.Provider>
  );
};
