import { Box, Stack } from "@mui/material";
import { NewGraduateToDisplay } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { ChangeEmployeePredictionSelector } from "~/components/domains/employeePrediction/ChangeEmployeePredictionSelector";

import { ChangeRecruitmentStatusSelector } from "~/components/domains/recruitmentStatus/ChangeRecruitmentStatusSelector";

import { Typography } from "~/components/uiParts";
import { useScenarios } from "~/hooks/scenario/useScenarios";

type Props = {
  newGraduate: NewGraduateToDisplay;
  disabled?: boolean;
};

export const NewGraduateRecruitmentStatuses: FC<Props> = ({ newGraduate, disabled }) => {
  const { data: scenarios } = useScenarios();
  const scenarioMap = new Map((scenarios || []).map((scenario) => [scenario.id, scenario]));

  return (
    <Stack rowGap="16px">
      <Typography variant="body2" bold color="textPrimary">
        シナリオ・選考ステータス
      </Typography>

      <Stack height="100%" rowGap="16px">
        {newGraduate.scenarios.map((scenario) => {
          return (
            <Root key={scenario.scenarioId}>
              <ChangeSelectorWrapper
                title={scenarioMap.get(scenario.scenarioId)?.name}
                selectorComponent={
                  <ChangeRecruitmentStatusSelector
                    newGraduate={newGraduate}
                    disabled={disabled}
                    scenarioId={scenario.scenarioId}
                    selectedRecruitmentStatusId={scenario.recruitmentStatusId}
                  />
                }
              />
              <ChangeSelectorWrapper
                title="ヨミ"
                selectorComponent={
                  <ChangeEmployeePredictionSelector
                    newGraduate={newGraduate}
                    scenarioId={scenario.scenarioId}
                    currentPredictionId={scenario.predictionId}
                    disabled={disabled}
                  />
                }
              />
            </Root>
          );
        })}
      </Stack>
    </Stack>
  );
};

const ChangeSelectorWrapper: FC<{
  title?: string;
  selectorComponent: React.ReactElement;
}> = ({ title, selectorComponent }) => {
  return (
    <Box
      width={"50%"}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <Typography variant="overline" color="textPrimary">
        {title || ""}
      </Typography>
      {selectorComponent}
    </Box>
  );
};

const Root = styled(Box)`
  display: flex;
  gap: 8px;
  align-items: center;
`;
