import { Scenario } from "@onn/common";
import { useCallback } from "react";
import useSWR, { useSWRConfig } from "swr";

import { useCurrentUser } from "../employee/useCurrentUser";

import { useCurrentSpace } from "../space/useCurrentSpace";

import { apiClient } from "~/libs";

const getKey = ({ tenantId, spaceId }: { tenantId: string; spaceId: string }) => {
  return {
    endpoint: "/scenario_api/scenarios-with-info",
    tenantId,
    spaceId,
  } as const;
};

export const useScenariosWithInfo = () => {
  const { currentUser } = useCurrentUser();
  const { currentSpace } = useCurrentSpace();
  return useSWR(
    getKey({ tenantId: currentUser.tenantId, spaceId: currentSpace.id }),
    async ({ endpoint }) => {
      const response = await apiClient.get(endpoint);

      return response.data.map(({ scenario, countOfNewGraduateRelatedScenario }) => ({
        scenario: new Scenario(scenario),
        countOfNewGraduateRelatedScenario,
      }));
    }
  );
};

export const useMutateScenariosWithInfo = () => {
  const { mutate } = useSWRConfig();
  const { currentUser } = useCurrentUser();
  const { currentSpace } = useCurrentSpace();

  const mutateScenariosWithInfo = useCallback(async () => {
    await mutate(getKey({ tenantId: currentUser.tenantId, spaceId: currentSpace.id }));
  }, [currentUser.tenantId, currentSpace.id, mutate]);

  return { mutateScenariosWithInfo };
};
