import { OnnEventEvaluationFieldAccessControlType } from "../../OnnEventEvaluationFieldAccessControlType";

import { IOnnEventEvaluationFileField, onnEventEvaluationFileFieldSchema } from "./schema";

export class OnnEventEvaluationFileField implements IOnnEventEvaluationFileField {
  static validator = onnEventEvaluationFileFieldSchema;

  static readonly type = "FileField" as const;
  type = OnnEventEvaluationFileField.type;

  id: string;
  tenantId: string;
  label: string;
  order: number;
  onnEventId: string;
  accessControlType: OnnEventEvaluationFieldAccessControlType;
  createdAt: Date;
  updatedAt: Date;

  constructor(init: Omit<ExcludeMethods<OnnEventEvaluationFileField>, "type">) {
    const parsedInit = OnnEventEvaluationFileField.validator.parse(init);

    this.type = OnnEventEvaluationFileField.type;

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.label = parsedInit.label;
    this.order = parsedInit.order;
    this.onnEventId = parsedInit.onnEventId;
    this.accessControlType = parsedInit.accessControlType;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  /**
   * フィールドデータを新規作成する
   *
   * - IDは既に生成済みの場合を想定している
   */
  static createNewWithGeneratedId(
    params: Omit<ExcludeMethods<OnnEventEvaluationFileField>, "createdAt" | "updatedAt">
  ): OnnEventEvaluationFileField {
    return new OnnEventEvaluationFileField({
      ...params,
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  update(
    params: Partial<
      Omit<
        OnnEventEvaluationFileField,
        "id" | "tenantId" | "onnEventId" | "createdAt" | "updatedAt"
      >
    >
  ): OnnEventEvaluationFileField {
    return new OnnEventEvaluationFileField({
      ...this,
      ...params,
      updatedAt: new Date(),
    });
  }
}
