import { z } from "zod";

export const DayOfWeekSchema = z.enum([
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
]);

export type DayOfWeekType = `${z.infer<typeof DayOfWeekSchema>}`;

export default DayOfWeekSchema;
