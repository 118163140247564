import { OnnEventEvaluationFieldAccessControlType } from "../../OnnEventEvaluationFieldAccessControlType";

import { OnnEventEvaluationSingleSelectOption } from "./OnnEventEvaluationSingleSelectOption";

import {
  IOnnEventEvaluationSingleSelectField,
  onnEventEvaluationSingleSelectFieldSchema,
} from "./schema";

export class OnnEventEvaluationSingleSelectField implements IOnnEventEvaluationSingleSelectField {
  static validator = onnEventEvaluationSingleSelectFieldSchema;

  static readonly type = "SingleSelectField" as const;
  type = OnnEventEvaluationSingleSelectField.type;

  id: string;
  tenantId: string;
  label: string;
  order: number;
  onnEventId: string;
  accessControlType: OnnEventEvaluationFieldAccessControlType;

  options: OnnEventEvaluationSingleSelectOption[];

  createdAt: Date;
  updatedAt: Date;

  constructor(init: Omit<ExcludeMethods<OnnEventEvaluationSingleSelectField>, "type">) {
    const parsedInit = OnnEventEvaluationSingleSelectField.validator.parse(init);

    this.type = OnnEventEvaluationSingleSelectField.type;

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.label = parsedInit.label;
    this.order = parsedInit.order;
    this.onnEventId = parsedInit.onnEventId;
    this.accessControlType = parsedInit.accessControlType;

    this.options = parsedInit.options.map(
      (options) => new OnnEventEvaluationSingleSelectOption(options)
    );

    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  /**
   * フィールドデータを新規作成する
   *
   * - IDは既に生成済みの場合を想定している
   */
  static createNewWithGeneratedId(
    params: Omit<
      ExcludeMethods<OnnEventEvaluationSingleSelectField>,
      "createdAt" | "updatedAt" | "options"
    > & { options: Array<Pick<OnnEventEvaluationSingleSelectOption, "id" | "label">> }
  ): OnnEventEvaluationSingleSelectField {
    return new OnnEventEvaluationSingleSelectField({
      ...params,
      id: params.id,
      options: params.options.map((option, index) =>
        OnnEventEvaluationSingleSelectOption.createNewWithGeneratedId({
          ...option,
          tenantId: params.tenantId,
          onnEventEvaluationSingleSelectFieldId: params.id,
          order: index + 1, // 1から始まる
        })
      ),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  update(
    params: Partial<
      Omit<
        OnnEventEvaluationSingleSelectField,
        "id" | "tenantId" | "onnEventId" | "createdAt" | "updatedAt" | "options"
      >
    > & {
      options: Array<Pick<OnnEventEvaluationSingleSelectOption, "id" | "label">>;
    }
  ): OnnEventEvaluationSingleSelectField {
    return new OnnEventEvaluationSingleSelectField({
      ...this,
      ...params,
      options: params.options.map((option, index) => {
        const order = index + 1; // 1から始まる
        const sameOption = this.options.find((o) => o.id === option.id);

        return sameOption
          ? sameOption.update({ label: option.label, order })
          : OnnEventEvaluationSingleSelectOption.createNewWithGeneratedId({
              id: option.id,
              onnEventEvaluationSingleSelectFieldId: this.id,
              tenantId: this.tenantId,
              label: option.label,
              order,
            });
      }),
      updatedAt: new Date(),
    });
  }
}
