import { Box, TextField } from "@material-ui/core";
import { Stack } from "@mui/material";
import React, { useCallback } from "react";
import { DropResult } from "react-beautiful-dnd";
import { useController, useFieldArray } from "react-hook-form";
import styled from "styled-components";

import { v4 } from "uuid";

import { InputState } from "../../../../hooks/form/InputState";

import { FieldBase } from "./_shared/FieldBase";

import { FieldBaseProps } from "./_shared/types";

import {
  DnDDraggable,
  DnDDroppable,
  DnDProvider,
  Icon,
  IconButton,
  RadioButton,
  Typography,
} from "~/components/uiParts";

type Props = FieldBaseProps & {
  fieldIndex: number;
};

export const SingleSelectTypeField: React.FC<Props> = ({
  dragHandleProps,
  fieldIndex,
  onRemoveField,
  onDuplicateField,
  isFinalField,
}) => {
  const {
    append: appendOption,
    remove: removeOption,
    swap: swapOption,
    fields: options,
  } = useFieldArray<InputState>({
    name: `evaluationFields.${fieldIndex}.options`,
  });

  const onAddOption = useCallback(() => {
    appendOption({ optionId: v4(), label: "" });
  }, [appendOption]);

  const onRemoveOption = useCallback(
    (optionIndex: number) => {
      removeOption(optionIndex);
    },
    [removeOption]
  );

  const onSwap = useCallback(
    (sourceIndex: number, destinationIndex: number) => {
      swapOption(sourceIndex, destinationIndex);
    },
    [swapOption]
  );

  return (
    <FieldBase
      dragHandleProps={dragHandleProps}
      fieldIndex={fieldIndex}
      onRemoveField={onRemoveField}
      onDuplicateField={onDuplicateField}
      isFinalField={isFinalField}
    >
      <OptionTextFieldsArea
        fieldIndex={fieldIndex}
        onAddOption={onAddOption}
        onRemoveOption={onRemoveOption}
        onSwap={onSwap}
        options={options}
      />
    </FieldBase>
  );
};

const OptionTextFieldsArea: React.FC<{
  onAddOption: () => void;
  onRemoveOption: (index: number) => void;
  onSwap: (sourceIndex: number, destinationIndex: number) => void;
  options: { id: string }[]; // NOTE: useFieldArrayを使って並び替えるために、idを持たせる
  fieldIndex: number;
}> = ({ onAddOption, onRemoveOption, onSwap, options, fieldIndex }) => {
  const onDragEnd = useCallback(
    (result: DropResult) => {
      // drop先がない場合は処理を終了
      if (!result.destination) return;

      // drag開始元とdrop先を取得
      const { index: sourceIndex } = result.source;
      const { index: destinationIndex } = result.destination;

      // drop可能範囲以外でのdropは無効 or 移動元と移動先が同じ場合は処理を終了
      if (destinationIndex === undefined || sourceIndex === destinationIndex) return;

      onSwap(sourceIndex, destinationIndex);
    },
    [onSwap]
  );

  return (
    <Box width="100%">
      <DnDProvider onDragEnd={onDragEnd}>
        <Box width="100%">
          <DnDDroppable
            droppableId={`${fieldIndex}-single-select-fields-options`}
            isDropDisabled={false}
          >
            {options.map((option, optionIndex) => {
              return (
                <DnDDraggable
                  key={option.id}
                  isDragDisabled={false}
                  draggableId={option.id}
                  index={optionIndex}
                >
                  <OptionLabelInputRow
                    fieldIndex={fieldIndex}
                    optionIndex={optionIndex}
                    isShowRemoveButton={options.length > 1}
                    onRemoveOption={onRemoveOption}
                  />
                </DnDDraggable>
              );
            })}
          </DnDDroppable>
        </Box>
      </DnDProvider>
      <Stack
        direction="row"
        alignItems="center"
        pl="32px"
        height="40px"
        spacing={2}
        width="100%"
        onClick={onAddOption}
        sx={{ cursor: "pointer" }}
      >
        <RadioButton onChange={undefined} disabled style={{ padding: 0 }} />
        <StyledTypography variant="body1">選択肢を追加</StyledTypography>
      </Stack>
    </Box>
  );
};

const OptionLabelInputRow: React.FC<{
  fieldIndex: number;
  optionIndex: number;
  isShowRemoveButton?: boolean;
  onRemoveOption: (optionIndex: number) => void;
}> = ({ optionIndex, isShowRemoveButton, fieldIndex, onRemoveOption }) => {
  const { field, fieldState } = useController({
    name: `evaluationFields.${fieldIndex}.options.${optionIndex}.label`,
  });

  return (
    <StyledWrapper direction="row" alignItems="center" spacing={1} width="100%" mb={2}>
      <Box display="flex" justifyContent="center" p="4px">
        <Icon id="gripVerticalWrapper" icon="gripVertical" size="sm" color="lightGrey" />
      </Box>
      <Stack direction="row" alignItems="center" spacing={2} width="100%">
        <RadioButton onChange={undefined} disabled style={{ padding: 0 }} />
        <StyledTextField
          value={field.value}
          onChange={field.onChange}
          placeholder="選択肢を入力"
          fullWidth
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
        />
        <Box>
          {/* NOTE: 最後の１つの選択肢は消せないようにする */}
          {isShowRemoveButton && (
            <IconButton icon="close" onClick={() => onRemoveOption(optionIndex)} />
          )}
        </Box>
      </Stack>
    </StyledWrapper>
  );
};

const StyledTextField = styled(TextField)`
  color: ${(props) => props.theme.palette.text.muted};

  .MuiInput-underline:before {
    border-bottom: 1px solid ${(props) => props.theme.palette.text.muted};
  }
  .MuiInputBase-input {
    height: 40px;
    padding: 0;
  }
`;

const StyledWrapper = styled(Stack)`
  #gripVerticalWrapper > svg {
    display: none;
  }
  &:hover,
  &:active {
    #gripVerticalWrapper > svg {
      display: inline-block;
    }
  }
`;

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    display: block;
    padding: 0;
  }
`;
