import { APISchema } from "@onn/common";
import { instanceToPlain } from "class-transformer";

import { apiClient } from "~/libs";

type Body = APISchema["/api/onn-form-task-answers/id"]["DELETE"]["body"];

export const useDeleteOnnFormTaskAnswer = () => {
  const execDeleteOnnFormTaskAnswer = async ({
    onnFormTaskAnswerId,
    forceNotifyImmediately,
  }: {
    onnFormTaskAnswerId: string;
    forceNotifyImmediately?: boolean;
  }) => {
    const body: Body = {
      onnFormTaskAnswerId,
      forceNotifyImmediately,
    };
    await apiClient.delete(
      "/api/onn-form-task-answers/id",
      undefined,
      instanceToPlain(body) as Body
    );
  };

  return { execDeleteOnnFormTaskAnswer };
};
