import { useCallback, useState } from "react";

export const useSelectNotificationOption = () => {
  const [isForce, setForce] = useState(false);
  const onSelectForceOption = useCallback(() => {
    setForce(true);
  }, []);
  const onSelectNotifyDuringBusinessHoursOption = useCallback(() => {
    setForce(false);
  }, []);

  return {
    isForce,
    onSelectForceOption,
    onSelectNotifyDuringBusinessHoursOption,
  };
};
