import {
  OnnEventEvaluationFileField,
  OnnEventEvaluationMultipleSelectField,
  OnnEventEvaluationSingleSelectField,
  OnnEventEvaluationTextField,
} from "@onn/common";
import React from "react";

import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { useController } from "react-hook-form";

import { InputState } from "../../../hooks/form/InputState";

import { MultipleSelectTypeField } from "./parts/MultipleSelectTypeField";
import { SingleSelectTypeField } from "./parts/SingleSelectTypeField";
import { TextTypeField } from "./parts/TextTypeField";

export const EvaluationField: React.FC<{
  fieldIndex: number;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  onRemoveField: (fieldIndex: number) => void;
  onDuplicateField: (fieldIndex: number) => void;
  isFinalField: boolean;
}> = ({ fieldIndex, dragHandleProps, isFinalField, onRemoveField, onDuplicateField }) => {
  const { field: evaluationField } = useController<InputState, `evaluationFields.${number}`>({
    name: `evaluationFields.${fieldIndex}`,
  });

  const fieldValue = evaluationField.value;

  switch (fieldValue.type) {
    case OnnEventEvaluationTextField.type: {
      return (
        <TextTypeField
          dragHandleProps={dragHandleProps}
          fieldIndex={fieldIndex}
          onRemoveField={onRemoveField}
          onDuplicateField={onDuplicateField}
          isFinalField={isFinalField}
        />
      );
    }
    case OnnEventEvaluationFileField.type: {
      // NOTE: ファイルタイプは編集できない仕様
      return null;
    }
    case OnnEventEvaluationMultipleSelectField.type: {
      return (
        <SingleSelectTypeField
          dragHandleProps={dragHandleProps}
          fieldIndex={fieldIndex}
          onRemoveField={onRemoveField}
          onDuplicateField={onDuplicateField}
          isFinalField={isFinalField}
        />
      );
    }
    case OnnEventEvaluationSingleSelectField.type: {
      return (
        <MultipleSelectTypeField
          dragHandleProps={dragHandleProps}
          fieldIndex={fieldIndex}
          onRemoveField={onRemoveField}
          onDuplicateField={onDuplicateField}
          isFinalField={isFinalField}
        />
      );
    }
    default: {
      const _exhaustiveCheck: never = fieldValue;
      return _exhaustiveCheck;
    }
  }
};
