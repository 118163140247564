import { z } from "zod";

import { INotificationPart } from "../NotificationPart";
import { DelaySystemMessageOrderSchema } from "../_gen/zodSchema/index";

export const delaySystemMessageOrderSchema = DelaySystemMessageOrderSchema.merge(
  z.object({
    lineNotificationPayload: z.object({
      body: z.array(z.record(z.any()).transform((v) => v as INotificationPart)),
      footer: z.array(z.record(z.any()).transform((v) => v as INotificationPart)).optional(),
    }),
    emailNotificationPayload: z.object({
      body: z.array(z.record(z.any()).transform((v) => v as INotificationPart)).optional(),
    }),
  })
);

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IDelaySystemMessageOrder extends z.infer<typeof delaySystemMessageOrderSchema> {}
