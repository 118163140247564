import { z } from "zod";

/////////////////////////////////////////
// ONN EVENT EVALUATION FILE VALUE SCHEMA
/////////////////////////////////////////

export const OnnEventEvaluationFileValueSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  onnEventEvaluationFileFieldId: z.string(),
  filePaths: z.string().array(),
  employeeId: z.string(),
  createdEmployeeId: z.string(),
  updatedEmployeeId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type OnnEventEvaluationFileValue = z.infer<typeof OnnEventEvaluationFileValueSchema>;

/////////////////////////////////////////
// ONN EVENT EVALUATION FILE VALUE OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const OnnEventEvaluationFileValueOptionalDefaultsSchema =
  OnnEventEvaluationFileValueSchema.merge(
    z.object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
  );

export type OnnEventEvaluationFileValueOptionalDefaults = z.infer<
  typeof OnnEventEvaluationFileValueOptionalDefaultsSchema
>;

export default OnnEventEvaluationFileValueSchema;
