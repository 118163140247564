import { Box } from "@material-ui/core";
import { Employee, OnnEvent, OnnEventAnswer } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { AnswerManageMenu } from "./AnswerManageMenu";

import { TooltipWhenTextTruncated, Typography, UserIcon } from "~/components/uiParts";

type Props = {
  onnEvent: OnnEvent;
  onnEventAnswer: OnnEventAnswer;
  newGraduate: Employee;
};
export const UserIconWithLabel: FC<Props> = (props) => {
  const { newGraduate } = props;
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" mr="8px">
      <Box display="flex" alignItems="center" gridGap={"16px"} ml={0}>
        <UserIcon
          username={newGraduate.getName()}
          profileIconImageUrl={newGraduate.profileIconImageUrl}
          size={"small"}
        />
        <Box display="grid">
          <TooltipWhenTextTruncated text={newGraduate.getName()}>
            {(ref) => (
              <StyledTypographyName ref={ref} variant={"body1"} color="textPrimary">
                {newGraduate.getName()}
              </StyledTypographyName>
            )}
          </TooltipWhenTextTruncated>
        </Box>
      </Box>
      {/* TODO: 回答期日が過ぎている場合でも代理回答を可能にする https://app.clickup.com/t/860t3ymck */}
      {!props.onnEvent.isExpired() && props.onnEvent.isExceededScheduledDate() && (
        <AnswerManageMenu {...props} />
      )}
    </Box>
  );
};

const StyledTypographyName = styled(Typography)`
  padding-right: 12px;
  &.MuiTypography-root {
    display: -webkit-box;
    overflow: hidden;
    word-break: break-all;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
`;
