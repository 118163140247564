import { useShouldDisplayConfirmationOutsideBusinessHours } from "~/hooks/businessHours/useShouldDisplayConfirmationOutsideBusinessHours";
import { useModal } from "~/hooks/modal";

type WithBusinessHours = (fnToWrap: (isForce?: boolean) => Promise<void>) => Promise<void>;

export const useWithBusinessHoursConfirmationModal = () => {
  const { handleModal } = useModal();
  const { getShouldDisplayConfirmationOutsideBusinessHours } =
    useShouldDisplayConfirmationOutsideBusinessHours();

  const withBusinessHours: WithBusinessHours =
    // NOTE: args を使った処理はしていないので any で問題ない
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (fnToWrap: (isForce?: boolean) => Promise<void>) => {
      const isShouldConfirmationOutsideBusinessHours =
        await getShouldDisplayConfirmationOutsideBusinessHours();

      if (!isShouldConfirmationOutsideBusinessHours) {
        return fnToWrap();
      }

      handleModal({
        name: "businessHoursConfirmationModal",
        args: {
          onConfirm(isForce) {
            return fnToWrap(isForce);
          },
        },
      });
    };

  return { withBusinessHours };
};
