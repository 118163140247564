import { RecruitmentStatusType } from "../Recruitment/RecruitmentStatus";

import { Employee } from "./Employee";

export class NewGraduate extends Employee {
  public static UPPER_ACTIVE_SCENARIO_MAXIMUM = 3;
  public static UPPER_ACTIVE_SCENARIO_MINIMUM = 1;

  isNewGraduate: true;
  assignedAsNewcomer: true;
  spaceId: string;
  uniqueId: string;

  scenarios: Array<{
    scenarioId: string;
    recruitmentStatusId: string;
    recruitmentStatusType: RecruitmentStatusType;
  }>;

  constructor(args: ExcludeMethods<NewGraduate>) {
    super(args);

    this.isNewGraduate = true;
    this.assignedAsNewcomer = true;
    this.spaceId = args.spaceId;
    this.uniqueId = args.uniqueId;

    this.scenarios = args.scenarios;
  }

  isUpperActiveScenarioLimit(): boolean {
    return NewGraduate.UPPER_ACTIVE_SCENARIO_MAXIMUM <= this.scenarios.length;
  }

  canDisableScenario(): boolean {
    return NewGraduate.UPPER_ACTIVE_SCENARIO_MINIMUM < this.scenarios.length;
  }

  isSomeScenarioRejectedOrWithdrew(): boolean {
    return this.scenarios.some(
      (scenario) =>
        scenario.recruitmentStatusType === "rejected" ||
        scenario.recruitmentStatusType === "withdrew"
    );
  }

  public static isValidActiveScenarioNumber(number: number): boolean {
    return (
      NewGraduate.UPPER_ACTIVE_SCENARIO_MINIMUM <= number &&
      number <= NewGraduate.UPPER_ACTIVE_SCENARIO_MAXIMUM
    );
  }

  /**
   * Onnの通知機能とそれに関連する機能を利用可能かどうかを返す
   * ただしポータルが利用可能かの判定には使わないこと
   */
  canUseNotificationFeatures(): boolean {
    return !this.deleted && !this.isNotRegisteredAndInvited();
  }
}
