import { BriefingSessionEvent } from "@onn/common/domain/OnnEvent/OnnEvent/OnnEvent";
import React, { FC } from "react";

import { TableRowProps } from "react-virtualized";

import { DeterminedSlotsCountToInterviewInTheFutureCell } from "../../../common/components/DeterminedSlotsCountToInterviewInTheFutureCell";
import { PublicSlotsCountCell } from "../../../common/components/PublicSlotsCountCell";

import { TitleCell } from "../../../common/components/TitleCell";

import { ThreeDotMenu } from "./ThreeDotMenuCell";

import { TableRowWrapper } from "~/components/uiParts/VirtualizedTable";
import { TableRow } from "~/components/uiParts/VirtualizedTable/TableRow";
import { VirtualizedTableV2 } from "~/components/uiParts/VirtualizedTable/VirtualizedTableV2";

type Props = {
  events: BriefingSessionEvent[];
  isLoading?: boolean;
};

export const BriefingSessionEventListTable: FC<Props> = ({ events, isLoading }) => {
  return (
    <VirtualizedTableV2
      rowHeight={112}
      isLoading={isLoading}
      widthOptions={widthOptions}
      headers={headers}
      rows={events}
      rowRenderer={(props) => <RowRenderer {...{ ...props }} />}
    />
  );
};

const RowRenderer: React.FC<Omit<TableRowProps, "rowData"> & { rowData: BriefingSessionEvent }> = ({
  index,
  style,
  rowData: onnEvent,
}) => {
  const contents = [
    <TitleCell key={onnEvent.id} title={onnEvent.title} />,
    <PublicSlotsCountCell key={onnEvent.id} onnEventId={onnEvent.id} />,
    <DeterminedSlotsCountToInterviewInTheFutureCell key={onnEvent.id} onnEventId={onnEvent.id} />,
    <ThreeDotMenu key={onnEvent.id} onnEvent={onnEvent} />,
  ];
  return (
    <TableRowWrapper key={onnEvent.id} index={index} {...style}>
      <TableRow
        row={{
          contents,
          to: `/events/${onnEvent.id}`,
          isTargetBlank: false,
        }}
        widthOptions={widthOptions}
        hover={true}
      />
    </TableRowWrapper>
  );
};

const widthOptions = ["76%", "8%", "8%", "8%"];

const headers = [
  {
    text: "タイトル",
  },
  {
    text: "公開予約枠",
  },
  {
    text: "開催予定",
  },
  {
    text: "",
  },
];
