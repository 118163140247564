import { z } from "zod";

import { EmailType, LineType } from "../Employee/AuthenticationType";

export const registrationInvitationLinkSchema = z.object({
  id: z.string().trim(),
  tenantId: z.string().trim(),
  spaceId: z.string().trim(),
  isDefault: z.boolean(),
  recruitmentStatusIds: z.array(z.string().trim()),
  expiredAt: z.date(),
  selectableAuthenticationFlowTypes: z.union([
    z.tuple([z.literal("line" as LineType)]),
    z.tuple([z.literal("line" as LineType), z.literal("email" as EmailType)]),
  ]),
  tagIds: z.array(z.string().trim()).optional(),
  onnEventIds: z.array(z.string().trim()).optional(),
  onnTaskIds: z.array(z.string().trim()).optional(),
  creatorEmployeeId: z.string().trim().optional(), // 管理画面上から作成された場合に設定される
  createdAt: z.date(),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IRegistrationInvitationLinkSchema
  extends z.infer<typeof registrationInvitationLinkSchema> {}
