import liff from "@line/liff";
import { RegistrationInvitationLink } from "@onn/common";
import React, { FC, useCallback, useEffect, useState } from "react";

import { useRegistrationLinks } from "./hooks/useGenerateRegistrationLinks";

import { QRCodePage } from "~/components/domains/account/QRCodePage";
import { SelectionPage } from "~/components/domains/account/SelectionPage";
import { useLiffUrl } from "~/hooks/liff/useLiffUrl";
import { useSnackbar } from "~/hooks/shared";

import { useNavigateWithQuery } from "~/hooks/shared/useNavigateWithQuery";
import { captureException, isMobileBrowser } from "~/util";

export const Registration: FC<{
  liffId: string;
  registrationInvitationLink?: RegistrationInvitationLink;
  canSelectMail: boolean;
}> = ({ liffId, registrationInvitationLink, canSelectMail }) => {
  const [isOpenQRCodePage, setIsOpenQRCodePage] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigateWithQuery();
  const { openLiff, createLiffUrl } = useLiffUrl();
  const { pathForLine, pathForEmail } = useRegistrationLinks();
  const liffUrl = createLiffUrl({ liffId, path: pathForLine });

  const startLineRegistration = useCallback(() => {
    if (liff.isInClient()) {
      navigate(pathForLine);
      return;
    }

    if (isMobileBrowser()) {
      openLiff({ liffId, path: pathForLine });
      setIsOpenQRCodePage(true);
    }
    setIsOpenQRCodePage(true);
    // LIFFを開いた場合に元のブラウザ上のページは残るので、QRコードページは表示する
  }, [liffId, navigate, openLiff, pathForLine]);

  const startEmailRegistration = useCallback(() => {
    if (liff.isInClient()) {
      enqueueSnackbar(
        "エラーが発生したためこの操作を続けることはできません。メールアドレスで登録する場合、採用担当者にお問い合わせください。",
        { variant: "error" }
      );
      captureException({
        error: new Error("アカウント登録ページ群でエラーが発生しました"),
        tags: { type: "Registration" },
        extras: { detail: "liff clientの場合に、Email登録に進むことはできません" },
      });
      return;
    }

    navigate(pathForEmail);
  }, [enqueueSnackbar, navigate, pathForEmail]);

  useEffect(() => {
    if (registrationInvitationLink && !registrationInvitationLink.canAuthenticateByEmail()) {
      // メール認証が選択可能ではない場合、このアカウント登録扉ページはスキップされLINE用の登録ページへ進む
      startLineRegistration();
    }
  }, [registrationInvitationLink, startLineRegistration]);

  return isOpenQRCodePage ? (
    <QRCodePage urlForQRCode={liffUrl} startLineRegistration={startLineRegistration} />
  ) : (
    <SelectionPage
      start={{ startLineRegistration, startEmailRegistration }}
      canSelectMail={canSelectMail}
      canNavigateToLogin
    />
  );
};
