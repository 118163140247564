import { z } from "zod";

import { DelayContactMessageOrderSchema } from "../_gen/zodSchema/index";

export const delayContactMessageOrderSchema = DelayContactMessageOrderSchema.merge(
  z.object({
    stickerId: z.string().optional(),
    creatorEmployeeId: z.string().optional(),
    isOfficialName: z.boolean().optional(),
  })
);
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IDelayContactMessageOrder extends z.infer<typeof delayContactMessageOrderSchema> {}
