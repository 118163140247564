import { RegistrationInvitationLink } from "@onn/common";
import React, { FC, useState } from "react";
import styled from "styled-components";

import { useAddConditionModeModal } from "../_share_InvitationQR/AddConditionMode/useAddConditionModeModal";
import { useSelectCondition } from "../_share_InvitationQR/useSelectCondition";

import { useShowQRModeModal } from "./ShowQRMode/useShowQRModeModal";

import { Condition } from "./type";
import { useCreateOrUpdateRegistrationInvitationLink } from "./useCreateOrUpdateRegistrationInvitationLink";

import { useGenerateDefaultCondition } from "./useGenerateDefaultCondition";

import { Loading, ScrollableBodyModal } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useDefaultRegistrationInvitationLink } from "~/hooks/registrationInvitationLink/useDefaultRegistrationInvitationLink";
import { useSnackbar } from "~/hooks/shared";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";
import { captureException } from "~/util";

type Props = {
  open: boolean;
  onCancel: () => void;
};

/**
 * SHOW_QR: 共通QRを表示する画面
 * ADD_CONDITION: 条件付与画面
 */
type Mode = "SHOW_QR" | "ADD_CONDITION";

const useOnAddConditionsToShareInvitationLink = ({
  createOrUpdateRegistrationInvitationLink,
  toShowQRMode,
  selectedCondition,
}: {
  createOrUpdateRegistrationInvitationLink: () => Promise<RegistrationInvitationLink | undefined>;
  toShowQRMode: () => void;
  selectedCondition: Condition;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useCurrentUser();
  const { currentSpace } = useCurrentSpace();

  const onAddConditions = async () => {
    try {
      await createOrUpdateRegistrationInvitationLink();
      toShowQRMode();
    } catch (error) {
      enqueueSnackbar("招待QRへの条件付与に失敗しました。管理者までお問い合わせください。", {
        variant: "error",
      });
      captureException({
        error: new Error("共通招待QRへの条件付与に失敗しました。"),
        tags: { type: "useGenerateRegistrationInvitationLinkUrl:handleSubmit" },
        extras: {
          error,
          employeeId: currentUser.id,
          spaceId: currentSpace.id,
          selectedCondition,
        },
      });
    }
  };

  return { onAddConditions };
};

const useGenerateRegistrationInvitationLink = ({
  defaultRegistrationInvitationLink,
}: {
  defaultRegistrationInvitationLink: RegistrationInvitationLink;
}) => {
  // 共通QR表示画面と条件付与画面とで、付与された条件（イベントやタグなど）の状態を共有するため、
  // このようにステートを用いて画面遷移を行なっています
  const [mode, setMode] = useState<Mode>("SHOW_QR");
  const toAddConditionMode = () => setMode("ADD_CONDITION");
  const toShowQRMode = () => setMode("SHOW_QR");

  const { generateDefaultCondition } = useGenerateDefaultCondition();

  // NOTE: 共通QRの条件付与管理Hooks
  const {
    selectedCondition,
    addSelectedAuthenticationFlowTypes,
    removeSelectedAuthenticationFlowTypes,
    toggleScenarioSelection,
    changeSelectedRecruitmentStatusId,
    addSelectedOnnEventIds,
    removeSelectedOnnEventIds,
    addSelectedOnnTaskIds,
    removeSelectedOnnTaskIds,
    addSelectedTagIds,
    removeSelectedTagIds,
  } = useSelectCondition({
    defaultCondition: generateDefaultCondition({
      defaultRegistrationInvitationLink,
    }),
  });

  // NOTE: 条件付与された共通QRの生成・更新と、その状態管理するステート
  const {
    createdRegistrationInvitationLink,
    createOrUpdateRegistrationInvitationLink,
    isLoading: isLoadingCreateOrUpdateRegistrationInvitationLink,
  } = useCreateOrUpdateRegistrationInvitationLink({ selectedCondition });

  // NOTE: QR表示画面用のモーダルプロパティ
  const showQRModeModal = useShowQRModeModal({
    toAddConditionMode,
    defaultRegistrationInvitationLink,
    createdRegistrationInvitationLink,
  });

  // NOTE: 条件付与画面用のモーダルプロパティ
  const { onAddConditions } = useOnAddConditionsToShareInvitationLink({
    createOrUpdateRegistrationInvitationLink,
    toShowQRMode,
    selectedCondition,
  });
  const addConditionModeModal = useAddConditionModeModal({
    selectedCondition,
    addSelectedAuthenticationFlowTypes,
    removeSelectedAuthenticationFlowTypes,
    toggleScenarioSelection,
    changeSelectedRecruitmentStatusId,
    addSelectedOnnEventIds,
    removeSelectedOnnEventIds,
    addSelectedOnnTaskIds,
    removeSelectedOnnTaskIds,
    addSelectedTagIds,
    removeSelectedTagIds,
    isLoadingFooterButton: isLoadingCreateOrUpdateRegistrationInvitationLink,
    onAddConditions,
    onCancelAddConditions: toShowQRMode,
    currentScenarios: [],
  });

  if (mode === "ADD_CONDITION") {
    return addConditionModeModal;
  }
  return showQRModeModal;
};

export const GenerateRegistrationInvitationLinkModal = ({ open, onCancel }: Props): JSX.Element => {
  const { currentUser } = useCurrentUser();
  const { currentSpace } = useCurrentSpace();
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: defaultRegistrationInvitationLink,
    isLoading: isLoadingRegistrationInvitationLink,
  } = useDefaultRegistrationInvitationLink(currentUser.id);

  if (isLoadingRegistrationInvitationLink) {
    return <Loading fullHeight size="large" />;
  }
  if (!defaultRegistrationInvitationLink) {
    enqueueSnackbar("招待リンクの取得に失敗しました。担当者までお問い合わせください。", {
      variant: "error",
    });
    captureException({
      error: new Error("招待リンクの取得に失敗しました。"),
      tags: { type: "useGenerateRegistrationInvitationLinkUrl:handleSubmit" },
      extras: {
        employeeId: currentUser.id,
        spaceId: currentSpace.id,
      },
    });
    return <Loading fullHeight size="large" />;
  }

  return (
    <GenerateRegistrationInvitationLinkModalCore
      open={open}
      onCancel={onCancel}
      defaultRegistrationInvitationLink={defaultRegistrationInvitationLink}
    />
  );
};

// NOTE: defaultRegistrationInvitationLinkが取得が終わっている前提で実装をする方が、ステート管理が楽なのでcoreを分けています
const GenerateRegistrationInvitationLinkModalCore: FC<
  Props & {
    defaultRegistrationInvitationLink: RegistrationInvitationLink;
  }
> = ({ open, onCancel, defaultRegistrationInvitationLink }): JSX.Element => {
  const { title, titleHint, content, footer } = useGenerateRegistrationInvitationLink({
    defaultRegistrationInvitationLink,
  });

  return (
    <StyledModal
      open={open}
      title={title}
      titleHint={titleHint}
      footer={footer}
      content={content}
      onCancel={onCancel}
      fullWidth
      disableBackdropModal
      isShowFooterDivider
    />
  );
};

const StyledModal = styled(ScrollableBodyModal)`
  .MuiDialog-paper {
    padding-bottom: 24px;
    row-gap: 24px;
  }
  footer {
    margin-top: 24px;
  }
`;
