import { useSWRConfig } from "swr";
import useSWRMutation from "swr/mutation";

import { mutateAndClearEmployeeInformationCache } from "../employeeInformation/useEmployeeInformation";
import { useSnackbar } from "../shared";

import { useMutateAllNewcomers } from "./useAllNewcomers";

import { generateUseNewGraduateKey } from "./useNewGraduate";

import { generateUseEmployeeKeys } from "~/hooks/employee/useEmployee";
import { apiClient } from "~/libs";

export const useAddScenarioToNewGraduate = ({
  employeeId,
  onAddScenarioToNewGraduate,
}: {
  employeeId: string;
  onAddScenarioToNewGraduate?: () => void;
}) => {
  const { mutate } = useSWRConfig();
  const { mutateAllNewcomers } = useMutateAllNewcomers();
  const { enqueueSnackbar } = useSnackbar();

  return useSWRMutation(
    { key: "addScenarioToNewGraduate", employeeId },
    async (
      _key,
      {
        arg,
      }: {
        arg: { scenarioId: string; recruitmentStatusId: string; offerAcceptanceDeadline?: Date };
      }
    ) => {
      await apiClient.post("/scenario_api/scenarios/:id/relations", {
        employeeId,
        ...arg,
      });
    },
    {
      onSuccess: () => {
        enqueueSnackbar("シナリオが追加されました", { variant: "success" });

        onAddScenarioToNewGraduate?.();
        mutate(generateUseEmployeeKeys(employeeId));
        mutate(generateUseNewGraduateKey({ newGraduateId: employeeId }));
        mutateAllNewcomers();
        // NOTE: 選考ステータス変更モーダル上で、なぜか古いキャッシュが初回で取得されて、RHFのデフォルト値にセットされてしまうためキャッシュをクリアする
        mutateAndClearEmployeeInformationCache(employeeId);
      },

      onError: (error: Error) => {
        enqueueSnackbar(
          `シナリオの追加に失敗しました${error.message ? `（${error.message}）` : ""}`,
          { variant: "error" }
        );
      },
    }
  );
};
