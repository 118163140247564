import { MenuItem } from "@material-ui/core";
import { Box } from "@mui/material";
import React, { FC, useCallback } from "react";
import { useController, useFormContext } from "react-hook-form";

import { useRichMenuTabContext } from "../../RichMenuTabContext";
import { InputState } from "../../useForm";

import { useRecruitmentStatusList } from "~/components/providers/ScenarioProvider";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Icon,
  Typography,
  UncontrolledMenu,
} from "~/components/uiParts";

export const TargetRecruitmentStatusesSelector: FC = () => {
  const { control } = useFormContext<InputState>();
  const {
    field: { value: asyncTargetRecruitmentStatusIds, onChange: setAsyncTargetSelectedStatusIds },
  } = useController({ control, name: "asyncTargetRecruitmentStatusIds" });
  const { recruitmentStatuses } = useRecruitmentStatusList();
  const { selectedRecruitmentStatus: currentRecruitmentStatus } = useRichMenuTabContext();

  const onChangeCheckbox = useCallback(
    (statusId: string) => {
      const newValue = asyncTargetRecruitmentStatusIds.includes(statusId)
        ? asyncTargetRecruitmentStatusIds.filter((id) => id !== statusId)
        : [...asyncTargetRecruitmentStatusIds, statusId];
      setAsyncTargetSelectedStatusIds(newValue);
    },
    [asyncTargetRecruitmentStatusIds, setAsyncTargetSelectedStatusIds]
  );

  return (
    <Box width={"100%"}>
      <UncontrolledMenu
        renderButton={(openMenu) => (
          <Button
            color="default"
            variant="outlined"
            borderRadius="regular"
            fullWidth
            onClick={openMenu}
            endIcon={<Icon icon="dropdownArrow" size="sm" color="grey" />}
          >
            <Box width={"100%"} flex={1} display={"flex"}>
              <Typography variant="body2" noWrap>
                {asyncTargetRecruitmentStatusIds.length > 0
                  ? asyncTargetRecruitmentStatusIds
                      .map((id) => recruitmentStatuses.find((status) => status.id === id)?.label)
                      .join(",")
                  : "----"}
              </Typography>
            </Box>
          </Button>
        )}
        menuProps={{
          anchorOrigin: { vertical: "top", horizontal: "left" },
          transformOrigin: { vertical: "top", horizontal: "right" },
        }}
        menuItemOptions={recruitmentStatuses.map((status) => ({
          renderCustomComponent: () => {
            const isDisabled = currentRecruitmentStatus?.id === status.id;
            return (
              <MenuItem
                style={{
                  padding: 0,
                }}
                disabled={isDisabled}
              >
                <FormControlLabel
                  onChange={() => onChangeCheckbox(status.id)}
                  control={
                    <Checkbox
                      checked={asyncTargetRecruitmentStatusIds.includes(status.id)}
                      name={status.label}
                      value={status.id}
                      disabled={isDisabled}
                    />
                  }
                  label={
                    <Typography variant="body2" color="textSecondary">
                      {status.label}
                    </Typography>
                  }
                  style={{
                    padding: "8px 16px",
                    width: "100%",
                  }}
                  disabled={isDisabled}
                />
              </MenuItem>
            );
          },
        }))}
      />
    </Box>
  );
};
