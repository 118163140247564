import {
  CandidateDateWithNumberOfParticipants,
  EmployeeActiveLog,
  OnnEvent,
  OnnEventDeterminedDate,
} from "@onn/common";

import { useCallback } from "react";

import { useGenerateDateRow } from "./useGenerateDateRow";
import { useGenerateHeaderRow } from "./useGenerateHeaderRow";

import { OnnEventAnswerWithEmployee } from "~/hooks/onnEvent";

// MultiGrid用のデータを生成する
export const useGenerateRows = () => {
  const { generateHeaderRow } = useGenerateHeaderRow();
  const { generateDataRow } = useGenerateDateRow();

  const generateRows = useCallback(
    ({
      onnEvent,
      candidateDateMap,
      candidateDateList,
      employeeIdToDeterminedDate,
      onnEventAnswers,
      hasCapacity,
      numberOfDistribution,
      numberOfResponses,
      selectedCandidateDateIds,
      employeeIdToLatestEmployeeActiveLogMap,
    }: {
      onnEvent: OnnEvent;
      candidateDateMap: Map<string, CandidateDateWithNumberOfParticipants>;
      candidateDateList: CandidateDateWithNumberOfParticipants[];
      employeeIdToDeterminedDate: Map<string, OnnEventDeterminedDate>;
      onnEventAnswers: OnnEventAnswerWithEmployee[];
      hasCapacity: boolean;
      numberOfDistribution: number;
      numberOfResponses: number;
      selectedCandidateDateIds: string[];
      employeeIdToLatestEmployeeActiveLogMap: Map<string, EmployeeActiveLog>;
    }) => {
      const headerRow = generateHeaderRow({
        candidateDateList,
        onnEventAnswers,
        hasCapacity,
        numberOfDistribution,
        numberOfResponses,
        selectedCandidateDateIds,
      });

      const dataRows = onnEventAnswers.map((onnEventAnswer) => {
        const determinedDate =
          onnEventAnswer && employeeIdToDeterminedDate.get(onnEventAnswer.employeeId);
        const employeeActiveLog =
          onnEventAnswer && employeeIdToLatestEmployeeActiveLogMap.get(onnEventAnswer.employeeId);
        return generateDataRow({
          onnEvent,
          onnEventAnswer,
          determinedDate,
          employeeActiveLog,
          candidateDateMap,
          candidateDateList,
          selectedCandidateDateIds,
        });
      });

      return [headerRow, ...dataRows];
    },
    [generateDataRow, generateHeaderRow]
  );

  return { generateRows };
};
