import liff from "@line/liff";
import React, { FC, useCallback, useEffect } from "react";

import { useRegistrationLinks } from "./Registration/hooks/useGenerateRegistrationLinks";

import { SelectionPage } from "~/components/domains/account/SelectionPage";
import { RegistrationInfo } from "~/hooks/employee/useRegistrationInfoByInvitationToken";
import { useLiffUrl } from "~/hooks/liff";
import { useSnackbar } from "~/hooks/shared";
import { useNavigateWithQuery } from "~/hooks/shared/useNavigateWithQuery";
import { captureException, isMobileBrowser } from "~/util";

type Props = {
  registrationInfoByInvitationToken: RegistrationInfo;
  dataLineLoginChannelInfo: {
    liffId: string;
    clientId: string;
  };
};

export const RegisterIndividual: FC<Props> = ({
  registrationInfoByInvitationToken,
  dataLineLoginChannelInfo: { liffId },
}) => {
  const { pathForLine, pathForEmail } = useRegistrationLinks(
    registrationInfoByInvitationToken.employee.invitationToken
  );
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigateWithQuery();
  const { openLiff } = useLiffUrl();

  const startLineRegistration = useCallback(() => {
    if (isMobileBrowser() && !liff.isInClient()) {
      openLiff({ liffId, path: pathForLine });
    }

    navigate(pathForLine);
  }, [liffId, navigate, openLiff, pathForLine]);

  const startEmailRegistration = useCallback(() => {
    if (liff.isInClient()) {
      enqueueSnackbar(
        "エラーが発生したためこの操作を続けることはできません。メールアドレスで登録する場合、採用担当者にお問い合わせください。",
        { variant: "error" }
      );
      captureException({
        error: new Error("アカウント登録ページ群でエラーが発生しました"),
        tags: { type: "Registration" },
        extras: { detail: "liff clientの場合に、Email登録に進むことはできません" },
      });
      return;
    }

    navigate(pathForEmail);
  }, [enqueueSnackbar, navigate, pathForEmail]);

  useEffect(() => {
    // LINE認証のみ選択されている場合このアカウント登録扉ページはスキップされLINE用の登録ページへ進む
    if (registrationInfoByInvitationToken.employee.isOnlySelectableLineAuthentication()) {
      startLineRegistration();
    }
  }, [registrationInfoByInvitationToken.employee, startLineRegistration]);

  const { selectableAuthenticationFlowTypes } = registrationInfoByInvitationToken.employee;

  return (
    <SelectionPage
      start={{
        startLineRegistration,
        startEmailRegistration,
      }}
      canSelectMail={selectableAuthenticationFlowTypes.includes("email")}
      canNavigateToLogin={false}
    />
  );
};
