import { Box, TableCell, TableRow } from "@mui/material";
import { NewGraduateToDisplay, NextPlan, Scenario } from "@onn/common";
import React, { FC, useMemo } from "react";

import styled from "styled-components";

import { headers } from "../Header/TableHeaderRow";

import { ChangeEmployeePredictionSelector } from "~/components/domains/employeePrediction/ChangeEmployeePredictionSelector";
import { NewGraduateNextPlan } from "~/components/domains/employees/NewGraduateTable/TableRowItems/NewGraduateNextPlan";
import { useEmployeeIdToEmployeeMap } from "~/components/domains/employees/NewGraduateTable/hooks/useEmployeeIdToEmployeeMap";
import { ChangeRecruitmentStatusSelector } from "~/components/domains/recruitmentStatus/ChangeRecruitmentStatusSelector";
import { Typography } from "~/components/uiParts";
import theme from "~/config/theme";
import { useGenerateMutateNewGraduate } from "~/hooks/employee";

type Props = {
  newGraduate: NewGraduateToDisplay;
  scenario: NewGraduateToDisplay["scenarios"][number];
  scenarioMap: Map<string, Scenario>;
  scenarioIdToNextPlanMap: Map<string, NextPlan>;
};

export const Row: FC<Props> = ({ newGraduate, scenario, scenarioMap, scenarioIdToNextPlanMap }) => {
  const { employeeIdToEmployeeMap } = useEmployeeIdToEmployeeMap();
  const { generateMutateNewGraduate } = useGenerateMutateNewGraduate();
  const mutate = generateMutateNewGraduate(newGraduate.id);

  const cells = useMemo(
    () => [
      <ScenarioName key={0} name={scenarioMap.get(scenario.scenarioId)?.name} />,
      <ChangeRecruitmentStatusSelector
        key={1}
        scenarioId={scenario.scenarioId}
        newGraduate={newGraduate}
        selectedRecruitmentStatusId={scenario.recruitmentStatusId}
      />,
      <ChangeEmployeePredictionSelector
        key={2}
        scenarioId={scenario.scenarioId}
        currentPredictionId={scenario.predictionId}
        newGraduate={newGraduate}
      />,
      <NewGraduateNextPlan
        key={3}
        newGraduate={newGraduate}
        nextPlan={scenarioIdToNextPlanMap.get(scenario.scenarioId)}
        employeeIdToEmployeeMap={employeeIdToEmployeeMap}
        onSaveNextPlan={mutate}
      />,
    ],
    [
      scenarioMap,
      scenario.scenarioId,
      scenario.recruitmentStatusId,
      scenario.predictionId,
      newGraduate,
      scenarioIdToNextPlanMap,
      employeeIdToEmployeeMap,
      mutate,
    ]
  );
  return (
    <TableRow>
      {headers.map((v, index) => {
        return <Cell key={index}> {cells[index]}</Cell>;
      })}
    </TableRow>
  );
};

const ScenarioName: FC<{ name?: string }> = ({ name }) => {
  return (
    <Box
      sx={{
        border: "1px solid",
        borderRadius: "4px",
        borderColor: theme.palette.grey[200],
        backgroundColor: theme.palette.grey[50],
        height: "40px",
        display: "flex",
        alignItems: "center",
        paddingLeft: "12px",
      }}
    >
      <Typography variant="body2" color="textPrimary">
        {name}
      </Typography>
    </Box>
  );
};

export const Cell = styled(TableCell)`
  &.MuiTableCell-root {
    height: 80px;
    padding: 0px 16px 0px 0px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;
