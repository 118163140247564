import { Employee } from "@onn/common";
import React, { createContext, FC, ReactNode, useCallback, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useAuthorization } from "~/hooks/context/useAuthorization";

import { useCookie, useQuery } from "~/hooks/shared";
import { useCurrentTenantId } from "~/hooks/tenant/useCurrentTenantId";
import { AccountUseCase } from "~/service/usecases/employeeUseCase";
import { isAdminHostname } from "~/util/isAdminHostname";

export const CurrentUserContext = createContext<{
  currentUser: Employee | undefined;
  fetchCurrentUser: () => void;
}>({
  currentUser: undefined,
  fetchCurrentUser: () => void 0,
});

export const CurrentUserProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const navigate = useNavigate();
  const { query } = useQuery();
  const { updateValue: updateCookie } = useCookie("employeeId");
  const { currentTenantId, updateCurrentTenantId } = useCurrentTenantId();

  const { authorizedUsers } = useAuthorization();

  const [currentUser, setCurrentUser] = useState<Employee>();

  const setCurrentUserAndLastUpdateActiveTime = useCallback(
    (newCurrentUser: Employee | undefined) => {
      setCurrentUser(newCurrentUser);
      updateCookie(newCurrentUser?.id || "");
      if (newCurrentUser?.id) {
        AccountUseCase.updateLastActiveTime();
      }
    },
    [updateCookie]
  );

  const fetchCurrentUser = useCallback(async () => {
    const newCurrentUser = authorizedUsers.find(
      (employee) => employee.tenantId === currentTenantId
    );
    setCurrentUser(newCurrentUser);
    updateCookie(newCurrentUser?.id || "");
  }, [authorizedUsers, currentTenantId, updateCookie]);

  // 候補者用のドメイン(サブドメイン)での処理
  useEffect(() => {
    if (isAdminHostname()) return;
    if (!currentTenantId) return;

    const newCurrentUser = authorizedUsers.find((v) => v.tenantId === currentTenantId);
    setCurrentUserAndLastUpdateActiveTime(newCurrentUser);
  }, [authorizedUsers, currentTenantId, setCurrentUserAndLastUpdateActiveTime]);

  // 管理者用のドメインの処理
  useEffect(() => {
    if (!isAdminHostname()) return;
    if (authorizedUsers.length !== 1) return;

    const newCurrentUser = authorizedUsers[0] as (typeof authorizedUsers)[number];
    setCurrentUserAndLastUpdateActiveTime(newCurrentUser);
    updateCurrentTenantId(newCurrentUser.tenantId);
  }, [
    authorizedUsers,
    navigate,
    query,
    currentTenantId,
    updateCurrentTenantId,
    setCurrentUserAndLastUpdateActiveTime,
  ]);

  if (authorizedUsers.length === 0) {
    throw new Error("authorizedUsers is empty");
  }

  if (!currentUser) {
    return <></>;
  }

  return (
    <CurrentUserContext.Provider value={{ currentUser, fetchCurrentUser }}>
      {children}
    </CurrentUserContext.Provider>
  );
};
