import { Box, Menu, MenuItem } from "@material-ui/core";
import { isEmpty } from "lodash";
import pako from "pako";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import { MutateSearchNewGraduatesPerPageFunctions } from "../../useNewGraduateTable";

import { BulkActionControl } from "./BulkActionControl/BulkActionControl";
import { OpenInviteMembersModalButton } from "./OpenInviteMembersModalButton";

import {
  AnyInputCondition,
  AnyValidCondition,
} from "~/components/domains/employees/NewGraduateSearchModal/types/condition";
import { LogicType } from "~/components/domains/employees/NewGraduateSearchModal/types/logic-type";
import { NewGraduateTable } from "~/components/domains/employees/NewGraduateTable";
import {
  Button,
  Icon,
  IconButton,
  Loading,
  NotFoundPaper,
  SearchByConditionsButton,
  SearchForm,
  Typography,
} from "~/components/uiParts";
import { TableActionsLayoutWithFilterAndSearchForm } from "~/components/uiParts/TableActionsLayoutWithFilterAndSearchForm";
import { useAllEmployees, useCurrentUser } from "~/hooks/employee";
import { useCreateNewGraduates } from "~/hooks/employee/useCreateNewGraduates";
import { SearchNewGraduatesPerPageData } from "~/hooks/employee/useSearchNewGraduatesPerPage";
import { useSendInvitationNotifications } from "~/hooks/employee/useSendInvitationNotifications";
import { useEmployeeInformationGroups } from "~/hooks/employeeInformationGroup/useEmployeeInformationGroups";
import { useEmployeeTags } from "~/hooks/employeeTag";
import { useModal } from "~/hooks/modal";
import { useNotExpiredOnnEvents } from "~/hooks/onnEvent";
import { useOnnTasks } from "~/hooks/onnTask";
import { useLocalStorage, useQuery } from "~/hooks/shared";
import { State } from "~/hooks/shared/useToggleSelectAll/useToggleSelectAll";

type Props = {
  searchResultCount: number;
  searchNewGraduatesPerPageData: SearchNewGraduatesPerPageData;
  keywordsString: string;
  keywordsStringSearchErrorText?: string;
  onChangeKeywordsString: (value: string) => void;
  conditions: AnyInputCondition[];
  validConditionsCount: number;
  logicType: LogicType;
  onSearchConfirm(logicType: LogicType, conditions: AnyValidCondition[]): Promise<void>;
  onResetSearchConditions(): void;
  onResetSelectedNewGraduateIds(): void;
  onSelectNewGraduateId(newGraduateId: string): void;
  selectedNewGraduateIds: Set<string>;
  toggleSelectAll(): void;
  allSelectionState: State;
  onConfirmAddTags(tagIds: string[]): Promise<void>;
  onLoadMore: () => Promise<void> | void;
} & MutateSearchNewGraduatesPerPageFunctions;

export const NewGraduateTab: FC<Props> = ({
  searchResultCount,
  searchNewGraduatesPerPageData,
  keywordsString,
  keywordsStringSearchErrorText,
  onChangeKeywordsString,
  conditions,
  validConditionsCount,
  logicType,
  onSearchConfirm,
  onResetSearchConditions,
  onResetSelectedNewGraduateIds,
  onSelectNewGraduateId,
  selectedNewGraduateIds,
  toggleSelectAll,
  allSelectionState,
  onConfirmAddTags,
  onLoadMore,
  mutateAllPagesOfSearchNewGraduatesPerPage,
}) => {
  const { mutate: mutateAllPagesOfSearchNewGraduatesPerPageData } =
    mutateAllPagesOfSearchNewGraduatesPerPage;

  const { currentUser } = useCurrentUser();
  const { handleModal } = useModal();
  const { query } = useQuery();
  const { storeValue } = useLocalStorage();

  const { data: employeeTagsData, isLoading: isLoadingEmployeeTagsData } = useEmployeeTags();
  const { data: onnEvents, isLoading: isLoadingOnnEvents } = useNotExpiredOnnEvents({
    tenantId: currentUser.tenantId,
  });
  const { data: onnTasks, isLoading: isLoadingOnnTasks } = useOnnTasks({
    tenantId: currentUser.tenantId,
    isExceededDeadlineDate: false,
  });
  const {
    data: employeeInformationGroupWithFieldAndOptions,
    isLoading: isLoadingEmployeeInformationGroup,
  } = useEmployeeInformationGroups();
  const { allEmployees } = useAllEmployees();
  const { createNewGraduates } = useCreateNewGraduates();
  const { sendInvitations } = useSendInvitationNotifications();

  const openDialogType = query.get("openDialogType");

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpenMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClickedCsvUploadMenuItem = useCallback(() => {
    handleModal({
      name: "inviteNewGraduateWithCSVModal",
      args: {
        inviteType: "newGraduate",
        employeeTags: employeeTagsData?.employeeTags || [],
        onnEvents: onnEvents || [],
        onnTasks: onnTasks || [],
        allEmployees,
        employeeInformationGroups: employeeInformationGroupWithFieldAndOptions || [],
        onCreateNewGraduateWithCsv: () => {
          mutateAllPagesOfSearchNewGraduatesPerPageData();
        },
      },
    });
    setAnchorEl(null);
  }, [
    handleModal,
    employeeTagsData?.employeeTags,
    onnEvents,
    onnTasks,
    allEmployees,
    employeeInformationGroupWithFieldAndOptions,
    mutateAllPagesOfSearchNewGraduatesPerPageData,
  ]);

  const handleOpenNewGraduateInviteModal = useCallback(() => {
    handleModal({
      name: "newGraduateInviteModal",
      args: {
        async onSubmit(employeeIds) {
          await sendInvitations(employeeIds);
          mutateAllPagesOfSearchNewGraduatesPerPageData();
        },
      },
    });
    setAnchorEl(null);
  }, [handleModal, sendInvitations, mutateAllPagesOfSearchNewGraduatesPerPageData]);

  const handleOpenNewGraduateSearchModal = useCallback(() => {
    handleModal({
      name: "newGraduateSearchModal",
      args: {
        conditions,
        logicType,
        defaultCount: searchResultCount,
        onSearchConfirm,
      },
    });
  }, [handleModal, conditions, logicType, onSearchConfirm, searchResultCount]);

  const handleOpenAddEmployeeTagsActionModal = useCallback(() => {
    handleModal({
      name: "addEmployeeTagsActionModal",
      args: {
        onConfirm: onConfirmAddTags,
      },
    });
  }, [handleModal, onConfirmAddTags]);

  const handleOpenDownloadEmployeesWithCSVActionModal = useCallback(() => {
    handleModal({
      name: "downloadEmployeesWithCSVActionModal",
      args: {
        employeeIds: Array.from(selectedNewGraduateIds),
      },
    });
  }, [handleModal, selectedNewGraduateIds]);

  const handleNavigateToBulkSendMessage = useCallback(() => {
    // NOTE: URL の文字数制限が2048文字程度なため、クエリパラメータは使用しなかった
    // NOTE: ローカルストレージの平均的な容量が5MBであることを考慮して圧縮後の値を保存し、解凍して使用するようにしている
    const compressedReceiverIds = pako.deflate(JSON.stringify(Array.from(selectedNewGraduateIds)));

    // NOTE: 連続で素早く一括メッセージ送信タブを開いた場合は最新の値で上書きされた状態で全てのタブが開くのでFBがあった場合に対応する
    // 対応する場合、一案として searchParams と localStorage に同一の文字列を持たせて参照することで実現できる
    storeValue("compressedReceiverIds", compressedReceiverIds);

    window.open("/contact_rooms/contact_messages_orders/new", "_blank");
  }, [selectedNewGraduateIds, storeValue]);

  const handleClickedUpdateEmployeeInformationWithCSVMenuItem = useCallback(() => {
    handleModal({
      name: "updateEmployeeInformationWithCSVModal",
      args: {
        onUpdate: () => {
          mutateAllPagesOfSearchNewGraduatesPerPageData();
        },
        employeeInformationGroups: employeeInformationGroupWithFieldAndOptions || [],
      },
    });
    setAnchorEl(null);
  }, [
    employeeInformationGroupWithFieldAndOptions,
    handleModal,
    mutateAllPagesOfSearchNewGraduatesPerPageData,
  ]);

  const handleClickedEditEmployeePredictionMenuItem = useCallback(() => {
    handleModal({
      name: "editEmployeePredictionsModal",
      args: {},
    });
    setAnchorEl(null);
  }, [handleModal]);

  const openInviteMembersModal = useCallback(() => {
    handleModal({
      name: "createNewGraduateModal",
      args: {
        onSubmit: async (
          userDataArray: { email: string; employeeTagIds: string[] }[],
          selectedScenarios: { scenarioId: string; recruitmentStatusId: string }[],
          isSendEmail: boolean
        ) => {
          await createNewGraduates(
            userDataArray.map((userData) => {
              return {
                ...userData,
                onnEventIds: [],
                isNewGraduate: true,
                employeeTagIds: userData.employeeTagIds,
                onnTaskIds: [],
                isSendInvitationEmail: isSendEmail,
                scenarios: selectedScenarios,
              };
            })
          );
          mutateAllPagesOfSearchNewGraduatesPerPageData();
        },
        currentUser,
        employeeTags: employeeTagsData?.employeeTags || [],
      },
    });
  }, [
    createNewGraduates,
    currentUser,
    handleModal,
    employeeTagsData?.employeeTags,
    mutateAllPagesOfSearchNewGraduatesPerPageData,
  ]);

  useEffect(() => {
    if (openDialogType === "invite" && !isLoadingOnnEvents && !isLoadingOnnTasks) {
      openInviteMembersModal();
    }
  }, [openDialogType, isLoadingOnnEvents, openInviteMembersModal, isLoadingOnnTasks]);

  const [notFoundText, notFoundButtons] = useMemo(() => {
    const text = "該当の候補者が見つかりません。";
    const buttons = [
      <OpenInviteMembersModalButton
        key="invite-member-modal-button"
        onClick={() => openInviteMembersModal()}
      />,
    ];
    return [text, buttons];
  }, [openInviteMembersModal]);

  const isLoading =
    isLoadingEmployeeTagsData ||
    isLoadingOnnEvents ||
    isLoadingOnnTasks ||
    isLoadingEmployeeInformationGroup;
  if (isLoading) {
    return (
      <Box pt={5}>
        <Loading size="large" />
      </Box>
    );
  }

  return (
    <Box pt={"40px"}>
      <Box mb="24px" width="100%" display="flex" alignItems="flex-end" gridGap={24}>
        {allSelectionState.checked ? (
          <BulkActionControl
            selectedCount={selectedNewGraduateIds.size}
            onResetSelection={onResetSelectedNewGraduateIds}
            onClickSendMessage={handleNavigateToBulkSendMessage}
            onClickAddTags={handleOpenAddEmployeeTagsActionModal}
            onClickDownloadWithCSV={handleOpenDownloadEmployeesWithCSVActionModal}
          />
        ) : (
          <>
            <TableActionsLayoutWithFilterAndSearchForm
              filter={
                <SearchByConditionsButton
                  onReset={onResetSearchConditions}
                  onClick={handleOpenNewGraduateSearchModal}
                  count={validConditionsCount}
                />
              }
              searchForm={
                <SearchForm
                  searchValue={keywordsString}
                  onSearchValue={onChangeKeywordsString}
                  placeholder="候補者名・候補者ID・メールアドレス・電話番号で検索"
                  variant="standard"
                  errorText={keywordsStringSearchErrorText}
                  fullWidth
                />
              }
            />
            <Box width="200px" display="flex" alignItems="flex-end" justifyContent="space-between">
              {!currentUser.isMember() && (
                <>
                  <Button
                    color="primary"
                    variant="outlined"
                    borderRadius="regular"
                    startIcon={<Icon icon="add" color="primary" size="md" />}
                    onClick={openInviteMembersModal}
                  >
                    候補者を登録
                  </Button>
                  <IconButton icon="menuVert" onClick={handleOpenMenu} />
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted={true}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                  >
                    <MenuItem onClick={() => handleOpenNewGraduateInviteModal()}>
                      <Typography variant="body2">{"招待"}</Typography>
                    </MenuItem>
                    <MenuItem onClick={handleClickedCsvUploadMenuItem}>
                      <Typography variant="body2">{"候補者一括登録"}</Typography>
                    </MenuItem>
                    <MenuItem onClick={handleClickedUpdateEmployeeInformationWithCSVMenuItem}>
                      <Typography variant="body2">{"候補者情報一括更新"}</Typography>
                    </MenuItem>
                    {currentUser.isAdmin() && (
                      <MenuItem onClick={handleClickedEditEmployeePredictionMenuItem}>
                        <Typography variant="body2">{"ヨミ設定"}</Typography>
                      </MenuItem>
                    )}
                  </Menu>
                </>
              )}
            </Box>
          </>
        )}
      </Box>

      {searchNewGraduatesPerPageData && (
        <>
          {!isEmpty(searchNewGraduatesPerPageData) ? (
            <NewGraduateTable
              searchNewGraduatesPerPageData={searchNewGraduatesPerPageData}
              selectedNewGraduateIds={selectedNewGraduateIds}
              onSelectNewGraduateId={onSelectNewGraduateId}
              checked={allSelectionState.checked}
              indeterminate={allSelectionState.indeterminate}
              onChangeCheckBox={toggleSelectAll}
              onLoadMore={onLoadMore}
              mutateAllPagesOfSearchNewGraduatesPerPage={mutateAllPagesOfSearchNewGraduatesPerPage}
            />
          ) : currentUser.isMember() ? (
            <NotFoundPaper text="表示する候補者がいません" />
          ) : (
            <NotFoundPaper text={notFoundText} buttons={notFoundButtons} />
          )}
        </>
      )}
    </Box>
  );
};
