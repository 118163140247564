import { z } from "zod";

import { OnnEventEvaluationSingleSelectFieldSchema } from "../../../../../_gen/zodSchema/index";

import { onnEventEvaluationSingleSelectOptionSchema } from "./OnnEventEvaluationSingleSelectOption";

export const onnEventEvaluationSingleSelectFieldSchema =
  OnnEventEvaluationSingleSelectFieldSchema.merge(
    z.object({ options: z.array(onnEventEvaluationSingleSelectOptionSchema) })
  );
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOnnEventEvaluationSingleSelectField
  extends z.infer<typeof onnEventEvaluationSingleSelectFieldSchema> {}
