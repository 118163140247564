import { z } from "zod";

import { EmailType, LineType } from "../../../Employee/AuthenticationType";
import { RecruitmentStatus } from "../../../Recruitment/RecruitmentStatus";
import { RegistrationInvitationLink } from "../../../RegistrationInvitationLink";
import { RegistrationInvitationLinkAccessLog } from "../../../RegistrationInvitationLinkAccessLog";

export interface APISchemaRegistrationInvitationLink {
  "/api/registration-invitation-link/registration-invitation-links-default": {
    GET: {
      response: {
        data: ExcludeMethods<RegistrationInvitationLink> | null;
      };
    };
  };
  "/api/registration-invitation-link/spaces-registration-invitation-links-default": {
    GET: {
      query: z.infer<typeof retrieveSpacesRegistrationInvitationLinksDefaultApiSchema>["query"];
      response: {
        data: {
          registrationInvitationLink: ExcludeMethods<RegistrationInvitationLink> | null;
          recruitmentStatuses: ExcludeMethods<RecruitmentStatus>[];
        };
      };
    };
  };
  "/api/registration-invitation-link": {
    GET: {
      query: z.infer<typeof getRegistrationInvitationLinkSchema>["query"];
      response: {
        data: {
          registrationInvitationLink: ExcludeMethods<RegistrationInvitationLink>;
          tenantInfo: { tenantName: string; tenantLogoUrl: string };
        };
      };
    };
    POST: {
      body: z.infer<typeof postRegistrationInvitationLinkApiSchema>["body"];
      response: {
        data: {
          registrationInvitationLink: ExcludeMethods<RegistrationInvitationLink>;
        };
      };
    };
    PATCH: {
      body: z.infer<typeof patchRegistrationInvitationLinkApiSchema>["body"];
      response: {
        data: {
          registrationInvitationLink: ExcludeMethods<RegistrationInvitationLink>;
        };
      };
    };
  };
  "/api/registration-invitation-link/retrieve-last-registration-invitation-link": {
    POST: {
      body: z.infer<typeof retrieveLastRegistrationInvitationLinkSchema>["body"];
      response: {
        data: {
          registrationInvitationLink: RegistrationInvitationLink | null;
        };
      };
    };
  };
  "/api/registration-invitation-link/registration-invitation-link-access-logs": {
    POST: {
      body: z.infer<typeof postRegistrationInvitationLinksAccessLogsApiSchema>["body"];
      response: {
        data: ExcludeMethods<RegistrationInvitationLinkAccessLog>;
      };
    };
  };
}

export const getRegistrationInvitationLinkSchema = z.object({
  query: z.object({
    id: z.string().uuid(),
  }),
});

export const retrieveSpacesRegistrationInvitationLinksDefaultApiSchema = z.object({
  query: z.object({
    "tenant-id": z.string(),
    "space-id": z.string(),
  }),
});

export const retrieveLastRegistrationInvitationLinkSchema = z.object({
  body: z.object({
    lineAccessToken: z.string(),
    liffId: z.string(),
  }),
});

export const postRegistrationInvitationLinksAccessLogsApiSchema = z.object({
  body: z.object({
    registrationInvitationLinkId: z.string(),
    lineAccessToken: z.string(),
    liffId: z.string(),
  }),
});

export const postRegistrationInvitationLinkApiSchema = z.object({
  body: z.object({
    conditions: z.object({
      spaceId: z.string(),
      selectableAuthenticationFlowTypes: z.union([
        z.tuple([z.literal("line" as LineType)]),
        z.tuple([z.literal("line" as LineType), z.literal("email" as EmailType)]),
      ]),
      scenarios: z.array(
        z.object({
          scenarioId: z.string(),
          recruitmentStatusId: z.string(),
        })
      ),
      onnEventIds: z.array(z.string()),
      onnTaskIds: z.array(z.string()),
      tagIds: z.array(z.string()),
    }),
  }),
});

export const patchRegistrationInvitationLinkApiSchema = z.object({
  body: z.object({
    registrationInvitationLinkId: z.string(),
    conditions: z.object({
      selectableAuthenticationFlowTypes: z.union([
        z.tuple([z.literal("line" as LineType)]),
        z.tuple([z.literal("line" as LineType), z.literal("email" as EmailType)]),
      ]),
      scenarios: z.array(
        z.object({
          scenarioId: z.string(),
          recruitmentStatusId: z.string(),
        })
      ),
      onnEventIds: z.array(z.string()),
      onnTaskIds: z.array(z.string()),
      tagIds: z.array(z.string()),
    }),
  }),
});
