import { z } from "zod";

/////////////////////////////////////////
// ONN EVENT EVALUATION SINGLE SELECT OPTION SCHEMA
/////////////////////////////////////////

export const OnnEventEvaluationSingleSelectOptionSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  onnEventEvaluationSingleSelectFieldId: z.string(),
  label: z.string(),
  order: z.number(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type OnnEventEvaluationSingleSelectOption = z.infer<
  typeof OnnEventEvaluationSingleSelectOptionSchema
>;

/////////////////////////////////////////
// ONN EVENT EVALUATION SINGLE SELECT OPTION OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const OnnEventEvaluationSingleSelectOptionOptionalDefaultsSchema =
  OnnEventEvaluationSingleSelectOptionSchema.merge(
    z.object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
  );

export type OnnEventEvaluationSingleSelectOptionOptionalDefaults = z.infer<
  typeof OnnEventEvaluationSingleSelectOptionOptionalDefaultsSchema
>;

export default OnnEventEvaluationSingleSelectOptionSchema;
