import { CsvInputValueKeyToInfoType } from "./_share";

export type InputValue = {
  lastName?: string;
  firstName?: string;
  email: string;
  scenario1?: string;
  recruitmentStatus1?: string;
  scenario2?: string;
  recruitmentStatus2?: string;
  scenario3?: string;
  recruitmentStatus3?: string;
  isInvite?: string;
  tagNames?: string[];
  onnEventTitles?: string[];
  onnTaskTitles?: string[];
  kanaLastName?: string;
  kanaFirstName?: string;
  gender?: string;
  dateOfBirth?: string;
  phoneNumber?: string;
  homePhoneNumber?: string;
  graduationYear?: string;
  graduationMonth?: string;
  schoolName?: string;
  faculty?: string;
  schoolDepartment?: string;
  addressPostalCode?: string;
  addressPrefecture?: string;
  addressCity?: string;
  addressLine1?: string;
  addressLine2?: string;
  hometownAddressPostalCode?: string;
  hometownAddressPrefecture?: string;
  hometownAddressCity?: string;
  hometownAddressLine1?: string;
  hometownAddressLine2?: string;
  externalId?: string;
  assigneeEmail?: string;
  offerAcceptanceDeadline?: string;
  spaceName?: string;
};

export const csvInputValueKeyToInfo: CsvInputValueKeyToInfoType<Required<InputValue>> = {
  spaceName: { labelInCsv: "スペース名", isRequired: true },
  lastName: { labelInCsv: "姓", isRequired: true },
  firstName: { labelInCsv: "名", isRequired: true },
  email: { labelInCsv: "メールアドレス", isRequired: true },
  scenario1: { labelInCsv: "シナリオ1", isRequired: false },
  recruitmentStatus1: { labelInCsv: "選考ステータス1", isRequired: false },
  scenario2: { labelInCsv: "シナリオ2", isRequired: false },
  recruitmentStatus2: { labelInCsv: "選考ステータス2", isRequired: false },
  scenario3: { labelInCsv: "シナリオ3", isRequired: false },
  recruitmentStatus3: { labelInCsv: "選考ステータス3", isRequired: false },
  isInvite: {
    labelInCsv: "招待フラグ",
    isRequired: false,
    allowedValues: ["送信"] as string[],
    allowedValueToBoolean: { 送信: true },
  },
  assigneeEmail: { labelInCsv: "担当者", isRequired: false },
  tagNames: { labelInCsv: "タグ", isRequired: false, multipleColumnCount: 3 },
  onnEventTitles: { labelInCsv: "イベント", isRequired: false, multipleColumnCount: 3 },
  onnTaskTitles: { labelInCsv: "タスク", isRequired: false, multipleColumnCount: 3 },
  kanaLastName: { labelInCsv: "姓（フリガナ）", isRequired: false },
  kanaFirstName: { labelInCsv: "名（フリガナ）", isRequired: false },
  gender: { labelInCsv: "性別", isRequired: false },
  dateOfBirth: { labelInCsv: "生年月日(例：20240101)", isRequired: false },
  phoneNumber: { labelInCsv: "電話番号（携帯）", isRequired: false },
  homePhoneNumber: { labelInCsv: "電話番号（自宅）", isRequired: false },
  graduationYear: { labelInCsv: "卒業年", isRequired: false },
  graduationMonth: { labelInCsv: "卒業月", isRequired: false },
  schoolName: { labelInCsv: "学校名", isRequired: false },
  faculty: { labelInCsv: "学部", isRequired: false },
  schoolDepartment: { labelInCsv: "学科", isRequired: false },
  addressPostalCode: { labelInCsv: "自宅住所・郵便番号", isRequired: false },
  addressPrefecture: { labelInCsv: "自宅住所・都道府県", isRequired: false },
  addressCity: { labelInCsv: "自宅住所・市区町村", isRequired: false },
  addressLine1: { labelInCsv: "自宅住所・番地", isRequired: false },
  addressLine2: { labelInCsv: "自宅住所・建物名", isRequired: false },
  hometownAddressPostalCode: { labelInCsv: "帰省先住所・郵便番号", isRequired: false },
  hometownAddressPrefecture: { labelInCsv: "帰省先住所・都道府県", isRequired: false },
  hometownAddressCity: { labelInCsv: "帰省先住所・市区町村", isRequired: false },
  hometownAddressLine1: { labelInCsv: "帰省先住所・番地", isRequired: false },
  hometownAddressLine2: { labelInCsv: "帰省先住所・建物名", isRequired: false },
  externalId: { labelInCsv: "ID（その他）", isRequired: false },
  offerAcceptanceDeadline: { labelInCsv: "内定承諾期日", isRequired: false },
} as const;

const csvInputValueKeyToSampleValue = (spaceName: string) => ({
  spaceName,
  lastName: "田中",
  firstName: "太郎",
  email: "sample.taro@example.com",
  scenario1: "",
  recruitmentStatus1: "",
  scenario2: "",
  recruitmentStatus2: "",
  scenario3: "",
  recruitmentStatus3: "",
  isInvite: "送信",
  assigneeEmail: "sample@onn-hr.com",
  tagNames: Array(csvInputValueKeyToInfo["tagNames"].multipleColumnCount || 0).fill(""),
  onnEventTitles: Array(csvInputValueKeyToInfo["onnEventTitles"].multipleColumnCount || 0).fill(""),
  onnTaskTitles: Array(csvInputValueKeyToInfo["onnTaskTitles"].multipleColumnCount || 0).fill(""),
  kanaLastName: "タナカ",
  kanaFirstName: "タロウ",
  gender: "男性",
  dateOfBirth: "19900101",
  phoneNumber: "09012345678",
  homePhoneNumber: "0331234567",
  graduationYear: "2022",
  graduationMonth: "3",
  schoolName: "○○大学",
  faculty: "○○学部",
  schoolDepartment: "○○学科",
  addressPostalCode: "000-0000",
  addressPrefecture: "東京都",
  addressCity: "千代田区",
  addressLine1: "千代田1-1",
  addressLine2: "千代田ビル",
  hometownAddressPostalCode: "000-0000",
  hometownAddressPrefecture: "東京都",
  hometownAddressCity: "千代田区",
  hometownAddressLine1: "千代田1-1",
  hometownAddressLine2: "千代田ビル",
  externalId: "1234567890",
  offerAcceptanceDeadline: "20200101",
});

// サンプルCSVファイルのデータ
export const generateSampleCsvData = (spaceName: string) => [
  [
    ...Object.entries(csvInputValueKeyToInfo).flatMap(([_, value]) => {
      if (value.multipleColumnCount) {
        return Array(value.multipleColumnCount).fill(value.labelInCsv);
      }
      return value.labelInCsv;
    }),
  ],
  [...Object.entries(csvInputValueKeyToSampleValue(spaceName)).flatMap(([_, value]) => value)],
];
