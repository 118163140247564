import { Stack } from "@mui/material";
import { OnnEventEvaluationFileField } from "@onn/common";
import React from "react";

import { Draggable } from "react-beautiful-dnd";

import styled from "styled-components";

import { EvaluationField } from "./EvaluationField";

import { FileTypeField } from "./EvaluationField/parts/FileTypeField";
import { useOnnEventFieldArray } from "./useOnnEventFieldArray";

import { Button, DnDDroppable, DnDProvider, Icon } from "~/components/uiParts";

export const OnnEventEvaluationFormForm: React.FC<{
  onnEventEvaluationFileField?: OnnEventEvaluationFileField;
}> = ({ onnEventEvaluationFileField }) => {
  const { onAddField, onRemoveField, onDuplicateField, onDragFieldEnd, fields } =
    useOnnEventFieldArray();

  return (
    <DnDProvider onDragEnd={onDragFieldEnd}>
      <DnDDroppable droppableId="onnEventEvaluationFields" isDropDisabled={false}>
        <Stack rowGap="32px">
          {fields.map((field, fieldIndex) => (
            <Draggable draggableId={field.id} index={fieldIndex} key={field.id}>
              {(provided, snapshot) => (
                <StyledDnDEffect
                  $isDragging={snapshot.isDragging}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                >
                  <EvaluationField
                    key={field.id}
                    fieldIndex={fieldIndex}
                    dragHandleProps={provided.dragHandleProps}
                    onRemoveField={onRemoveField}
                    onDuplicateField={() => onDuplicateField(fieldIndex)}
                    isFinalField={fields.length === 1}
                  />
                </StyledDnDEffect>
              )}
            </Draggable>
          ))}
          <AddFieldButton onClick={onAddField} />
          {onnEventEvaluationFileField && (
            <FileTypeField onnEventEvaluationFileField={onnEventEvaluationFileField} />
          )}
        </Stack>
      </DnDDroppable>
    </DnDProvider>
  );
};

const AddFieldButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <Button
    color="primary"
    variant="dashedOutlined"
    borderRadius="regular"
    startIcon={<Icon icon="add" color="primary" size="md" />}
    onClick={onClick}
    fullWidth
  >
    フォームを追加
  </Button>
);

const StyledDnDEffect = styled.div<{ $isDragging: boolean }>`
  box-shadow: ${({ $isDragging }) => ($isDragging ? "0px 0px 10px #40404033" : "none")};
  background: #fff;
  border-radius: 8px;
`;
