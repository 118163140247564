import { OnnFormTaskAnswer, OnnTask } from "@onn/common";
import { useCallback, useMemo, useState } from "react";

import { useWithBusinessHoursConfirmationModal } from "~/components/domains/businessHours/BusinessHoursConfirmationModal";

import { useEmployees } from "~/hooks/employee";

import { useDeleteOnnFormTaskAnswer } from "~/hooks/onnFormTaskAnswer";
import { useOnnFormTasksAnswersOnlyCountable } from "~/hooks/onnFormTaskAnswer/useOnnFormTasksAnswersOnlyCountable";
import { useSnackbar } from "~/hooks/shared";

export type Filter = "answered" | "noAnswered";

export const useTabPage = ({ onnTask }: { onnTask: OnnTask }) => {
  const {
    data: onnFromTaskAnswers = [],
    isLoading: isLoadingOnnFromTaskAnswers,
    mutate: mutateOnnFromTaskAnswers,
  } = useOnnFormTasksAnswersOnlyCountable({
    onnTaskId: onnTask.id,
  });
  const { data: newGraduates = [], isLoading: isLoadingNewGraduates } = useEmployees(
    onnFromTaskAnswers.map((v) => v.employeeId)
  );
  const newGraduatesMap = useMemo(
    () => new Map(newGraduates.map((v) => [v.id, v])),
    [newGraduates]
  );

  const { execDeleteOnnFormTaskAnswer } = useDeleteOnnFormTaskAnswer();
  const { enqueueSnackbar } = useSnackbar();
  const { withBusinessHours } = useWithBusinessHoursConfirmationModal();

  const [selectedFilterIsAnswered, setSelectedFilterIsAnswered] = useState<Filter>("answered");
  const answeredOnnFromTaskAnswers = useMemo(
    () => onnFromTaskAnswers.filter((v) => v.answers.length > 0),
    [onnFromTaskAnswers]
  );
  const nonAnsweredOnnFromTaskAnswers = useMemo(
    () => onnFromTaskAnswers.filter((v) => v.answers.length === 0),
    [onnFromTaskAnswers]
  );
  // 回答済フィルターボタンクリック
  const onClickFilterButtonAnswered = () => setSelectedFilterIsAnswered("answered");
  // 未回答フィルターボタンクリック
  const onClickFilterButtonNoAnswered = () => setSelectedFilterIsAnswered("noAnswered");

  const deleteOnnFormTaskAnswer = useCallback(
    async (onnFormTaskAnswer: OnnFormTaskAnswer) => {
      withBusinessHours(async (isForce) => {
        await execDeleteOnnFormTaskAnswer({
          onnFormTaskAnswerId: onnFormTaskAnswer.id,
          forceNotifyImmediately: isForce,
        })
          .catch(async (e) => {
            enqueueSnackbar("削除に失敗しました。管理者より連絡がくるまで、お待ちください。", {
              variant: "error",
            });
            throw e;
          })
          .finally(() => {
            mutateOnnFromTaskAnswers();
          });

        // NOTE: システムの応答はシンプルに保つため、一旦、削除対象者の情報は表示しない
        // - 対応する場合は他の部分もまとめて議論
        enqueueSnackbar(
          "削除が完了しました。該当のメンバーには削除された旨を伝える通知が送信されています。",
          { variant: "success" }
        );
      });
    },
    [enqueueSnackbar, execDeleteOnnFormTaskAnswer, mutateOnnFromTaskAnswers, withBusinessHours]
  );

  return {
    onnFromTaskAnswers,
    isLoadingOnnFromTaskAnswers,
    newGraduates,
    isLoadingNewGraduates,
    newGraduatesMap,
    deleteOnnFormTaskAnswer,
    answeredOnnFromTaskAnswers,
    nonAnsweredOnnFromTaskAnswers,
    filter: {
      selectedFilterIsAnswered,
      onClickFilterButtonAnswered,
      onClickFilterButtonNoAnswered,
    },
  };
};
