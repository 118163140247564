import { z } from "zod";

import { JsonValueSchema } from "../inputTypeSchemas/JsonValueSchema";

/////////////////////////////////////////
// DELAY SYSTEM MESSAGE ORDER SCHEMA
/////////////////////////////////////////

export const DelaySystemMessageOrderSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  employeeId: z.string(),
  executionId: z.string(),
  createdAt: z.date(),
  scheduledAt: z.date(),
  isCompleted: z.boolean(),
  title: z.string(),
  lineNotificationPayload: JsonValueSchema,
  emailNotificationPayload: JsonValueSchema,
});

export type DelaySystemMessageOrder = z.infer<typeof DelaySystemMessageOrderSchema>;

/////////////////////////////////////////
// DELAY SYSTEM MESSAGE ORDER OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const DelaySystemMessageOrderOptionalDefaultsSchema = DelaySystemMessageOrderSchema.merge(
  z.object({
    createdAt: z.date().optional(),
    isCompleted: z.boolean().optional(),
  })
);

export type DelaySystemMessageOrderOptionalDefaults = z.infer<
  typeof DelaySystemMessageOrderOptionalDefaultsSchema
>;

export default DelaySystemMessageOrderSchema;
