import { Box } from "@material-ui/core";
import { Stack } from "@mui/material";
import React, { FC } from "react";

import { SelectScenarioAndStatusForm } from "../../scenario/SelectScenarioAndStatusForm";

import { useRecruitmentStatusList } from "~/components/providers/ScenarioProvider";
import { Typography } from "~/components/uiParts";

type Props = {
  selectedScenarios: {
    scenarioId: string;
    recruitmentStatusId?: string;
  }[];
  toggleScenarioSelection: ({ scenarioId }: { scenarioId: string }) => void;
  changeSelectedRecruitmentStatusId: ({
    scenarioId,
    recruitmentStatusId,
  }: {
    scenarioId: string;
    recruitmentStatusId: string;
  }) => void;
};

export const SelectScenarioStep: FC<Props> = ({
  selectedScenarios,
  toggleScenarioSelection,
  changeSelectedRecruitmentStatusId,
}) => {
  const { scenariosWithRecruitmentStatuses } = useRecruitmentStatusList();
  return (
    <Box>
      <Typography variant="body1" color="textPrimary">
        {`対象者に配信するシナリオを選択してください。`}
      </Typography>
      <Stack py={"24px"} height={"300px"} gap={2} sx={{ overflowY: "scroll" }}>
        {scenariosWithRecruitmentStatuses.map(({ scenario, recruitmentStatuses }) => {
          return (
            <SelectScenarioAndStatusForm
              key={scenario.id}
              value={selectedScenarios.find((selected) => selected.scenarioId === scenario.id)}
              toggleScenarioSelection={toggleScenarioSelection}
              onChangeStatus={changeSelectedRecruitmentStatusId}
              scenario={scenario}
              disabled={false} // NOTE: アカウント登録のタイミングでは何もシナリオが紐づいていない前提なので常にfalse
              recruitmentStatuses={recruitmentStatuses}
            />
          );
        })}
      </Stack>
    </Box>
  );
};
