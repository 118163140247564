import { z } from "zod";

import { OnnEventEvaluationFieldAccessControlTypeSchema } from "../inputTypeSchemas/OnnEventEvaluationFieldAccessControlTypeSchema";

/////////////////////////////////////////
// ONN EVENT EVALUATION FILE FIELD SCHEMA
/////////////////////////////////////////

export const OnnEventEvaluationFileFieldSchema = z.object({
  accessControlType: OnnEventEvaluationFieldAccessControlTypeSchema,
  id: z.string(),
  tenantId: z.string(),
  label: z.string(),
  order: z.number(),
  onnEventId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type OnnEventEvaluationFileField = z.infer<typeof OnnEventEvaluationFileFieldSchema>;

/////////////////////////////////////////
// ONN EVENT EVALUATION FILE FIELD OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const OnnEventEvaluationFileFieldOptionalDefaultsSchema =
  OnnEventEvaluationFileFieldSchema.merge(
    z.object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
  );

export type OnnEventEvaluationFileFieldOptionalDefaults = z.infer<
  typeof OnnEventEvaluationFileFieldOptionalDefaultsSchema
>;

export default OnnEventEvaluationFileFieldSchema;
