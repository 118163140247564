import { z } from "zod";

import {
  OnnEventEvaluationFileValue,
  onnEventEvaluationFileValueSchema,
} from "./OnnEventEvaluationFileValue";
import {
  OnnEventEvaluationMultipleSelectValue,
  onnEventEvaluationMultipleSelectValueSchema,
} from "./OnnEventEvaluationMultipleSelectValue";
import {
  OnnEventEvaluationSingleSelectValue,
  onnEventEvaluationSingleSelectValueSchema,
} from "./OnnEventEvaluationSingleSelectValue";
import {
  OnnEventEvaluationTextValue,
  onnEventEvaluationTextValueSchema,
} from "./OnnEventEvaluationTextValue";

export { OnnEventEvaluationFileValue } from "./OnnEventEvaluationFileValue";
export { OnnEventEvaluationMultipleSelectValue } from "./OnnEventEvaluationMultipleSelectValue";
export { OnnEventEvaluationSingleSelectValue } from "./OnnEventEvaluationSingleSelectValue";
export { OnnEventEvaluationTextValue } from "./OnnEventEvaluationTextValue";

export type AnyOnnEventEvaluationValue =
  | OnnEventEvaluationTextValue
  | OnnEventEvaluationFileValue
  | OnnEventEvaluationSingleSelectValue
  | OnnEventEvaluationMultipleSelectValue;

export type AnyOnnEventEvaluationValueExcludeMethods =
  | ExcludeMethods<OnnEventEvaluationTextValue>
  | ExcludeMethods<OnnEventEvaluationFileValue>
  | ExcludeMethods<OnnEventEvaluationSingleSelectValue>
  | ExcludeMethods<OnnEventEvaluationMultipleSelectValue>;

export const anyOnnEventEvaluationValueSchema = z.union([
  onnEventEvaluationTextValueSchema,
  onnEventEvaluationFileValueSchema,
  onnEventEvaluationSingleSelectValueSchema,
  onnEventEvaluationMultipleSelectValueSchema,
]);
