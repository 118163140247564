import { Box, Stack } from "@mui/material";
import React, { FC } from "react";
import { Control, Controller } from "react-hook-form";

import { DatePickerV2, Typography } from "~/components/uiParts";

import { SelectFormV2 } from "~/components/uiParts/SelectFormV2";

export const RecruitmentStatusForm: FC<{
  control: Control<
    {
      scenarioId: string;
      recruitmentStatusId: string;
      offerAcceptanceDeadline?: Date;
    },
    unknown
  >;
  isSelectedJobOfferType: boolean;
  recruitmentStatusOptions: {
    value: string;
    name: string;
  }[];
  onChangeRecruitmentStatus: () => void;
}> = ({ control, isSelectedJobOfferType, recruitmentStatusOptions, onChangeRecruitmentStatus }) => {
  return (
    <Stack width="100%" rowGap="8px">
      <Typography variant="body2" color="textSecondary" bold>
        選考ステータス
      </Typography>
      <Controller
        control={control}
        name="recruitmentStatusId"
        render={({ field, fieldState }) => (
          <SelectFormV2
            labelWhenNoSelected="選択してください"
            selected={field.value}
            onChange={(e) => {
              field.onChange(e.target.value);
              onChangeRecruitmentStatus();
            }}
            errorText={fieldState.error?.message}
            menuItems={recruitmentStatusOptions}
            fullWidth
          />
        )}
      />
      {isSelectedJobOfferType && (
        <Box display="flex" justifyContent="flexStart" alignItems="center" gap="8px">
          <Box>
            <Typography variant="caption" color="textSecondary" noWrap>
              承諾期日
            </Typography>
          </Box>
          <Box width="100%">
            <Controller
              name="offerAcceptanceDeadline"
              control={control}
              render={({ field }) => (
                <DatePickerV2
                  value={field.value || null}
                  fullWidth
                  height={40}
                  placeholder="日程を選択"
                  disablePast={false}
                  onChange={(data) => field.onChange(data)}
                />
              )}
            />
          </Box>
        </Box>
      )}
    </Stack>
  );
};
