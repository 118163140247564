const JSTOffsetInMinutes = 9 * 60; // UTC+9

/**
 * Date に対してローカル時間と JST の時差を加算した Date を返す
 * NOTE: 曜日や時間を個別で取得する場合、値はローカル時間での計算になるため、時差を加算することでタイムゾーンによらず JST での各種数値を取得できる
 * - 値を個別に取得したときに JST で取得した時と同じになる
 */
export const addJSTOffset = (date: Date): Date => {
  const offset = date.getTimezoneOffset();
  const offsetInMinutes = offset;

  return new Date(date.getTime() + (offsetInMinutes + JSTOffsetInMinutes) * 60 * 1000);
};
