import { v4 } from "uuid";

import { addJSTOffset } from "../../utils";

import { IBusinessHoursSetting, businessHoursSettingSchema } from "./schema";

const JSTOffset = 9;

export class BusinessHoursSetting implements IBusinessHoursSetting {
  static validator = businessHoursSettingSchema;

  id: string;
  tenantId: string;
  fromHours: number;
  fromMinutes: number;
  untilHours: number;
  untilMinutes: number;

  daysOfWeeks: DayOfWeek[];

  createdAt: Date;
  updatedAt: Date;

  constructor(init: ExcludeMethods<BusinessHoursSetting>) {
    const parsedInit = BusinessHoursSetting.validator.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.fromHours = parsedInit.fromHours;
    this.fromMinutes = parsedInit.fromMinutes;
    this.untilHours = parsedInit.untilHours;
    this.untilMinutes = parsedInit.untilMinutes;
    this.daysOfWeeks = [...new Set(parsedInit.daysOfWeeks)]; // 重複排除
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  static createNew(
    params: Omit<ExcludeMethods<BusinessHoursSetting>, "id" | "createdAt" | "updatedAt">
  ): BusinessHoursSetting {
    return new BusinessHoursSetting({
      ...params,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  static getAllDayOfWeeks(): DayOfWeek[] {
    return ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  }

  /** ソートと重複排除を行う */
  static sortDaysOfWeeks(daysOfWeeks: DayOfWeek[]): DayOfWeek[] {
    const sortedDaysOfWeeks = BusinessHoursSetting.getAllDayOfWeeks().filter((dayOfWeek) =>
      daysOfWeeks.includes(dayOfWeek)
    );

    return sortedDaysOfWeeks;
  }

  static convertDayOfWeekToJapanese(dayOfWeek: DayOfWeek): string {
    switch (dayOfWeek) {
      case "Monday":
        return "月";
      case "Tuesday":
        return "火";
      case "Wednesday":
        return "水";
      case "Thursday":
        return "木";
      case "Friday":
        return "金";
      case "Saturday":
        return "土";
      case "Sunday":
        return "日";
      default:
        // eslint-disable-next-line no-case-declarations
        const _exhaustiveCheck: never = dayOfWeek;
        return _exhaustiveCheck;
    }
  }

  getDayNameInJST(date: Date): DayOfWeek {
    return date.toLocaleString("en-US", {
      timeZone: "Asia/Tokyo",
      weekday: "long",
    }) as DayOfWeek; // NOTE: DayOfWeek と toLocaleString は ISO 8601 に準拠しているため、as しても問題ない
  }

  getIsDuringBusinessHours(date: Date): boolean {
    // NOTE: 曜日は timeZone 指定できるため、JST での曜日を取得する
    const dayName = this.getDayNameInJST(date);

    const dateAddedJSTOffset = addJSTOffset(date);

    const sumOfHoursAndMinutesInMinutes =
      dateAddedJSTOffset.getHours() * 60 + dateAddedJSTOffset.getMinutes();
    const fromInMinutes = this.fromHours * 60 + this.fromMinutes;
    const untilInMinutes = this.untilHours * 60 + this.untilMinutes;

    const isDayIncluded = this.daysOfWeeks.includes(dayName);
    const isTimeIncluded =
      fromInMinutes <= sumOfHoursAndMinutesInMinutes &&
      sumOfHoursAndMinutesInMinutes < untilInMinutes;
    const isDuringBusinessHours = isDayIncluded && isTimeIncluded;

    return isDuringBusinessHours;
  }

  // 営業時間内の場合は現在時刻を返す。テストケースで表現する
  getNextBusinessStartDate(date: Date): Date {
    const isDuringBusinessHours = this.getIsDuringBusinessHours(date);

    // 営業時間中の場合は現在時刻を返す
    if (isDuringBusinessHours) {
      return date;
    }

    const currentDayOfWeek = this.getDayNameInJST(date);

    const isBusinessDay = this.daysOfWeeks.includes(currentDayOfWeek);
    if (isBusinessDay && this.isTimeApproachingInSameDay(date)) {
      // 営業時間がまだ開始していない現在の日を処理
      return this.getStartOfBusinessDay(date);
    }

    // 翌日以降の営業日を取得して設定
    const nextDayOfWeek = this.getNextActiveDayOfWeek(currentDayOfWeek);
    const nextBusinessStartDate = this.getDateForNextBusinessDay(date, nextDayOfWeek);

    return nextBusinessStartDate;
  }

  private isTimeApproachingInSameDay(date: Date): boolean {
    const dateAddedJSTOffset = addJSTOffset(date);
    const currentMinutes = dateAddedJSTOffset.getHours() * 60 + dateAddedJSTOffset.getMinutes();
    const startMinutes = this.fromHours * 60 + this.fromMinutes;

    return currentMinutes < startMinutes;
  }

  private getStartOfBusinessDay(date: Date): Date {
    const dateAddedJSTOffset = addJSTOffset(date);
    dateAddedJSTOffset.setUTCHours(this.fromHours - JSTOffset, this.fromMinutes, 0, 0);
    return dateAddedJSTOffset;
  }

  private getNextActiveDayOfWeek(currentDayOfWeek: DayOfWeek): DayOfWeek {
    const allDayOfWeeks = BusinessHoursSetting.getAllDayOfWeeks();
    const currentIndex = allDayOfWeeks.indexOf(currentDayOfWeek);

    return (
      allDayOfWeeks
        .slice(currentIndex + 1)
        .concat(allDayOfWeeks)
        .find((day) => this.daysOfWeeks.includes(day)) ?? currentDayOfWeek
    );
  }

  private getDateForNextBusinessDay(date: Date, nextDayOfWeek: DayOfWeek): Date {
    const allDayOfWeeks = BusinessHoursSetting.getAllDayOfWeeks();
    const currentIndex = allDayOfWeeks.indexOf(this.getDayNameInJST(date));
    const nextIndex = allDayOfWeeks.indexOf(nextDayOfWeek);

    const daysToAdd =
      nextIndex > currentIndex ? nextIndex - currentIndex : nextIndex + 7 - currentIndex;

    const dateAddedJSTOffset = addJSTOffset(date);
    dateAddedJSTOffset.setUTCDate(dateAddedJSTOffset.getDate() + daysToAdd);
    dateAddedJSTOffset.setUTCHours(this.fromHours - JSTOffset, this.fromMinutes, 0, 0);

    return dateAddedJSTOffset;
  }
}

// NOTE: DayOfWeek は ISO 8601 に準拠
// see: https://unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
type DayOfWeek = "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
