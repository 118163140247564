import { Box } from "@material-ui/core";
import { Stack } from "@mui/material";
import {
  EmployeeInformation,
  NewGraduateToDisplay,
  RecruitmentStatusRelation,
  Scenario,
} from "@onn/common";
import React, { FC, useContext, useMemo } from "react";
import styled from "styled-components";

import { RecruitmentStatusForm } from "./parts/RecruitmentStatusForm";
import { ScenarioForm } from "./parts/ScenarioForm";
import { useAddScenarioToEmployeeForm } from "./useAddScenarioToEmployeeForm";

import { ScenarioContext } from "~/components/providers/ScenarioProvider";

import { Button, Modal as ModalUI, Typography } from "~/components/uiParts";

import { useInactiveScenarioRelationByNewGraduate } from "~/hooks/employee";
import { useAddScenarioToNewGraduate } from "~/hooks/employee/useAddScenarioToNewGraduate";
import { useEmployeeInformation } from "~/hooks/employeeInformation";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  onCancel: () => void;
  newGraduate: NewGraduateToDisplay;
  onAddScenarioToNewGraduate?: () => void;
};

export const AddScenarioToNewGraduateModal: FC<Props> = (props) => {
  return <ModalWrapper {...props}>{Modal}</ModalWrapper>;
};

const ModalWrapper: FC<
  Props & {
    children: typeof Modal;
  }
> = ({ children: Children, ...props }) => {
  const { data: employeeInformation, isLoading: isLoadingEmployeeInformation } =
    useEmployeeInformation(props.newGraduate.id);
  const { data: inactiveScenarioRelations, isLoading: isLoadingInactiveScenarioRelations } =
    useInactiveScenarioRelationByNewGraduate({ employeeId: props.newGraduate.id });

  const { trigger } = useAddScenarioToNewGraduate({
    employeeId: props.newGraduate.id,
    onAddScenarioToNewGraduate: props.onAddScenarioToNewGraduate,
  });

  const isLoading = useMemo(
    () => isLoadingEmployeeInformation || isLoadingInactiveScenarioRelations,
    [isLoadingEmployeeInformation, isLoadingInactiveScenarioRelations]
  );

  return isLoading ||
    typeof employeeInformation === "undefined" ||
    typeof inactiveScenarioRelations === "undefined" ? (
    <></>
  ) : (
    <Children
      {...props}
      employeeInformation={employeeInformation}
      inactiveScenarioRelations={inactiveScenarioRelations}
      onSubmit={async (args: { scenarioId: string; recruitmentStatusId: string }) => {
        await trigger(args);
        props.onCancel();
      }}
    />
  );
};

const Modal: FC<
  Props & {
    employeeInformation: EmployeeInformation | null;
    inactiveScenarioRelations: Array<{
      scenario: Scenario;
      recruitmentStatusRelation: RecruitmentStatusRelation;
    }>;
    onSubmit: (args: {
      scenarioId: string;
      recruitmentStatusId: string;
      offerAcceptanceDeadline?: Date;
    }) => Promise<void>;
  }
> = ({ open, onCancel, newGraduate, employeeInformation, inactiveScenarioRelations, onSubmit }) => {
  const { scenariosWithRecruitmentStatuses } = useContext(ScenarioContext);

  const {
    formState: { isValid, isDirty, isLoading, isSubmitting },
    handleSubmit,
    onChangeScenario,
    onChangeRecruitmentStatus,
    ...form
  } = useAddScenarioToEmployeeForm({
    newGraduate,
    scenariosWithRecruitmentStatuses,
    offerAcceptanceDeadline: employeeInformation?.value.offerAcceptanceDeadline,
    inactiveScenarioRelations,
    onSubmit,
  });

  return (
    <ModalUI
      open={open}
      title="シナリオ追加"
      titleHint="シナリオ追加は、選考ステータスやシナリオの変更とは別の機能です。一人の候補者に対して、並行して進む全く別の選考ラインが複数付与される場合にのみご活用ください。合計3ラインまでシナリオを追加することができます。"
      content={
        <Stack alignItems="center">
          <Box mb="24px">
            <Typography variant="body1" align="center">
              {newGraduate.getName()}さんに追加するシナリオを選択してください。
              <br />
              シナリオを追加すると、並行して進む別の選考ラインが追加されます。
            </Typography>
          </Box>

          <Stack width="60%" rowGap="16px" mb="32px">
            <ScenarioForm {...form} onChangeScenario={onChangeScenario} />
            <RecruitmentStatusForm
              {...form}
              onChangeRecruitmentStatus={onChangeRecruitmentStatus}
            />
          </Stack>

          <Box display="flex" justifyContent="center">
            <Typography variant="caption" color="textSecondary">
              この操作は取り消すことができません。
            </Typography>
          </Box>
        </Stack>
      }
      footer={
        <StyledButtonContainer>
          <Button
            fullWidth
            borderRadius="circle"
            variant="outlined"
            color="default"
            onClick={onCancel}
          >
            キャンセル
          </Button>
          <Button
            fullWidth
            borderRadius="circle"
            variant="contained"
            color="primary"
            disabled={!isValid || !isDirty || isLoading || isSubmitting}
            isLoading={isLoading || isSubmitting}
            onClick={handleSubmit}
          >
            追加
          </Button>
        </StyledButtonContainer>
      }
      onCancel={onCancel}
    />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
