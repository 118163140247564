import { z } from "zod";

/////////////////////////////////////////
// ONN EVENT EVALUATION MULTIPLE SELECT OPTION SCHEMA
/////////////////////////////////////////

export const OnnEventEvaluationMultipleSelectOptionSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  onnEventEvaluationMultipleSelectFieldId: z.string(),
  label: z.string(),
  order: z.number(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type OnnEventEvaluationMultipleSelectOption = z.infer<
  typeof OnnEventEvaluationMultipleSelectOptionSchema
>;

/////////////////////////////////////////
// ONN EVENT EVALUATION MULTIPLE SELECT OPTION OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const OnnEventEvaluationMultipleSelectOptionOptionalDefaultsSchema =
  OnnEventEvaluationMultipleSelectOptionSchema.merge(
    z.object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
  );

export type OnnEventEvaluationMultipleSelectOptionOptionalDefaults = z.infer<
  typeof OnnEventEvaluationMultipleSelectOptionOptionalDefaultsSchema
>;

export default OnnEventEvaluationMultipleSelectOptionSchema;
