import { Box, Drawer, List } from "@material-ui/core";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { NotificationSidebarListItem } from "./NotificationSidebarListItem";
import { SidebarListItem } from "./SidebarListItem";

import { SpaceSwitcherController } from "./parts/SpaceSwitcherController";

import { UserProfile } from "~/components/layouts/Sidebar/UserProfile";
import { Divider, IconButton, Tooltip } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useTenant } from "~/hooks/tenant";
import logoSquare from "~/images/logo-square.svg";
import logo from "~/images/logo.svg";

type Props = {
  isOpenSidebar: boolean;
  isDisplayContactBadge: boolean;
  isDisplayOnboardingMenu: boolean;
  toSettingsUrl: string;
  onClickToggleIcon: () => void;
};

export const Sidebar: FC<Props> = ({
  isOpenSidebar,
  isDisplayContactBadge,
  isDisplayOnboardingMenu,
  toSettingsUrl,
  onClickToggleIcon,
}) => {
  const { currentUser } = useCurrentUser();
  const icon = isOpenSidebar ? "chevronLeft" : "chevronRight";
  const logoSrc = isOpenSidebar ? logo : logoSquare;
  const currentPathname = window.location.pathname;
  const { tenant } = useTenant();

  return (
    <StyledDrawer variant="persistent" anchor="left" open={isOpenSidebar}>
      <Box display="flex" position="relative">
        <StyledButtonWrapper width="26px" height="26px" $isOpenSidebar={isOpenSidebar}>
          <Tooltip
            title={isOpenSidebar ? "サイドバーを縮小" : "サイドバーを拡大"}
            placement="right"
            arrow
          >
            <IconButton
              icon={icon}
              size="md"
              color="grey"
              onClick={onClickToggleIcon}
              borderRadius="circle"
            />
          </Tooltip>
        </StyledButtonWrapper>
      </Box>

      <Box
        mb={5}
        pt={4}
        pl={4}
        pr={8}
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
      >
        <StyledLink to="/" $disabled={!currentUser.isRegularAcceptanceEmployee()}>
          <img src={logoSrc} alt="Onn" />
        </StyledLink>
      </Box>
      <Box display="flex" flexDirection="column" gridRowGap="16px">
        {/* Spaceの個数によっては何もレンダリングされないケースがあるが、その判定はSpaceProviderに依存しているためこのコンポーネントでは判別できない */}
        {tenant.isActiveNewGraduate && currentUser.isRegularAcceptanceEmployee() && (
          <Box px={4}>
            <SpaceSwitcherController isExpand={isOpenSidebar} ifFullWidthMenu={false} />
          </Box>
        )}
        <List style={{ display: "flex", flexDirection: "column", rowGap: "16px" }} disablePadding>
          {isDisplayOnboardingMenu && (
            <SidebarListItem
              label="候補者"
              to="/"
              icon="onboarding"
              shouldShowName={isOpenSidebar}
              currentPathname={currentPathname}
              isAccessible={currentUser.isRegularAcceptanceEmployee()}
            />
          )}
          {tenant.isActiveNewGraduate && (
            <SidebarListItem
              label="イベント"
              to="/events"
              icon="calendar"
              shouldShowName={isOpenSidebar}
              currentPathname={currentPathname}
              isAccessible={currentUser.isRegularAcceptanceEmployee()}
            />
          )}
          <SidebarListItem
            label="タスク"
            to={tenant.isActiveNewGraduate ? "/onn_tasks" : "/tasks"}
            icon="checkOutlineCircle"
            shouldShowName={isOpenSidebar}
            currentPathname={currentPathname}
            isAccessible={currentUser.isRegularAcceptanceEmployee()}
          />
          <SidebarListItem
            label="コンタクト"
            to="/contact_rooms"
            icon="paperAirplane"
            shouldShowName={isOpenSidebar}
            isBadge={isDisplayContactBadge}
            currentPathname={currentPathname}
            isAccessible={currentUser.isRegularAcceptanceEmployee()}
          />
          <SidebarListItem
            label="ツール"
            to="/tools"
            icon="suitcase"
            shouldShowName={isOpenSidebar}
            currentPathname={currentPathname}
            isAccessible={currentUser.isRegularAcceptanceEmployee()}
          />
          <Box pl={4}>
            <StyledDivider orientation="horizontal" $isOpenSidebar={isOpenSidebar} />
          </Box>
          <SidebarListItem
            label="設定"
            to={toSettingsUrl}
            icon="setting"
            shouldShowName={isOpenSidebar}
            currentPathname={currentPathname}
            isAccessible={!currentUser.isNewGraduateEmployee()}
          />
        </List>
      </Box>
      <Box flexGrow={1} minHeight="24px" />
      <List style={{ display: "flex", flexDirection: "column", rowGap: "24px" }} disablePadding>
        {tenant.isActiveNewGraduate && (
          <NotificationSidebarListItem shouldShowName={isOpenSidebar} />
        )}
        <Divider orientation="horizontal" />
        <Box px="32px" pb="24px">
          <UserProfile shouldShowUserName={isOpenSidebar} />
        </Box>
      </List>
    </StyledDrawer>
  );
};

const StyledLink = styled(Link)<{ $disabled: boolean }>`
  pointer-events: ${(props) => (props.$disabled ? "none" : "auto")};
`;

const StyledDrawer = styled(Drawer)`
  &.MuiDrawer-root {
    > .MuiPaper-root {
      width: ${(props) =>
        props.open
          ? props.theme.constants.SIDEBAR_OPEN_WIDTH
          : props.theme.constants.SIDEBAR_CLOSED_WIDTH}px;
      transform: none !important;
      visibility: visible !important;
      overflow-x: hidden;
    }
  }
`;

const StyledButtonWrapper = styled(Box)<{ $isOpenSidebar: boolean }>`
  position: fixed;
  z-index: ${(props) => props.theme.zIndex.drawer + 1};
  top: 44px;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.palette.grey[100]};
  background-color: ${(props) => props.theme.palette.common.white};
  left: ${(props) =>
    props.$isOpenSidebar
      ? props.theme.constants.SIDEBAR_OPEN_WIDTH - 13
      : props.theme.constants.SIDEBAR_CLOSED_WIDTH - 13}px;

  span > .MuiIconButton-root {
    padding: 0;
  }
`;

const StyledDivider = styled(Divider)<{ $isOpenSidebar: boolean }>`
  width: ${(props) => (props.$isOpenSidebar ? 190 : 40)}px;
`;
