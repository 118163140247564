import { RecruitmentStatusRelation, Scenario } from "@onn/common";
import useSWR, { SWRResponse } from "swr";

import { useCurrentUser } from "~/hooks/employee";
import { apiClient } from "~/libs";

export const useInactiveScenarioRelationByNewGraduate = ({
  employeeId,
}: {
  employeeId?: string;
}): SWRResponse<
  Array<{
    scenario: Scenario;
    recruitmentStatusRelation: RecruitmentStatusRelation;
  }>,
  Error
> => {
  const { currentUser } = useCurrentUser();

  return useSWR(
    getKey({ employeeId, tenantId: currentUser.tenantId }),
    async ({ endpoint, employeeId }) => {
      const response = await apiClient.get(endpoint, { employeeId, isActive: false });

      return response.data.map((obj) => {
        return {
          scenario: new Scenario(obj.scenario),
          recruitmentStatusRelation: new RecruitmentStatusRelation(obj.recruitmentStatusRelation),
        };
      });
    },
    {
      // キャッシュを利用するメリットがないため、常に再検証する
      revalidateOnMount: true,
      revalidateIfStale: true,
    }
  );
};
const getKey = (args: { tenantId: string; employeeId?: string }) => {
  return args.employeeId
    ? ({
        endpoint: "/scenario_api/scenarios/:id/relations",
        ...args,
      } as const)
    : null;
};
