import { z } from "zod";

import { DayOfWeekSchema } from "../inputTypeSchemas/DayOfWeekSchema";

/////////////////////////////////////////
// BUSINESS HOURS SETTING SCHEMA
/////////////////////////////////////////

export const BusinessHoursSettingSchema = z.object({
  daysOfWeeks: DayOfWeekSchema.array(),
  id: z.string(),
  tenantId: z.string(),
  fromHours: z
    .number()
    .min(0, { message: "開始時刻が不正です" })
    .max(24, { message: "開始時刻が不正です" }),
  fromMinutes: z
    .number()
    .min(0, { message: "開始時刻が不正です" })
    .max(59, { message: "開始時刻が不正です" }),
  untilHours: z
    .number()
    .min(0, { message: "開始時刻が不正です" })
    .max(24, { message: "開始時刻が不正です" }),
  untilMinutes: z
    .number()
    .min(0, { message: "開始時刻が不正です" })
    .max(59, { message: "開始時刻が不正です" }),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type BusinessHoursSetting = z.infer<typeof BusinessHoursSettingSchema>;

/////////////////////////////////////////
// BUSINESS HOURS SETTING OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const BusinessHoursSettingOptionalDefaultsSchema = BusinessHoursSettingSchema.merge(
  z.object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
  })
);

export type BusinessHoursSettingOptionalDefaults = z.infer<
  typeof BusinessHoursSettingOptionalDefaultsSchema
>;

export default BusinessHoursSettingSchema;
