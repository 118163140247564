import { NewGraduate } from "@onn/common";
import { EmailType, LineType } from "@onn/common/domain/Employee/AuthenticationType";
import React from "react";

import { Condition } from "../type";

import { ModalContent } from "./ModalContent";
import { ModalFooter } from "./ModalFooter";

export const useAddConditionModeModal = ({
  isLoadingFooterButton,
  onAddConditions,
  onCancelAddConditions,
  ...params
}: {
  selectedCondition: Condition;
  addSelectedAuthenticationFlowTypes: (authenticationFlowTypes: (LineType | EmailType)[]) => void;
  removeSelectedAuthenticationFlowTypes: (
    authenticationFlowTypes: (LineType | EmailType)[]
  ) => void;
  toggleScenarioSelection: ({ scenarioId }: { scenarioId: string }) => void;
  changeSelectedRecruitmentStatusId: ({
    scenarioId,
    recruitmentStatusId,
  }: {
    scenarioId: string;
    recruitmentStatusId: string;
  }) => void;
  addSelectedOnnEventIds: (onnEventIds: string[]) => void;
  removeSelectedOnnEventIds: (onnEventIds: string[]) => void;
  addSelectedOnnTaskIds: (onnTaskIds: string[]) => void;
  removeSelectedOnnTaskIds: (onnTaskIds: string[]) => void;
  addSelectedTagIds: (tagIds: string[]) => void;
  removeSelectedTagIds: (tagIds: string[]) => void;
  isLoadingFooterButton: boolean;
  onAddConditions: () => Promise<void>;
  onCancelAddConditions: () => void;
  currentScenarios: NewGraduate["scenarios"];
}) => {
  return {
    title: "招待QR・URL｜条件付与",
    titleHint: undefined,
    content: <ModalContent {...params} />,
    footer: (
      <ModalFooter
        selectedCondition={params.selectedCondition}
        isLoading={isLoadingFooterButton}
        onAddConditions={onAddConditions}
        onCancel={onCancelAddConditions}
      />
    ),
  };
};
