import { Box, Menu, MenuItem } from "@material-ui/core";
import { OnnEvent } from "@onn/common";
import React, { MouseEvent, memo, useCallback, useState } from "react";

import styled from "styled-components";

import { Filter, useTabPage } from "../../hooks/OnnEventContentsTab/useTabPage";

import { IconButton, TooltipWhenTextTruncated, Typography, UserIcon } from "~/components/uiParts";
import { OnnEventAnswerWithEmployee } from "~/hooks/onnEvent/answerResult/useOnnEventAnswersWithEmployee";

type CandidateListItemProps = {
  selectedFilterIsAnswered: Filter;
  onnEvent: OnnEvent;
  onnEventAnswerWithEmployee: OnnEventAnswerWithEmployee;
  isAdmin: boolean;
  onClickOpenRemoveModal: ReturnType<typeof useTabPage>["onClickOpenRemoveModal"];
  onSubmitRemove: ReturnType<typeof useTabPage>["onSubmitRemove"];
};

export const CandidateListItem = memo((props: CandidateListItemProps) => {
  const {
    selectedFilterIsAnswered,
    onnEvent,
    onnEventAnswerWithEmployee,
    onClickOpenRemoveModal,
    onSubmitRemove,
  } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpenMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const shouldShowOptionBtn = props.isAdmin && !onnEventAnswerWithEmployee.employee.deleted;
  return (
    <Box
      key={onnEventAnswerWithEmployee.id}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      mb="16px"
      position="relative"
    >
      <Box
        width={
          shouldShowOptionBtn ? "85%" : "100%" /* NOTE: VirtualizedTable の widthOptions を模倣 */
        }
        display="flex"
        justifyContent="space-between"
      >
        <TooltipWhenTextTruncated text={onnEventAnswerWithEmployee.employee.getName()}>
          {(ref) => (
            <Box width="100%" display="flex" alignItems="center" gridGap="12px">
              <UserIcon
                username={onnEventAnswerWithEmployee.employee.getName()}
                profileIconImageUrl={onnEventAnswerWithEmployee.employee.profileIconImageUrl}
                size="small"
                backgroundColor={onnEventAnswerWithEmployee.employee.uid ? "primary" : "grey"}
              />
              <Typography variant="body1" color="textPrimary" bold noWrap ref={ref}>
                {onnEventAnswerWithEmployee.employee.getName()}
              </Typography>
            </Box>
          )}
        </TooltipWhenTextTruncated>
      </Box>
      {shouldShowOptionBtn && (
        <Box width="15%">
          <IconButton icon="menuVert" onClick={handleOpenMenu} />
          <StyledMenu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <MenuItem
              onClick={() => {
                handleCloseMenu();
                selectedFilterIsAnswered === "answered"
                  ? onClickOpenRemoveModal(onnEvent, onnEventAnswerWithEmployee)
                  : onSubmitRemove(onnEventAnswerWithEmployee);
              }}
            >
              削除
            </MenuItem>
          </StyledMenu>
        </Box>
      )}
    </Box>
  );
});

const StyledMenu = styled(Menu)`
  margin-top: 8px;
`;
