import { z } from "zod";

/////////////////////////////////////////
// DELAY CONTACT MESSAGE ORDER SCHEMA
/////////////////////////////////////////

export const DelayContactMessageOrderSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  contactRoomId: z.string(),
  executionId: z.string(),
  createdAt: z.date(),
  scheduledAt: z.date(),
  isCompleted: z.boolean(),
  text: z.string(),
  filePaths: z.string().array(),
  stickerId: z.string().nullish(),
  creatorEmployeeId: z.string().nullish(),
  isOfficialName: z.boolean().nullish(),
});

export type DelayContactMessageOrder = z.infer<typeof DelayContactMessageOrderSchema>;

/////////////////////////////////////////
// DELAY CONTACT MESSAGE ORDER OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const DelayContactMessageOrderOptionalDefaultsSchema = DelayContactMessageOrderSchema.merge(
  z.object({
    createdAt: z.date().optional(),
    isCompleted: z.boolean().optional(),
  })
);

export type DelayContactMessageOrderOptionalDefaults = z.infer<
  typeof DelayContactMessageOrderOptionalDefaultsSchema
>;

export default DelayContactMessageOrderSchema;
