import {
  Employee,
  OnnEventAnswerForDisplay,
  OnnEventDeterminedDate,
  OnnEventSlotDate,
} from "@onn/common";
import useSWR, { mutate } from "swr";

import { apiClient } from "~/libs";

const generateKey = (onnEventId: string) => {
  return {
    path: `/get_onn_event_answers_for_display`,
    onnEventId,
  } as const;
};

export const useOnnEventAnswersForDisplay = (onnEventId: string) => {
  return useSWR(generateKey(onnEventId), async ({ path }) => {
    const response = await apiClient.get(path, { onnEventId });

    return response.data.onnEventAnswersForDisplay.map(
      (v) =>
        new OnnEventAnswerForDisplay({
          ...v,
          onnEventDeterminedDate:
            v.onnEventDeterminedDate && new OnnEventDeterminedDate(v.onnEventDeterminedDate),
          selectedOnnEventSlotDate: v.selectedOnnEventSlotDate
            ? new OnnEventSlotDate(v.selectedOnnEventSlotDate)
            : undefined,
          newGraduate: Employee.castToNewGraduate(Employee.plainToInstance(v.newGraduate)),
        })
    );
  });
};

export const mutateOnnEventAnswersForDisplay = (onnEventId: string) => {
  mutate(generateKey(onnEventId));
};
