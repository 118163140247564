import { RegistrationInvitationLink } from "@onn/common";
import { useCallback } from "react";

import { Condition } from "./type";

import { useRecruitmentStatusList } from "~/components/providers/ScenarioProvider";

export const useGenerateDefaultCondition = () => {
  const { recruitmentStatuses } = useRecruitmentStatusList();

  const generateDefaultCondition = useCallback(
    ({
      defaultRegistrationInvitationLink,
    }: {
      defaultRegistrationInvitationLink: RegistrationInvitationLink;
    }): Partial<Condition> => {
      const defaultRecruitmentStatuses = recruitmentStatuses.filter((rs) =>
        defaultRegistrationInvitationLink.recruitmentStatusIds.includes(rs.id)
      );
      return {
        authenticationFlowTypes:
          defaultRegistrationInvitationLink.selectableAuthenticationFlowTypes,
        scenarios: defaultRecruitmentStatuses.map((rs) => ({
          scenarioId: rs.scenarioId,
          recruitmentStatusId: rs.id,
        })),
      };
    },
    [recruitmentStatuses]
  );

  return { generateDefaultCondition };
};
