export * from "./OnnEventEvaluationFileField";
export * from "./OnnEventEvaluationMultipleSelectField";
export * from "./OnnEventEvaluationSingleSelectField";
export * from "./OnnEventEvaluationTextField";

import { OnnEventEvaluationFileField } from "./OnnEventEvaluationFileField";
import {
  OnnEventEvaluationMultipleSelectField,
  OnnEventEvaluationMultipleSelectOption,
} from "./OnnEventEvaluationMultipleSelectField";
import {
  OnnEventEvaluationSingleSelectField,
  OnnEventEvaluationSingleSelectOption,
} from "./OnnEventEvaluationSingleSelectField";
import { OnnEventEvaluationTextField } from "./OnnEventEvaluationTextField";

export type AnyOnnEventEvaluationField =
  | OnnEventEvaluationTextField
  | OnnEventEvaluationFileField
  | OnnEventEvaluationSingleSelectField
  | OnnEventEvaluationMultipleSelectField;

type AnyOnnEventEvaluationFieldCreateNewParams =
  | Parameters<typeof OnnEventEvaluationTextField.createNewWithGeneratedId>[0]
  | Parameters<typeof OnnEventEvaluationFileField.createNewWithGeneratedId>[0]
  | Parameters<typeof OnnEventEvaluationSingleSelectField.createNewWithGeneratedId>[0]
  | Parameters<typeof OnnEventEvaluationMultipleSelectField.createNewWithGeneratedId>[0];

export const createNewOnnEventEvaluationField = (
  args: AnyOnnEventEvaluationFieldCreateNewParams
): AnyOnnEventEvaluationField => {
  switch (args.type) {
    case OnnEventEvaluationTextField.type:
      return OnnEventEvaluationTextField.createNewWithGeneratedId(args);
    case OnnEventEvaluationFileField.type:
      return OnnEventEvaluationFileField.createNewWithGeneratedId(args);
    case OnnEventEvaluationSingleSelectField.type:
      return OnnEventEvaluationSingleSelectField.createNewWithGeneratedId(args);
    case OnnEventEvaluationMultipleSelectField.type:
      return OnnEventEvaluationMultipleSelectField.createNewWithGeneratedId(args);
  }
};

type AnyOnnEventEvaluationFieldUpdateParams =
  | Parameters<OnnEventEvaluationTextField["update"]>[0]
  | Parameters<OnnEventEvaluationFileField["update"]>[0]
  | Parameters<OnnEventEvaluationSingleSelectField["update"]>[0]
  | Parameters<OnnEventEvaluationMultipleSelectField["update"]>[0];

export const updateOnnEventEvaluationField = (
  field: AnyOnnEventEvaluationField,
  params: AnyOnnEventEvaluationFieldUpdateParams
): AnyOnnEventEvaluationField => {
  if (field.type !== params.type) {
    // 想定外のため、Resultすら返さずに例外を投げる
    throw new Error("評価フィールドのタイプを変更することはできません");
  }

  switch (field.type) {
    case OnnEventEvaluationTextField.type:
      if (field.type !== params.type) throw new Error(); // 型合わせ

      return field.update(params);
    case OnnEventEvaluationFileField.type:
      if (field.type !== params.type) throw new Error(); // 型合わせ

      return field.update(params);
    case OnnEventEvaluationSingleSelectField.type:
      if (field.type !== params.type) throw new Error(); // 型合わせ

      return field.update(params);
    case OnnEventEvaluationMultipleSelectField.type:
      if (field.type !== params.type) throw new Error(); // 型合わせ

      return field.update(params);
  }
};

type OnnEventEvaluationSingleSelectFieldExcludeMethods = ExcludeMethods<
  Omit<OnnEventEvaluationSingleSelectField, "options">
> & {
  options: ExcludeMethods<OnnEventEvaluationSingleSelectField["options"][number]>[];
};

type OnnEventEvaluationMultipleSelectFieldExcludeMethods = ExcludeMethods<
  Omit<OnnEventEvaluationMultipleSelectField, "options">
> & {
  options: ExcludeMethods<OnnEventEvaluationMultipleSelectField["options"][number]>[];
};

export type AnyOnnEventEvaluationFieldExcludeMethods =
  | ExcludeMethods<OnnEventEvaluationTextField>
  | ExcludeMethods<OnnEventEvaluationFileField>
  | ExcludeMethods<OnnEventEvaluationSingleSelectFieldExcludeMethods>
  | ExcludeMethods<OnnEventEvaluationMultipleSelectFieldExcludeMethods>;

export const instantiateFromAnyOnnEventEvaluationFieldExcludeMethods = (
  anyOnnEventEvaluationFieldExcludeMethods: AnyOnnEventEvaluationFieldExcludeMethods
) => {
  switch (anyOnnEventEvaluationFieldExcludeMethods.type) {
    case OnnEventEvaluationTextField.type:
      return new OnnEventEvaluationTextField(anyOnnEventEvaluationFieldExcludeMethods);
    case OnnEventEvaluationFileField.type:
      return new OnnEventEvaluationFileField(anyOnnEventEvaluationFieldExcludeMethods);
    case OnnEventEvaluationSingleSelectField.type:
      return new OnnEventEvaluationSingleSelectField({
        ...anyOnnEventEvaluationFieldExcludeMethods,
        options: anyOnnEventEvaluationFieldExcludeMethods.options.map(
          (option) => new OnnEventEvaluationSingleSelectOption(option)
        ),
      });
    case OnnEventEvaluationMultipleSelectField.type:
      return new OnnEventEvaluationMultipleSelectField({
        ...anyOnnEventEvaluationFieldExcludeMethods,
        options: anyOnnEventEvaluationFieldExcludeMethods.options.map((option) => {
          return new OnnEventEvaluationMultipleSelectOption(option);
        }),
      });
    default: {
      const _exhaustiveCheck: never = anyOnnEventEvaluationFieldExcludeMethods;
      return _exhaustiveCheck;
    }
  }
};

export * from "./schema";
