import { Box } from "@material-ui/core";
import { Stack } from "@mui/material";
import { NewGraduateToDisplay } from "@onn/common";
import React, { FC, useContext } from "react";
import styled from "styled-components";

import { ScenarioForm } from "./parts/ScenarioForm";
import { useDisableScenarioToEmployeeForm } from "./useDisableScenarioToEmployeeForm";

import { ScenarioContext } from "~/components/providers/ScenarioProvider";

import { Button, Modal as ModalUI, Typography } from "~/components/uiParts";

import { useDisableScenarioToNewGraduate } from "~/hooks/employee";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  onCancel: () => void;
  newGraduate: NewGraduateToDisplay;
  onDisableScenario?: () => void;
};

export const DisableScenarioToNewGraduateModal: FC<Props> = (props) => {
  return <ModalWrapper {...props}>{Modal}</ModalWrapper>;
};

const ModalWrapper: FC<
  Props & {
    children: typeof Modal;
  }
> = ({ children: Children, ...props }) => {
  const { trigger } = useDisableScenarioToNewGraduate({
    employeeId: props.newGraduate.id,
    onDisableScenario: props.onDisableScenario,
  });

  return (
    <Children
      {...props}
      onSubmit={async (args: { scenarioId: string }) => {
        await trigger({ scenarioId: args.scenarioId });
        props.onCancel();
      }}
    />
  );
};

const Modal: FC<
  Props & {
    onSubmit: (args: { scenarioId: string }) => Promise<void>;
  }
> = ({ open, onCancel, newGraduate, onSubmit }) => {
  const { scenariosWithRecruitmentStatuses } = useContext(ScenarioContext);

  const {
    formState: { isValid, isDirty, isLoading, isSubmitting },
    handleSubmit,
    ...form
  } = useDisableScenarioToEmployeeForm({
    newGraduate,
    scenariosWithRecruitmentStatuses,
    onSubmit,
  });

  return (
    <ModalUI
      open={open}
      title="シナリオ中断"
      titleHint="シナリオを中断すると、そのシナリオへの操作ができなくなります。ただし、中断前に実行予約されていたアクションは実行されます。"
      content={
        <Stack alignItems="center">
          <Box mb="24px">
            <Typography variant="body1" align="center">
              {newGraduate.getName()}さんで中断するシナリオを選択してください。
            </Typography>
          </Box>

          <Stack width="60%" rowGap="16px" mb="32px">
            <ScenarioForm {...form} />
          </Stack>

          <Box display="flex" justifyContent="center">
            <Typography variant="caption" color="textSecondary">
              この操作は取り消すことができません。
            </Typography>
          </Box>
        </Stack>
      }
      footer={
        <StyledButtonContainer>
          <Button
            fullWidth
            borderRadius="circle"
            variant="outlined"
            color="default"
            onClick={onCancel}
          >
            キャンセル
          </Button>
          <Button
            fullWidth
            borderRadius="circle"
            variant="contained"
            color="secondary"
            disabled={!isValid || !isDirty || isLoading || isSubmitting}
            isLoading={isLoading || isSubmitting}
            onClick={handleSubmit}
          >
            中断
          </Button>
        </StyledButtonContainer>
      }
      onCancel={onCancel}
    />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
