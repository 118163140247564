import { APISchema } from "@onn/common";
import useSWR from "swr";

import { apiClient } from "~/libs";

type EndPoint =
  APISchema["/scenario_api/executable-change-recruitment-status-action-settings"]["GET"];

const generateKey = ({
  newGraduateId,
  onnEventEvaluationRankId,
  onnEventId,
}: {
  newGraduateId: string;
  onnEventEvaluationRankId: string | null;
  onnEventId: string;
}) => {
  if (!onnEventEvaluationRankId) {
    return null;
  }
  return {
    endpoint: "/scenario_api/executable-change-recruitment-status-action-settings",
    newGraduateId,
    onnEventEvaluationRankId,
    onnEventId,
  } as const;
};

export const useGetExecutableChangeRecruitmentStatusActionSettings = ({
  newGraduateId,
  onnEventEvaluationRankId,
  onnEventId,
}: {
  newGraduateId: string;
  onnEventEvaluationRankId: string | null;
  onnEventId: string;
}) => {
  return useSWR(
    generateKey({ newGraduateId, onnEventEvaluationRankId, onnEventId }),
    async ({ endpoint, newGraduateId, onnEventEvaluationRankId, onnEventId }) => {
      const requestQuery: EndPoint["query"] = {
        newGraduateId,
        onnEventEvaluationRankId,
        onnEventId,
      };
      const response = await apiClient.get(endpoint, requestQuery);
      return response.data.changeRecruitmentStatusActionSettings;
    }
  );
};
